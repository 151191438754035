import * as Yup from "yup";
import { incidentBaseValidationSchema } from "../../../../common/incidentBaseValidation";
import { propertyBaseValidationSchema } from "../../../../common/propertyBaseValidation";
import { narrativeBaseValidationSchema } from "../../../../common/narrativeBaseValidation";
import {
  businessYourselfValidationSchema,
  individualYourselfValidationSchema,
  personTypeSelectionValidationSchema,
  reportTypeSelectionValidationSchema,
} from "../../../../common/templates/validation";

const requiredErrorMsg = "You must fill all required fields.";

export const start0 = Yup.object({
  start0: Yup.object({
    isEmergency: Yup.string()
      .required("All questions must be answered.")
      .matches(/no/, "If this is an Emergency call 911."),
    haveValidEmailAddress: Yup.string()
      .required("All questions must be answered.")
      .matches(
        /yes/,
        "You must have a valid email address to file a report online."
      ),
    wasWithinCobourg: Yup.string()
      .required("All questions must be answered.")
      .matches(
        /yes/,
        "If the loss of property occurred outside Cobourg, please contact the police service responsible for that area."
      ),
  }),
});

export const metaStart1 = Yup.object({
  meta: reportTypeSelectionValidationSchema,
});

export const metaStart2 = Yup.object({
  meta: personTypeSelectionValidationSchema,
});

export const individualYourself = Yup.object({
  reporter: individualYourselfValidationSchema,
});

export const businessYourself = Yup.object({
  reporter: businessYourselfValidationSchema,
});

export const incident = Yup.object({
  info: Yup.object({
    lostProperty: Yup.object({
      locationType: Yup.string().required(requiredErrorMsg),
    }).concat(incidentBaseValidationSchema),
  }),
});

export const property = Yup.object().shape({
  info: Yup.object({
    lostProperty: Yup.object({
      property: Yup.array().of(propertyBaseValidationSchema),
    }),
  }),
});

export const narrative = Yup.object({
  info: Yup.object({
    lostProperty: narrativeBaseValidationSchema,
  }),
});
