import React from "react";
import { locations, IncidentBase } from "../../../../common/IncidentBase";
import RadioField from "../../../../form-fields/RadioField";
import SelectField from "../../../../form-fields/SelectField";
import styles from "./Incident.module.css";

export default function Incident({ formKey = "info.damageToProperty" }) {
  return (
    <IncidentBase
      formKey={formKey}
      text={
        <>
          Please enter all the information that applies. If you do not know the
          address of the incident, use the nearest intersection such as Market
          St./ 10th St.
        </>
      }
      color=""
    >
      <div
        style={{
          paddingBottom: "1rem",
          borderBottom: "1px solid var(--border-color)",
        }}
      >
        <p style={{ paddingLeft: "1rem" }}>
        <span style={{ color: "red" }}>* </span>Location Type
        </p>
        <div className={styles.section}>
          <SelectField
            formKey={formKey + ".locationType"}
            options={locations.map((location) => ({
              value: location,
              label: location,
            }))}
            name={formKey + ".locationType"}
            label={"Please Select"}
          />
        </div>
      </div>
      <div
        style={{
          paddingBottom: "1rem",
          borderBottom: "1px solid var(--border-color)",
        }}
      >
        <p style={{ paddingLeft: "1rem" }}>
          Graffiti Involved? <span style={{ color: "red" }}>* </span>
        </p>
        <div className={styles.section}>
          <RadioField
            name={formKey + ".graffitiInvolved"}
            type="radio"
            value="yes"
            label="yes"
          />
          <RadioField
            name={formKey + ".graffitiInvolved"}
            type="radio"
            value="no"
            label="no"
          />
        </div>
      </div>
      {/* <div
        style={{
          paddingBottom: "1rem",
          borderBottom: "1px solid var(--border-color)",
        }}
      >
        <p style={{ paddingLeft: "1rem" }}>
          Have Property Info to Enter? <span style={{ color: "red" }}>* </span>
        </p>
        <div className={styles.section}>
          <RadioField
            formKey={formKey + ".havePropertyInfo"}
            name={formKey + ".havePropertyInfo"}
            type="radio"
            value="yes"
            label="yes"
          />
          <RadioField
            formKey={formKey + ".havePropertyInfo"}
            name={formKey + ".havePropertyInfo"}
            type="radio"
            value="no"
            label="no"
          />
        </div>
      </div> */}
    </IncidentBase>
  );
}
