import { Snackbar } from "@mui/material";
import { useContext } from "react";
import { ConnectionContext } from "../../contexts/Connection";

export default function LoadingChip() {
  const { loadingMessage } = useContext(ConnectionContext);

  return (
    <Snackbar
      open={!!loadingMessage}
      message={loadingMessage}
    />
  )
}