import React from "react";
import InfoBox from "./InfoBox";
import styles from "./YourselfBase.module.css";

export default function YourselfBase({ children, infoBoxProps = {}, showInfoBox=true}) {
  return (
    <div className="page-root">
      {showInfoBox ? <InfoBox
        title="Enter Reporting Person Information"
        text={
          <>
            Please enter your information as completely as possible. You may be
            contacted regarding this incident. An email address is required if
            you would like to be notified when this report is received and
            approved.
          </>
        }
        {...infoBoxProps}
      /> : null}

      <div className={styles.fields + " shadow"}>{children}</div>
    </div>
  );
}
