import { React, useState } from "react";
import { useField } from "formik";
import { Button } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useContext } from "react";
import { ConnectionContext } from "../../contexts/Connection";
import styles from "./FileField.module.css";
import { Avatar } from "@mui/material";

export default function FileField({
  accept,
  id,
  icon,
  label,
  label2,
  renderValue,
  multiple = true,
  buttonSize = "large",
  fullWidth = true,
  retake = false,
  ...props
}) {
  const [field, , helpers] = useField(props);
  const { upMedia, loadingMessage } = useContext(ConnectionContext);

  const handleDelete = (id) => {
    helpers.setValue(field.value.filter((receipt) => receipt.id !== id));
  };

  const [image, setImage] = useState(null);
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      helpers.setValue(event.target.files[0], field.name);
    }
  };

  return (
    <>
      {renderValue && renderValue(field.value, handleDelete)}
      {retake ? (
        <div>
          <input
            {...field}
            accept={accept}
            style={{
              display: "none",
            }}
            id={id}
            type="file"
            value={undefined}
            onChange={onImageChange}
          />
          <label htmlFor={id}>
            <Button
              startIcon={
                <Avatar
                  src={image == null ? icon : image}
                  variant="rounded"
                  sx={{
                    transform: "scale(1.8)",
                    marginRight: 10,
                    marginLeft: 5,
                    maxWidth: 50,
                  }}
                />
              }
              component="span"
              size={buttonSize}
              fullWidth={false}
              disabled={!!loadingMessage}
              variant="contained"
              className={styles.button}
            >
              {image == null ? label : label2}
            </Button>
          </label>
        </div>
      ) : (
        <div>
          <input
            {...field}
            accept={accept}
            style={{
              display: "none",
            }}
            id="raised-button-file"
            type="file"
            value={undefined}
            onChange={(e) => {
              let newFile = e.currentTarget.files[0];
              upMedia(newFile, field.value).then((response) => {
                if (response) {
                  response.filename = newFile.name;
                  helpers.setValue([...field.value, response]);
                }
              });
            }}
          />
          <label htmlFor="raised-button-file">
            <Button
              startIcon={<AddPhotoAlternateIcon />}
              component="span"
              fullWidth={fullWidth}
              size={buttonSize}
              disabled={!!loadingMessage}
              variant="contained"
              // className={styles.button}
            >
              {label}
            </Button>
          </label>
        </div>
      )}
    </>
  );
}
