import React from "react";
import DateInput from "../DateInput";
import styles from "./DOB.module.css";

export default function DOB({ formKey = "reporter", required = true }) {
  return (
    <div className={styles.root}>
      <DateInput
        required={required}
        formKey={`${formKey}.dateOfBirth`}
        hasTime={false}
        label="Date of Birth"
      />
    </div>
  );
}
