import * as Yup from "yup";
import { genderList } from "../yourself-components/Gender";

export const reportTypeSelectionValidationSchema = Yup.object({
  reportType: Yup.string().required().oneOf(["Original", "Supplemental"]),
  originalReportNumber: Yup.string().when("reportType", {
    is: "Supplemental",
    then: Yup.string().required("Please enter the original report number."),
  }),
});

export const personTypeSelectionValidationSchema = Yup.object({
  reporterType: Yup.string().required().oneOf(["Individual", "Business"]),
});

const requiredErrorMsg = "You must fill all required fields.";

export const individualYourselfValidationSchema = Yup.object({
  firstName: Yup.string().required(requiredErrorMsg).max(20),
  lastName: Yup.string().required(requiredErrorMsg).max(20),
  homeAddress: Yup.object({
    country: Yup.string().required(requiredErrorMsg),
    streetNumber: Yup.string().max(30),
    streetName: Yup.string().required(requiredErrorMsg).max(100),
    streetType: Yup.string(),
    postDirection: Yup.string().oneOf(["E", "W", "S", "N"]),
    apartment: Yup.string().max(30),
    city: Yup.string().required(requiredErrorMsg).max(30),
    province: Yup.string().required(requiredErrorMsg),
    zipCode: Yup.string().required(requiredErrorMsg).max(20),
  }),
  contactPhoneNumber: Yup.string()
    .required()
    .max(12)
    .matches(
      /[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
      "Home phone is invalid."
    ),
  email: Yup.string().required().email("Invalid Email.").max(50),
  confirmEmail: Yup.string()
    .required()
    .oneOf([Yup.ref("email")], "Confirm Email has to match Email."),
  gender: Yup.string().required(requiredErrorMsg).oneOf(genderList),
  employerName: Yup.string().max(50),
  workPhone: Yup.string()
    .max(12)
    .matches(
      /[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
      "Work phone is invalid."
    ),
  workAddress: Yup.object({
    country: Yup.string(),
    streetNumber: Yup.string().max(30),
    streetName: Yup.string().max(100),
    streetType: Yup.string(),
    postDirection: Yup.string().oneOf(["E", "W", "S", "N"]),
    apartment: Yup.string().max(30),
    city: Yup.string().max(30),
    province: Yup.string(),
    zipCode: Yup.string().max(20),
  }),
  dateOfBirth: Yup.date().required(requiredErrorMsg).nullable().typeError("Invalid Date"),
  licenseNumber: Yup.object({
    number: Yup.string().max(50),
    provider: Yup.string(),
  }),
});

export const businessYourselfValidationSchema = Yup.object({
  firstName: Yup.string().required(requiredErrorMsg).max(20),
  lastName: Yup.string().required(requiredErrorMsg).max(20),
  homeAddress: Yup.object({
    country: Yup.string().required(requiredErrorMsg),
    streetNumber: Yup.string().max(30),
    streetName: Yup.string().required(requiredErrorMsg).max(100),
    streetType: Yup.string(),
    postDirection: Yup.string().oneOf(["E", "W", "S", "N"]),
    apartment: Yup.string().max(30),
    city: Yup.string().required(requiredErrorMsg).max(30),
    province: Yup.string().required(requiredErrorMsg),
    zipCode: Yup.string().required(requiredErrorMsg).max(20),
  }),
  contactPhoneNumber: Yup.string()
    .required()
    .max(12)
    .matches(
      /[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
      "Home phone is invalid."
    ),
  email: Yup.string().required().email("Invalid Email.").max(50),
  confirmEmail: Yup.string()
    .required()
    .oneOf([Yup.ref("email")], "Confirm Email has to match Email."),
  gender: Yup.string().required(requiredErrorMsg).oneOf(genderList),
  employerName: Yup.string().required(requiredErrorMsg).max(50),
  workPhone: Yup.string()
    .required(requiredErrorMsg)
    .max(12)
    .matches(
      /[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
      "Work phone is invalid."
    ),
  workAddress: Yup.object({
    country: Yup.string().required(requiredErrorMsg),
    streetNumber: Yup.string().max(30),
    streetName: Yup.string().required(requiredErrorMsg).max(100),
    streetType: Yup.string(),
    postDirection: Yup.string().oneOf(["E", "W", "S", "N"]),
    apartment: Yup.string().max(30),
    city: Yup.string().required(requiredErrorMsg).max(30),
    province: Yup.string().required(requiredErrorMsg),
    zipCode: Yup.string().required(requiredErrorMsg).max(20),
  }),
  dateOfBirth: Yup.date().required(requiredErrorMsg).nullable().typeError("Invalid Date"),
  licenseNumber: Yup.object({
    number: Yup.string().max(50),
    provider: Yup.string(),
  }),
});
