import React from "react";
import TextInputField from "../../form-fields/TextInputField";

export default function ContactPhoneNumber({
  formKey = "reporter",
  required = false,
  business = false,
}) {
  if (business) formKey += ".workPhone";
  else formKey += ".contactPhoneNumber";
  return (
    <TextInputField
      name={formKey}
      label={
        <>
          {required && <span style={{ color: "red" }}>* </span>}
          {!business && "Contact Phone Number"}
          {business && "Work Phone"}
        </>
      }
      helperText="(ex: 555-111-2222 - The system will auto-insert the dashes)"
      sx={{ width: 0.5 }}
      format={(currentValue) => {
        if (!currentValue) return currentValue;
        let value = currentValue.split("");
        if (value.length > 3 && value[3] !== "-") value.splice(3, 0, "-");
        if (value.length > 7 && value[7] !== "-") value.splice(7, 0, "-");
        value = value.join("");
        return value;
      }}
    />
  );
}
