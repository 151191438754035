import React from "react";
import VehicleBase, {
  colors,
  vehicleStyle,
  years,
} from "../../../../common/VehicleBase";
import SelectField from "../../../../form-fields/SelectField";
import TextInputField from "../../../../form-fields/TextInputField";
import styles from "./Vehicle.module.css";
import RadioField from "../../../../form-fields/RadioField";

export default function Vehicle({ formKey = "info.theftFromVehicle.vehicle" }) {
  return (
    <VehicleBase required={true} formKey={formKey}>
      <div className={styles.block}>
        <TextInputField
          name={formKey + ".vIN"}
          label={"VIN"}
          sx={{ width: 0.5 }}
        />
      </div>
      <div className={styles.block}>
        <SelectField
          name={formKey + ".style"}
          options={vehicleStyle.map((style) => ({
            value: style,
            label: style,
          }))}
          label={
            <>
              <span style={{ color: "red" }}>*</span> Style
            </>
          }
          sx={{ width: 0.5 }}
        />
        <SelectField
          name={formKey + ".year"}
          options={years.map((year) => ({
            value: year,
            label: year,
          }))}
          label={
            <>
              <span style={{ color: "red" }}>*</span> Year
            </>
          }
          sx={{ width: 0.5 }}
        />
      </div>
      <div className={styles.block}>
        <SelectField
          name={formKey + ".colours"}
          options={colors.map((color) => ({
            value: color,
            label: color,
          }))}
          label={
            <>
              <span style={{ color: "red" }}>*</span> Colour
            </>
          }
          sx={{ width: 0.5 }}
        />
      </div>
      <div className={styles.block}>
        <TextInputField
          name={formKey + ".marketValue"}
          label={"Market Value ($)"}
          sx={{ width: 0.5 }}
        />
        <TextInputField
          name={formKey + ".damagedValue"}
          label={"Damaged Value ($)"}
          sx={{ width: 0.5 }}
        />
      </div>
      <div className={styles.block}>
        <p>Vehicle Locked and Windows Closed?</p>
        <RadioField
          formKey={formKey + ".vehicleSituation"}
          type="radio"
          name={formKey + ".vehicleSituation"}
          value="yes"
          label="Yes"
        />
        <RadioField
          formKey={formKey + ".vehicleSituation"}
          type="radio"
          name={formKey + ".vehicleSituation"}
          value="no"
          label="No"
        />
      </div>
    </VehicleBase>
  );
}
