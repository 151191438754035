import React from "react";
import TextInputField from "../../../form-fields/TextInputField";
import styles from "../FirstMiddleLast.module.css";
import SelectField from "../../../form-fields/SelectField";
export default function AddressFields({
    formKey = "reporter.homeAddress",
    index = null,
    required = true,
}) {
    if (index !== null) {
        formKey = formKey + "." + index;
    }

    return (
        <div>
            <div className={styles.column}>
                <label className={styles.name}>Unit Number</label>
                <TextInputField
                    className={styles.textFieldStyle}
                    name={`${formKey}.unitNumber`}
                    label={
                        <>{required == null && <span style={{ color: "red" }}>* </span>} </>
                    }
                />


            </div>
            <div className={styles.column}>
                <label className={styles.name}>Street Number</label>
                <TextInputField
                    className={styles.textFieldStyle}
                    name={`${formKey}.streetNumber`}
                    label={
                        <>{required == null && <span style={{ color: "red" }}>* </span>} </>
                    }
                />

            </div>
            <div className={styles.column}>
                <label className={styles.name}>Street Name</label>
                <TextInputField
                    className={styles.textFieldStyle}
                    name={`${formKey}.streetName`}
                    label={
                        <>{required && <span style={{ color: "red" }}>* </span>} </>
                    }

                />
            </div>
            <div className={styles.column}>
                <label className={styles.name}>Post Direction </label>
                <SelectField
                    name={`${formKey}.postDirection`}
                    label={
                        <>
                            {required == null && (
                                <span style={{ color: "red" }}>* </span>
                            )}{" "}

                        </>
                    }
                    options={postDirOptions}
                />
            </div>

        </div>
    );
}


const postDirOptions = [
    { value: "", label: "Post Dir" },
    { value: "E", label: "E" },
    { value: "W", label: "W" },
    { value: "S", label: "S" },
    { value: "N", label: "N" },
];
