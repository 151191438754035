import * as Yup from "yup";
import { incidentBaseValidationSchema } from "../../../../common/incidentBaseValidation";
import { narrativeBaseValidationSchema } from "../../../../common/narrativeBaseValidation";
import {
  businessYourselfValidationSchema,
  individualYourselfValidationSchema,
  personTypeSelectionValidationSchema,
  reportTypeSelectionValidationSchema,
} from "../../../../common/templates/validation";
import {
  eyeColors,
  involvementTypes,
  hairColors,
  footList,
  inchList,
} from "../../../../common/personBaseValidation";

const requiredErrorMsg = "You must fill all required fields.";

export const start0 = Yup.object({
  start0: Yup.object({
    wasWithinCobourg: Yup.string()
      .required("All questions must be answered.")
      .matches(
        /yes/,
        "If this did not occur inside the city of Cobourg, please contact the appropriate law enforcement agency to file this report."
      ),
    isOwnerOrEmpolyee: Yup.string()
      .required("All questions must be answered.")
      .matches(
        /yes/,
        "If you are not the owner or employee of the business, you may NOT file this report on their behalf."
      ),
    haveAtLeastOnePhotograph: Yup.string()
      .required("All questions must be answered.")
      .matches(
        /yes/,
        "In order to submit this report, you must provide at least one photograph."
      ),
  }),
});

export const metaStart1 = Yup.object({
  meta: reportTypeSelectionValidationSchema,
});

export const metaStart2 = Yup.object({
  meta: personTypeSelectionValidationSchema,
});

export const individualYourself = Yup.object({
  reporter: individualYourselfValidationSchema,
});

export const businessYourself = Yup.object({
  reporter: businessYourselfValidationSchema,
});

export const incident = Yup.object({
  info: Yup.object({
    theftOfGas: Yup.object({
      haveAdditionalInfo: Yup.string()
        .oneOf(["yes", "no"])
        .required(requiredErrorMsg),
      locationType: Yup.string().required(requiredErrorMsg),
    }).concat(incidentBaseValidationSchema),
  }),
  incident: Yup.object({
    photographs: Yup.array().min(1, "You need to add at least one photograph"),
  }),
});

export const personsDecriptions = Yup.object().shape({
  info: Yup.object({
    theftOfGas: Yup.object({
      involvedPersons: Yup.array().of(
        Yup.object({
          involvementType: Yup.string()
            .required(requiredErrorMsg)
            .oneOf(involvementTypes),

          firstName: Yup.string().max(20),
          lastName: Yup.string().max(20),
          email: Yup.string().email("Invalid Email.").max(50),
          employerName: "",
          workAdress: Yup.object({
            country: Yup.string(),
            streetNumber: Yup.string().max(30),
            streetName: Yup.string().max(100),
            streetType: Yup.string(),
            postDirection: Yup.string().oneOf(["E", "W", "S", "N"]),
            apartment: Yup.string().max(30),
            city: Yup.string().max(30),
            province: Yup.string(),
            zipCode: Yup.string().max(20),
          }),
          workNumber: Yup.string().matches(
            /[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
            "Work phone is invalid."
          ),
          race: Yup.string(),
          residentStatus: Yup.string(),
          gender: Yup.string(),
          dateOfBirth: Yup.date().nullable().typeError("Invalid Date"),
          sSN: Yup.string(),
          licenseNumber: Yup.object({
            number: Yup.string().max(50),
            provider: Yup.string(),
          }),
          age: Yup.string(),
          height: Yup.object({
            foot: Yup.number().oneOf(footList, "Feet should be a number"),
            inch: Yup.number().oneOf(inchList, "Inch should be a number"),
          }),
          weight: Yup.string(),
          eyeColor: Yup.string().oneOf[eyeColors],
          hairColor: Yup.string().oneOf[hairColors],
        })
      ),
    }),
  }),
});

export const vehicle = Yup.object({
  info: Yup.object({
    theftOfGas: Yup.object({
      vehicle: Yup.object({
        involvementType: Yup.string().required(requiredErrorMsg),
        maker: Yup.string(),
        model: Yup.string(),
        vIN: Yup.string(),
        year: Yup.string(),
        colours: Yup.string(),
        licenseType: Yup.string(),
        licensePlateNo: Yup.string(),
        licenseLocation: Yup.string(),
      }),
    }),
  }),
});

export const narrative = Yup.object({
  info: Yup.object({
    theftFromVehicle: narrativeBaseValidationSchema,
  }),
});

export const status = ["Nonresident", "Resident", "Unknown"];
