import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AnimateHeight from "react-animate-height";
import { Alert, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./IncidentTypeSelection.module.css";
import RadioField from "../../../form-fields/RadioField";
import { TimerResetContext } from "../../../../contexts/Timeout";
import { FormDataContext } from "../../../../contexts/FormData";
import InfoBox from "../../../common/InfoBox";
import CopyRight from "../../../common/CopyRight";
import config from "../../../../config";

const DescriptiveRadioField = ({ label, definition, example }) => {
  const [height, setHeight] = useState(0);

  const handleExpand = (e) => {
    e.preventDefault();

    setHeight((height) => (height ? 0 : "auto"));
  };

  return (
    <div className={styles.field}>
      <RadioField
        name="meta.incidentType"
        type="radio"
        value={label}
        style={{
          width: "100%",
          padding: "0.3rem 1rem",
          boxShadow:
            height === "auto"
              ? "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
              : "",
          transition: ".4s",
        }}
        label={
          <div className={styles.labelRoot}>
            <p>{label}</p>
            <div className={styles.expandIconContainer} onClick={handleExpand}>
              <ExpandMoreIcon
                style={{
                  transform: `rotate(${height ? "180deg" : 0})`,
                  transition: "0.4s",
                }}
              />
            </div>
          </div>
        }
      />
      <AnimateHeight height={height} duration={400}>
        <div className={styles.incidentDetails + " inner-shadow"}>
          <div className={styles.detailsItem}>
            <div className={styles.detailsHeader}>Definition</div>
            <p>{definition}</p>
          </div>
          <div className={styles.detailsItem}>
            <div className={styles.detailsHeader}>Example</div>
            <p>{example}</p>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};

const validationSchema = Yup.object({
  meta: Yup.object({
    incidentType: Yup.string().required(
      "You must provide a value for Incident Type."
    ),
  }),
});

export default function IncidentTypeSelection() {
  const controlResetTimer = useContext(TimerResetContext);
  const { setFormData } = useContext(FormDataContext);

  const handleSubmit = async (values) => {
    setFormData({
      meta: {
        schemaVersion: "1.0.0",
        region: "Cobourg",
        incidentType: values.meta.incidentType,
      },
    });
    controlResetTimer();
  };

  return (
    <div className="page-root">
      <InfoBox
        title="Select Incident Type"
        text={
          <>
            There are several different incident types that can be entered using
            the internet. Please select one from the list below and continue. If
            the incident you are reporting has a known suspect, you should
            instead call us.
          </>
        }
      />
      <Formik
        initialValues={{ meta: { incidentType: "" }}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <Form className={styles.form}>
              {props.errors.incidentType && (
                <Alert severity="error">{props.errors.incidentType}</Alert>
              )}
              <div className={styles.fields + " shadow"}>
                <DescriptiveRadioField
                  label="Bicycle Registration"
                  definition={
                    <>
                      The Cobourg Police Service has created a bicycle
                      registration program to help protect bicycles owned by
                      residents of Cobourg . This program will increase the
                      chances of having your bicycle returned to you if it is
                      stolen or lost. It is important to remember that you still
                      have to make a police report if your bike is stolen.
                    </>
                  }
                  example={
                    <>
                      You own a bicycle and wish to register it with the Cobourg
                      Police Service. In the event your bicycle(s) is stolen in
                      the future, having this information saved with the Cobourg
                      PS may help to return your bicycle to you should it be
                      recovered.
                    </>
                  }
                />
                <DescriptiveRadioField
                  label="Bullying"
                  definition={
                    <>
                      Repeated, persistent and aggressive behavior intended to
                      cause fear, distress, or harm to another person ’s body,
                      emotions, self-esteem or reputation. This includes but is not
                      limited to: Verbal, physical, social and cyber-bullying
                    </>
                  }
                  example={<>Bullying</>}
                />
                <DescriptiveRadioField
                  label="Damage/Mischief to Property"
                  definition={
                    <>
                      The act of vandalizing or defacing property without the
                      owner's consent.
                    </>
                  }
                  example={
                    <>
                      Graffiti, broken lawn ornaments, throwing a rock through a
                      window, etc.
                    </>
                  }
                />
                <DescriptiveRadioField
                  label="Damage/Mischief to Vehicle"
                  definition={
                    <>
                      The act of vandalizing or defacing a vehicle. If your
                      vehicle was damaged and items were stolen, please choose
                      incident type "Theft from Vehicle".
                    </>
                  }
                  example={
                    <>
                      Keying, broken windows, slashing tires, vehicle ransacked,
                      etc.
                    </>
                  }
                />
                <DescriptiveRadioField
                  label="Lost Property"
                  definition={<>When property is missing or lost.</>}
                  example={
                    <>
                      Property that is missing, leaving items in restaurant, lost
                      cell phone, etc.
                    </>
                  }
                />
                <DescriptiveRadioField
                  label="Theft from Vehicle"
                  definition={
                    <>
                      Property is taken from a vehicle without the owner's
                      permission.
                    </>
                  }
                  example={
                    <>
                      Stereo, personal items, laptop, etc. taken from a vehicle
                      without the owner's permission.
                    </>
                  }
                />
                <DescriptiveRadioField
                  label="Theft of Gas"
                  definition={<>When gasoline is taken without payment.</>}
                  example={
                    <>
                      Someone takes gasoline without paying for it.{" "}
                      <span style={{ color: "red" }}>
                        NOTE: This is for gas stations only.
                      </span>
                    </>
                  }
                />
                <DescriptiveRadioField
                  label="Theft Under $5,000"
                  definition={
                    <>
                      Property other than a vehicle is taken without your
                      permission.
                    </>
                  }
                  example={
                    <>
                      Something that you own is taken without the owner's consent.{" "}
                      <b>Stolen vehicles cannot be reported online.</b> This
                      includes cars, trucks, atv's, snowmachines, trailers and
                      road building machines.
                    </>
                  }
                />
                <DescriptiveRadioField
                  label="Traffic Complaint"
                  definition={
                    <>
                      This program is a community driven program which encourages
                      reporting of incidents involving dangerous and or aggressive
                      driving. A dangerous or aggressive driver should never be
                      approached or confronted by a citizen.
                    </>
                  }
                  example={
                    <>
                      TRAFFIC COMPLAINT reports could include: • Excessive speed •
                      Aggressive driving • Careless / Dangerous driving • Stop
                      sign or red light violations • Unsafe passing • Following
                      too closely • Fail to yield to pedestrian • School bus
                      violations • Distracted driver (failure to use hands-free
                      device) • Community safety zone violations
                    </>
                    // TODO make traffic complaint info a bullet list
                  }
                />
              </div>
              <div className={styles.submit}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={!props.values.meta.incidentType}
                >
                  Start Report
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <CopyRight />
    </div>
  );
}
