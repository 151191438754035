import React from "react";
import AddressMobileBase from "./AddressMobileBase";
import AddressFields from "./AddressFields";
import AddressFields1 from "./AddressFields1";
export default function AddressMobile({ step }) {
    switch (step) {
        case "3":
            return (<AddressMobileBase>
                <div >
                    <AddressFields/>
                </div>
            </AddressMobileBase>);
        case "2":
            return (<AddressMobileBase>
                <div >
                    <AddressFields1 />
                </div>
            </AddressMobileBase>);
        default:
            return null;
    }

}

