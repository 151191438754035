import React from "react";
import InfoBox from "../../../../common/InfoBox";
import styles from "./Person.module.css";
import * as Fields from "./Fields";
import { FieldArray } from "formik";
import { useField } from "formik";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FirstAndLastName from "../../../../common/yourself-components/FirstAndLastName";
import Address from "../../../../common/yourself-components/Address";
import ContactPhoneNumber from "../../../../common/yourself-components/ContactPhoneNumber";
import DOB from "../../../../common/yourself-components/DOB";
import Gender from "../../../../common/yourself-components/Gender";
import Race from "../../../../common/Race";
import TextInputField from "../../../../form-fields/TextInputField";

export default function Person({ formKey = "info.bullying.involvedPersons" }) {
  const [field, meta, helpers] = useField(formKey);
  const { value } = meta;
  const [open, setOpen] = React.useState([true, true]);

  const handleClick = (index) => () => {
    setOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      newOpen[index] = !newOpen[index];
      return newOpen;
    });
  };

  return (
    <div className="page-root">
      <InfoBox
        title="Enter Person Information"
        text="Please enter information of people involved in bullying (Suspects, Witnesses, etc.)"
      />
      <div className={styles.fields + " shadow"}>
        <FieldArray
          name={formKey}
          render={(arrayHelpers) => (
            <div>
              {value.length > 0 &&
                value.map((persons, index) => (
                  <div key={index}>
                    <div className={styles.expandButton} value={index}>
                      <p
                        onClick={handleClick(index)}
                        className={styles.boxTitle}
                      >
                        Person {index + 1}
                      </p>
                      {open[index] ? (
                        <ExpandLess onClick={handleClick(index)} />
                      ) : (
                        <ExpandMore onClick={handleClick(index)} />
                      )}

                      <IconButton
                        onClick={() => {
                          if (value.length > 0) {
                            arrayHelpers.remove(index);
                            setOpen((prevOpen) => {
                              const newOpen = [...prevOpen];
                              newOpen.splice(index, 1);
                              return newOpen;
                            });
                          }
                        }}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    <Collapse in={open[index]} timeout="auto" unmountOnExit>
                      <Fields.InvolvementType formKey={formKey} index={index} />

                      <FirstAndLastName
                        formKey={formKey}
                        index={index}
                        required={false}
                      />

                      <Address
                        requiredFields=""
                        formKey={formKey + "." + index}
                        type="home"
                      />
                      <div className={styles.root}>
                        <ContactPhoneNumber formKey={formKey + "." + index} />
                      </div>
                      <div className={styles.root}>
                        <Race
                          formKey={formKey + "." + index}
                          required={false}
                        />

                        <Gender
                          formKey={formKey + "." + index}
                          required={false}
                        />
                      </div>
                      <DOB required={false} formKey={formKey + "." + index} />
                      <div className={styles.root}>
                        <TextInputField
                          name={formKey + "." + index + ".school"}
                          label={"What School?"}
                          sx={{ width: 1 / 2 }}
                        />
                        <TextInputField
                          name={formKey + "." + index + ".grade"}
                          label={"What Grade?"}
                          sx={{ width: 1 / 2 }}
                        />
                      </div>
                      <Fields.AgeHeight formKey={formKey} index={index} />
                      <Fields.PersonSize formKey={formKey} index={index} />
                      <Fields.EyeHairColor formKey={formKey} index={index} />
                      <Fields.FacialHairStyle formKey={formKey} index={index} />
                      <div className={styles.root}>
                        <TextInputField
                          sx={{ width: 1 }}
                          name={
                            formKey +
                            "." +
                            index +
                            ".clothingOrPhysicalDescription"
                          }
                          label={"Clothing or Physical Description"}
                        />
                      </div>
                      <div className={styles.root}>
                        <TextInputField
                          sx={{ width: 1 }}
                          multiline
                          name={formKey + "." + index + ".tattoosOrMarks"}
                          label={"Any tattoos or marks on body or face?"}
                        />
                      </div>
                    </Collapse>
                  </div>
                ))}
              <Button
                fullWidth
                variant="outlined"
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  arrayHelpers.push({
                    involvementType: "",
                    firstName: "",
                    lastName: "",
                    homeAddress: {
                      country: "Canada",
                      streetNumber: "",
                      streetName: "",
                      streetType: "",
                      postDirection: "",
                      apartment: "",
                      city: "",
                      province: "Ontario",
                      zipCode: "",
                    },
                    contactPhoneNumber: "",
                    race: "",
                    gender: "",
                    dateOfBirth: null,
                    school: "",
                    grade: "",
                    age: "",
                    height: {
                      foot: 0,
                      inch: 0,
                    },
                    personSize: "",
                    eyeColor: "",
                    hairColor: "",
                    hairStyle: "",
                    facialHair: "",
                    clothingOrPhysicalDescription: "",
                    tattoosOrMarks: "",
                  });
                  setOpen((prevOpen) => [...prevOpen, true]);
                }}
              >
                Add Person
              </Button>
            </div>
          )}
        />
      </div>
    </div>
  );
}
