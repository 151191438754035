import React from "react";
import InfoBox from "../InfoBox";
import RadioField from "../../form-fields/RadioField";
import styles from "./ReportTypeSelection.module.css";
import TextInputField from "../../form-fields/TextInputField";

export default function ReportTypeSelection({
  formKey = "meta",
  infoBoxProps = {
    title: "Select Report Type",
    text: "Please select the report type: original or supplemental.",
  },
}) {
  return (
    <div className="page-root">
      <InfoBox {...infoBoxProps} />
      <div className={styles.fields + " shadow"}>
        <div className={styles.field}>
          <RadioField
            name={`${formKey}.reportType`}
            type="radio"
            value="Original"
            style={{
              width: "100%",
              padding: "0.3rem 1rem",
            }}
            label={
              <>
                Original -{" "}
                <span style={{ fontSize: ".9rem" }}>
                  This is the first report that is being filed for this incident
                </span>
                .
              </>
            }
          />
        </div>
        <div className={styles.field}>
          <RadioField
            name={`${formKey}.reportType`}
            type="radio"
            value="Supplemental"
            style={{
              width: "100%",
              padding: "0.3rem 1rem",
            }}
            label={
              <>
                Supplemental -{" "}
                <span style={{ fontSize: ".9rem" }}>
                  You are adding information to a previous report which was
                  submitted online.
                </span>
              </>
            }
          />
          <div className={styles.reportNumber}>
            <TextInputField
              name={`${formKey}.originalReportNumber`}
              label="Original report number"
              helperText="ex: Cobourg-2022-01-20-1"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
