import React from "react";
import VerificationBase from "./VerificationBase";
import styles from "./Verification.module.css";
import OptionRadioField from "../common/OptionRadioField";
import SignatureWrapper from "../../../../../common/SignatureWrapper";
import PhotoScreen from "./PhotoScreen";
export default function Verification({ step }) {
    const values = ["Examine Copy", "Receive Copy"];
    switch (step) {
        case "0":
            return (<VerificationBase>
                <div className={styles.body}>
                    <div className={styles.column}>
                        <OptionRadioField text={"Preferred method of access to records:"} formKey={"info.adminreport.foireport.methodAccess"} values={values} required={true} ></OptionRadioField>
                        <p className={styles.column}>Signature :</p>
                        <SignatureWrapper name={"info.adminreport.foireport.signature"} required={true} />
                    </div>
                </div>
            </VerificationBase>);
        case "1":
            return (
                <PhotoScreen />
            );
        default:
            return null;
    }

}
