import { useField, Field } from "formik";
import { React, useRef} from "react";
import SignaturePad from 'react-signature-canvas';
import { Button } from "@mui/material";
import { FormControl } from "@mui/material";
import styles from "./SignatureWrapper.module.css";
import "./SignatureWrapper.module.css";
/* 
Signature pad uses Signature canvas , turns the url into a file object.
*/
export default function SignatureWrapper({
    name,
    ...otherProps
}) {
    const [field] = useField(name);
    let sigPad = useRef({});
    function clear() {
        sigPad.current.clear();
    }

    const configField = {
        ...field,
        ...otherProps,
    }
    const urlToObject = async (image) => {
        const response = await fetch(image);
        // here image is url/location of image
        const blob = await response.blob();
        const file = new File([blob], 'signature.png', { type: blob.type });
        return file;
    }
    return (

        <FormControl >
            <Field name={name} >
                {
                    ({ form }) => {
                        const { setFieldValue } = form
                        return (
                            <div>
                                <div className={styles.canvas}>
                                    <SignaturePad
                                        onEnd={() => {
                                            urlToObject(sigPad.current.toDataURL()).then(function (file) {
                                                console.log(file)
                                                if (file) setFieldValue(name, file);
                                            });
                                        }}
                                        {...configField} ref={sigPad} canvasProps={{ width: 500, height: 100 }} />
                                </div>
                                <Button onClick={clear} variant="contained" sx={{ marginLeft: 1, marginTop: 1 }}> Clear</Button>
                            </div>
                        )
                    }
                }
            </Field>
        </FormControl>

    );
};
