import React, { createContext, useState } from "react";

export const PaymentContext = createContext();

export default function PaymentProvider({ children }) {
    const [value, setValue] = useState(false);

    return (
        <PaymentContext.Provider value={{ value, setValue }}>
            {children}
        </PaymentContext.Provider>
    );
}
