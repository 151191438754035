import React from "react";
import FormTemplate from "../../../../common/templates";

const questions = [
  {
    value: "Did this incident occur within the city limits of Cobourg?",
    name: "start0.wasWithinCobourg",
  },
  {
    value:
      "Are you the owner/employee of the gas station wishing to report a theft of gas?",
    name: "start0.isOwnerOrEmpolyee",
  },
  {
    value:
      "Do you have at least one photograph to upload regarding this report?",
    name: "start0.haveAtLeastOnePhotograph",
  },
];

export default function Start({ step }) {
  switch (step) {
    case "0":
      return (
        <div>
          <FormTemplate
            templateName="confirm-questions"
            questions={questions}
          />
        </div>
      );
    case "1":
      return (
        <div>
          <FormTemplate templateName="report-type-selection" formKey="meta" />
        </div>
      );
    default:
      return null;
  }
}
