import * as Yup from "yup";
import { incidentBaseValidationSchema } from "../../../../common/incidentBaseValidation";
import { narrativeBaseValidationSchema } from "../../../../common/narrativeBaseValidation";
import {
  businessYourselfValidationSchema,
  individualYourselfValidationSchema,
  personTypeSelectionValidationSchema,
  reportTypeSelectionValidationSchema,
} from "../../../../common/templates/validation";

const requiredErrorMsg = "You must fill all required fields.";

export const start0 = Yup.object({
  start0: Yup.object({
    isEmergency: Yup.string()
      .required("All questions must be answered.")
      .matches(/no/, "If this is an Emergency call 911."),
    haveValidEmailAddress: Yup.string()
      .required("All questions must be answered.")
      .matches(
        /yes/,
        "You must have a valid email address to file a report online."
      ),
    haveKnownSuspects: Yup.string()
      .required("All questions must be answered.")
      .matches(
        /no/,
        "Please call the Cobourg Police Service by phone at 905-372-6821 to file your police report. Only reports without suspects can be filed online."
      ),
    damageWasOver5000: Yup.string()
      .required("All questions must be answered.")
      .matches(
        /no/,
        "Please call the Cobourg Police Service by phone at 905-372-6821 to file your police report. Only reports less than $5,000.00 in damage value can be filed online."
      ),
    wasWithinCobourg: Yup.string()
      .required("All questions must be answered.")
      .matches(
        /yes/,
        "If the damage or mischief to your vehicle occurred outside Cobourg, please contact the police service responsible for that area."
      ),
  }),
});

export const metaStart1 = Yup.object({
  meta: reportTypeSelectionValidationSchema,
});

export const metaStart2 = Yup.object({
  meta: personTypeSelectionValidationSchema,
});

export const individualYourself = Yup.object({
  reporter: individualYourselfValidationSchema,
});

export const businessYourself = Yup.object({
  reporter: businessYourselfValidationSchema,
});

export const incident = Yup.object({
  info: Yup.object({
    damageToVehicle: Yup.object({
      locationType: Yup.string().required(requiredErrorMsg),
      graffitiInvolved: Yup.string().required(requiredErrorMsg),
    }).concat(incidentBaseValidationSchema),
  }),
});

// TODO reuse vaildation accross all vehicle forms
export const vehicle = Yup.object({
  info: Yup.object({
    damageToVehicle: Yup.object({
      vehicle: Yup.object({
        involvementType: Yup.string().required(requiredErrorMsg),
        maker: Yup.string().required(requiredErrorMsg),
        model: Yup.string().required(requiredErrorMsg),
        vIN: Yup.string(),
        style: Yup.string().required(requiredErrorMsg),
        year: Yup.string().required(requiredErrorMsg),
        colours: Yup.string().required(requiredErrorMsg),
        marketValue: Yup.number().typeError("Value should be a number"),
        damagedValue: Yup.number().typeError("Value should be a number"),
        licenseType: Yup.string(),
        licensePlateNo: Yup.string().required(requiredErrorMsg),
        licenseLocation: Yup.string().required(requiredErrorMsg),
      }),
    }),
  }),
});

export const narrative = Yup.object({
  info: Yup.object({
    damageToVehicle: narrativeBaseValidationSchema,
  }),
});
