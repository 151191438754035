import * as Yup from "yup";
import { genderList } from "../../../../common/yourself-components/Gender";
import { narrativeBaseValidationSchema } from "../../../../common/narrativeBaseValidation";

const requiredErrorMsg = "Please fill all required fields.";

const reporter = Yup.object({
  reporter: Yup.object({
    firstName: Yup.string().required(requiredErrorMsg).max(20),
    lastName: Yup.string().required(requiredErrorMsg).max(20),
    middleName: Yup.string(),
    homeAddress: Yup.object({
      country: Yup.string().required(requiredErrorMsg),
      unitNumber: Yup.string().max(30),
      streetNumber: Yup.string().max(30),
      streetName: Yup.string().required(requiredErrorMsg).max(100),
      streetType: Yup.string(),
      postDirection: Yup.string().oneOf(["E", "W", "S", "N"]),
      apartment: Yup.string().max(30),
      city: Yup.string().required(requiredErrorMsg).max(30),
      province: Yup.string().required(requiredErrorMsg),
      zipCode: Yup.string().required(requiredErrorMsg).max(20),
    }),
    contactPhoneNumber: Yup.string()
      .max(12)
      .matches(
        /[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
        "Home phone is invalid."
      ),
    email: Yup.string()
      .email("Invalid Email.")
      .required(requiredErrorMsg)
      .max(50),
    confirmEmail: Yup.string()
      .required(requiredErrorMsg)
      .oneOf([Yup.ref("email")], "Confirm Email has to match Email."),
    gender: Yup.string().required(requiredErrorMsg).oneOf(genderList),
    dateOfBirth: Yup.string().required(requiredErrorMsg),
  }),
});

const yourself0 = Yup.object({
  reporter: Yup.object({
    firstName: Yup.string().required(requiredErrorMsg).max(20).matches(/^[aA-zZ\s]+$/, "Characters only"),
    lastName: Yup.string().required(requiredErrorMsg).max(20).matches(/^[aA-zZ\s]+$/, "Characters only"),
    middleName: Yup.string().max(20).matches(/^[aA-zZ\s]+$/, "Characters only"),
    dateOfBirth: Yup.date().required(requiredErrorMsg).nullable().typeError("Invalid Date"),
  }),
});

const yourself1 = Yup.object({
  reporter: Yup.object({
    gender: Yup.string().required(requiredErrorMsg).oneOf(genderList),
    phoneNumber: Yup.string().required(requiredErrorMsg)
      .max(12)
      .matches(
        /[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
        "Phone Number is invalid."
      ),
    otherNumber: Yup.string()
      .max(12).required(requiredErrorMsg)
      .matches(
        /[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
        "Home phone is invalid."
      ),
    email: Yup.string()
      .email("Invalid Email.")
      .required(requiredErrorMsg)
      .max(50),

  }),
});
const yourself2 = Yup.object({
  reporter: Yup.object({
    homeAddress: Yup.object({
      country: Yup.string().required(requiredErrorMsg),
      city: Yup.string().required(requiredErrorMsg).max(30),
      province: Yup.string().required(requiredErrorMsg),
      zipCode: Yup.string().required(requiredErrorMsg).max(20),
    }),

  }),
});
const yourself3 = Yup.object({
  reporter: Yup.object({
    homeAddress: Yup.object({
      unitNumber: Yup.string(),
      streetNumber: Yup.string().max(30),
      streetName: Yup.string().required(requiredErrorMsg),
      postDirection: Yup.string().oneOf(["E", "W", "S", "N"]),
    }),

  }),
});
const intro0 = Yup.object({
  info: Yup.object({
    adminreport: Yup.object({
      foireport:
        Yup.object({
          request: Yup.string().required(requiredErrorMsg),
        }),

    }),
  }),
});
const intro1 = Yup.object({
  info: Yup.object({
    adminreport: Yup.object({
      foireport:
        Yup.object({
          contactIndivisual: Yup.string().required(requiredErrorMsg),
          releaseIdentity: Yup.string().required(requiredErrorMsg),
        }),

    }),
  }),
});
const verification0 = Yup.object({
  info: Yup.object({
    adminreport: Yup.object({
      foireport:
        Yup.object({
          methodAccess: Yup.string().required(requiredErrorMsg),
          signature: Yup.string().nullable().required(requiredErrorMsg),
        })

    }),
  }),
});

const verification1 = Yup.object({
  info: Yup.object({
    adminreport: Yup.object({
      foireport:
        Yup.object({
          photoFace: Yup.string().nullable().required(requiredErrorMsg),
          photoID: Yup.string().nullable().required(requiredErrorMsg),
          photoBoth: Yup.string().nullable().required(requiredErrorMsg),
        })

    }),
  }),
});

export const narrative = narrativeBaseValidationSchema;

export {
  reporter,
  intro0,
  intro1,
  yourself0,
  yourself1,
  yourself2,
  yourself3,
  verification0,
  verification1,

};
