import React from "react";
import { FormControlLabel, Radio } from "@mui/material";
import { useField } from "formik";
import styles from "./RadioField.module.css";

export default function RadioField({
  style = {},
  radioProps = {},
  label,
  sx={},
  ...props
}) {
  const [field] = useField(props);
  return (
    <div>
      <FormControlLabel
        {...field}
        className={styles.label}
        control={<Radio size="small" {...radioProps} />}
        label={label}
        style={style}
        sx={sx}
      />
    </div>
  );
}
