import React from "react";
import InfoBox from "../InfoBox";
import RadioField from "../../form-fields/RadioField";
import styles from "./PersonTypeSelection.module.css";

export default function PersonTypeSelection({
  formKey = "meta",
  infoBoxProps = {
    title: "Select Reporting Person Type",
    text: "Please select a proper person type according to the definition below.",
  },
}) {
  return (
    <div className="page-root">
      <InfoBox {...infoBoxProps} />
      <div className={styles.fields + " shadow"}>
        <div className={styles.field}>
          <RadioField
            name={`${formKey}.reporterType`}
            type="radio"
            value="Individual"
            style={{
              width: "100%",
              padding: "0.3rem 1rem",
            }}
            label={
              <>
                Individual -{" "}
                <span style={{ fontSize: ".9rem" }}>
                  If you are reporting this for yourself.
                </span>
              </>
            }
          />
        </div>
        <div className={styles.field}>
          <RadioField
            name={`${formKey}.reporterType`}
            type="radio"
            value="Business"
            style={{
              width: "100%",
              padding: "0.3rem 1rem",
            }}
            label={
              <>
                Business -{" "}
                <span style={{ fontSize: ".9rem" }}>
                  If you are responsible for reporting this for your employer,
                  government agency, or your own business.
                </span>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}
