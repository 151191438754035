import React from "react";
import VehicleBase, {
  colors,
  vehicleStyle,
  years,
  licenseTypes,
} from "../../../../common/VehicleBase";
import SelectField from "../../../../form-fields/SelectField";
import TextInputField from "../../../../form-fields/TextInputField";
import styles from "./Vehicle.module.css";

export default function Vehicle({ formKey = "info.damageToVehicle.vehicle" }) {
  return (
    <VehicleBase required={true} formKey={formKey}>
      <div className={styles.block}>
        <TextInputField
          name={formKey + ".vIN"}
          label={"VIN"}
          sx={{ width: 0.5 }}
        />
      </div>
      <div className={styles.block}>
        <SelectField
          name={formKey + ".style"}
          options={vehicleStyle.map((style) => ({
            value: style,
            label: style,
          }))}
          label={
            <>
              <span style={{ color: "red" }}>*</span> Styles
            </>
          }
          sx={{ width: 0.5 }}
        />
        <SelectField
          name={formKey + ".year"}
          options={years.map((year) => ({
            value: year,
            label: year,
          }))}
          label={
            <>
              <span style={{ color: "red" }}>*</span> Year
            </>
          }
          sx={{ width: 0.5 }}
        />
      </div>
      <div className={styles.block}>
        <SelectField
          name={formKey + ".colours"}
          options={colors.map((color) => ({
            value: color,
            label: color,
          }))}
          label={
            <>
              <span style={{ color: "red" }}>*</span> Colour
            </>
          }
          sx={{ width: 0.5 }}
        />
      </div>
      <div className={styles.block}>
        <TextInputField
          name={formKey + ".marketValue"}
          label={"Market Value ($)"}
          sx={{ width: 0.5 }}
        />
        <TextInputField
          name={formKey + ".damagedValue"}
          label={"Damaged Value ($)"}
          sx={{ width: 0.5 }}
        />
      </div>

      <div className={styles.block}>
        <SelectField
          name={formKey + ".licenseType"}
          options={licenseTypes.map((type) => ({
            value: type,
            label: type,
          }))}
          label="License Plate Type"
          sx={{ width: 0.5 }}
        />
      </div>
    </VehicleBase>
  );
}
