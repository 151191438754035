import React, { createContext, useState } from "react";

export const FormDataContext = createContext();

export default function FormDataProvider({ children }) {
  const [formData, setFormData] = useState({ meta: {} });

  return (
    <FormDataContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormDataContext.Provider>
  );
}
