import React, { useState, useContext, useMemo, useRef, useEffect } from "react";
import { Formik } from "formik";
import { Alert, Button } from "@mui/material";
import { PageContext } from "../../contexts/Page";
import { FormDataContext } from "../../contexts/FormData";
import { TimerResetContext } from "../../contexts/Timeout";
import styles from "./FormBase.module.css";
import CopyRight from "./CopyRight";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { ConnectionContext } from "../../contexts/Connection";
import StepperCard from "./StepperCard";
import { PaymentContext } from "../../contexts/PaymentContext";

export default function FormBase({
  children,
  validationSchema,
  initialValues,
  submitCallback,
  validateOnChange = false,
}) {
  const { previousPage, nextPage, page, pages, setPages } =
    useContext(PageContext);
  const { formData, setFormData } = useContext(FormDataContext);
  const controlResetTimer = useContext(TimerResetContext);
  const { submitCrimeForm, loadingMessage, submitAdminForm } =
    useContext(ConnectionContext);
  const { value } = useContext(PaymentContext);
  const submitDirectionRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const isFirstPage = useMemo(() => !pages.indexOf(page), [pages, page]);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleSubmit = (direction, submitForm) => {
    submitDirectionRef.current = direction;
    submitForm();
  };

  const submitFunction = async () => {
    submitAdminForm(formData);
  };

  const handleHasError = (errors, values) => {
    // redirect user to previous page since validation failure prevented onSubmit from doing it
    if (submitDirectionRef.current === "back") {
      submitDirectionRef.current = null;
      const formKey = Object.keys(values)[0];
      setFormData((formData) => ({
        ...formData,
        [formKey]: { ...values[formKey] },
      }));
      previousPage();
      return;
    }

    return renderErrorMsg(errors);
  };

  const renderErrorMsg = (errors) => {
    let value = { ...errors };

    while (typeof value === "object") {
      value = value[Object.keys(value)[0]];
    }

    if (!value) {
      return null;
    }
    return <Alert severity="error">{value}</Alert>;
  };

  console.log(formData);

  function chooseButtons(submitForm) {
    let BackButton = (
      <Button
        className={styles.backButton}
        variant="contained"
        onClick={() => handleSubmit("back", submitForm)}
        disabled={!!loadingMessage}
      >
        Back
      </Button>
    );

    let ContinueButton = (
      <Button
        className={styles.continueButton}
        variant="contained"
        onClick={() => handleSubmit("forward", submitForm)}
        disabled={!!loadingMessage}
      >
        Continue
      </Button>
    );

    let BottomButtons;
    if (isFirstPage) {
      BottomButtons = (
        <>
          <Button
            className={styles.backButton}
            variant="contained"
            onClick={() => {
              setFormData({ meta: {} });
              setPages([]);
            }}
            disabled={!!loadingMessage}
          >
            Back
          </Button>
          {ContinueButton}
        </>
      );
    } else if (page === "Review:1" || page === "Review") {
      BottomButtons = (
        <>
          {BackButton}
          {loading ? (
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Submitting
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              className={styles.continueButton}
              onClick={() => handleSubmit("submit", submitForm)}
              disabled={!!loadingMessage}
            >
              Submit
            </Button>
          )}
        </>
      );
    } else if (page === "Finish") {
      BottomButtons = (
        <Button
          variant="contained"
          onClick={() => handleSubmit("refresh", submitForm)}
        >
          Report another incident
        </Button>
      );
    } else
      BottomButtons = (
        <>
          {BackButton}
          {ContinueButton}
        </>
      );

    return BottomButtons;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
      onSubmit={(values) => {
        controlResetTimer();
        setFormData((formData) => ({
          ...formData,
          ...values,
        }));

        if (submitDirectionRef.current === "back") {
          previousPage();
        } else if (submitDirectionRef.current === "forward") {
          nextPage();
        } else if (submitDirectionRef.current === "submit") {
          if (
            window.confirm(
              "I have carefully reviewed the provided information. Providing as many accurate details as possible helps expediate approval process"
            )
          ) {
            setLoading(true);

            if (formData.meta.emailOnly) {
              submitFunction().then((r) => console.log("THIS", r));
              setLoading(false);
              nextPage();
            } else {
              submitCrimeForm(formData).then((status) => {
                if (status) {
                  console.log("Report Submission Network Status:", status);
                  setLoading(false);
                  nextPage();
                }
              });
            }
          }
        } else if (submitDirectionRef.current === "refresh") {
          window.location.reload(false);
        }

        submitDirectionRef.current = null;
        submitCallback && submitCallback(values);
      }}
    >
      {({ submitForm, errors, values }) => (
        <div className={styles.root}>
          {!!Object.values(errors).length && handleHasError(errors, values)}
          <StepperCard isMobile={true} />
          <div className={styles.form}>{children({ values })}</div>
          {!!Object.values(errors).length && handleHasError(errors, values)}
          <div className={styles.buttonGroup}>{chooseButtons(submitForm)}</div>
          <CopyRight />
        </div>
      )}
    </Formik>
  );
}
