import React, { useContext, useEffect } from "react";
import { TimerContext } from "../../contexts/Timeout";
import styles from "./TimeoutTracker.module.css";

const getCurrentTime = () => {
  let date = new Date();
  let hh = date.getHours();
  let mm = date.getMinutes();
  let amPm = "AM";
  if (hh > 12) {
    hh = hh - 12;
    amPm = "PM";
  }
  if (hh === 12) {
    amPm = "PM";
  }
  if (hh < 10) {
    hh = "0" + hh;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  // This formats your string to HH:MM:SS
  var time = hh + ":" + mm + amPm;
  return time;
};

export default function TimeoutTracker() {
  const { timeoutTimer, setTimeoutTimer } = useContext(TimerContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeoutTimer((timeoutTimer) => {
        // TODO Take care of timer in the future
        /* if (timeoutTimer <= 0) {
          const message = "Your session expired.";
          alert(message);
          window.location.reload(); // ending session and starting another
          clearInterval(interval);
        }
        if (timeoutTimer === 300) {
          const message =
            "Your session will expire in 5 minutes from " +
            getCurrentTime() +
            ". Please submit the information and continue to the next page.";
          alert(message);
        } */
        if (timeoutTimer <= 0) {
          const message = "Are you still here?";
          alert(message);
          timeoutTimer = 1800;
          // TODO: Replace JS alert with "are you still here" MUI component
        }
        return timeoutTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [setTimeoutTimer]);

  const renderTime = () => {
    const minutes = Math.floor(timeoutTimer / 60);
    const seconds = timeoutTimer % 60;

    return (
      (minutes < 10 ? "0" : "") +
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      seconds
    );
  };

  return <div style={{display: "none"}} className={styles.root + " shadow"}>{renderTime()}</div>;
}
