import React from "react";
import TextInputField from "../../form-fields/TextInputField";
import ContactPhoneNumber from "../yourself-components/ContactPhoneNumber";
import DOB from "../yourself-components/DOB";
import DriversLicense from "../yourself-components/DriversLicense";
import EmailAndConfirmEmail from "../yourself-components/EmailAndConfirmEmail";
import FirstAndLastName from "../yourself-components/FirstAndLastName";
import Gender from "../yourself-components/Gender";
import Address from "../yourself-components/Address";
import YourselfBase from "../YourselfBase";
import styles from "./IndividualYourself.module.css";

export default function BusinessYourself({ formKey = "reporter" }) {
  return (
    <YourselfBase>
      <div className={styles.section}>
        <TextInputField
          name={`${formKey}.employerName`}
          label={
            <>
              <span style={{ color: "red" }}>* </span> Employer Name
            </>
          }
          sx={{ width: 0.5 }}
        />
        <ContactPhoneNumber formKey={formKey} business={true} required />
      </div>
      <Address type="work" formKey={formKey} />
      <FirstAndLastName formKey={formKey} />
      <Address formKey={formKey} />
      <div className={styles.section}>
        <Gender formKey={formKey} />
        <ContactPhoneNumber formKey={formKey} required />
      </div>
      <EmailAndConfirmEmail formKey={formKey} required />

      <DOB />
      <DriversLicense />
    </YourselfBase>
  );
}
