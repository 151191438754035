import { TextField } from "@mui/material";
import { useField } from "formik";
import { useEffect } from "react";

export default function TextInputField({
  label,
  helperText,
  inputProps = {},
  variant = "outlined",
  size = "small",
  format,
  multiline,
  fullWidth,
  sx,
  rows,
  ...props
}) {
  const [field, meta, helpers] = useField(props);

  useEffect(() => {
    format && helpers.setValue(format(field.value));
  }, [field.value]);

  return (
    <TextField
      sx={sx}
      {...field}
      label={label}
      helperText={helperText}
      variant={variant}
      size={size}
      inputProps={inputProps}
      multiline={multiline}
      fullWidth={fullWidth}
      error={!!meta.error}
      rows={rows}
      onChange={(e) => {
        field.onChange(e);
        props.onChange && props.onChange(e);
      }}
    />
  );
}
