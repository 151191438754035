import React from "react";
import TextInputField from "../../form-fields/TextInputField";
import styles from "./EmailAndConfirmEmail.module.css";

export default function EmailAndConfirmEmail({
  formKey = "reporter",
  required = false,
}) {
  return (
    <div className={styles.root}>
      <TextInputField
        name={`${formKey}.email`}
        label={<>{required && <span style={{ color: "red" }}>* </span>}Email</>}
        sx={{ width: 0.5 }}
      />
      <TextInputField
        name={`${formKey}.confirmEmail`}
        label={
          <>
            {required && <span style={{ color: "red" }}>* </span>} Confirm Email
          </>
        }
        sx={{ width: 0.5 }}
      />
    </div>
  );
}
