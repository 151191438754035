import React, { createContext, useState } from "react";

export const TimerContext = createContext();
export const TimerResetContext = createContext();

export default function TimeoutProvider({ children }) {
  const [timeoutTimer, setTimeoutTimer] = useState(1800);
  const [controlResetTimer, _] = useState(() => () => setTimeoutTimer(1800));
  // const controlResetTimer = () => () => setTimeoutTimer(1800) <--- doesn't work and trigers rerender every second!

  return (
    <TimerResetContext.Provider value={controlResetTimer}>
      <TimerContext.Provider value={{ timeoutTimer, setTimeoutTimer }}>
        {children}
      </TimerContext.Provider>
    </TimerResetContext.Provider>
  );
}
