import React, { useContext, useEffect } from "react";
import { PageContext } from "../../../../../contexts/Page";
import { FormDataContext } from "../../../../../contexts/FormData";
import FormBase from "../../../../common/FormBase";
import Start from "./Start";
import Yourself from "./Yourself";
import Incident from "./Incident";
import Vehicle from "./Vehicle";
import Narrative from "./Narrative";
import Review from "./Review";
import * as validation from "./validation";
import FinishBase from "../../../../common/FinishBase";

export default function Index() {
  const { page, pages, setPages } = useContext(PageContext);
  const { formData, setFormData } = useContext(FormDataContext);
  const enableStart2 = true;

  useEffect(() => {
    if (enableStart2 === true) {
      setPages([
        "Start:0",
        "Start:1",
        // "Start:2",
        "Yourself",
        "Incident",
        "Vehicle",
        "Narrative",
        "Review",
        "Finish",
      ]);
    } else {
      setPages([
        "Start:0",
        // "Start:1",
        // "Yourself",
        "Incident",
        "Vehicle",
        "Narrative",
        "Review",
        "Finish",
      ]);
    }

    setFormData((formData) => ({
      meta: {
        ...formData.meta,
        reportType: "Original",
        reporterType: "Individual",
        originalReportNumber: "",
      },
      start2: {
        willEnterPersonInfo: "yes",
      },
      reporter: {
        firstName: "",
        lastName: "",
        homeAddress: {
          country: "Canada",
          streetNumber: "",
          streetName: "",
          streetType: "",
          postDirection: "",
          apartment: "",
          city: "",
          province: "Ontario",
          zipCode: "",
        },
        contactPhoneNumber: "",
        email: "",
        confirmEmail: "",
        gender: "",
        employerName: "",
        workPhone: "",
        workAddress: {
          country: "Canada",
          streetNumber: "",
          streetName: "",
          streetType: "",
          postDirection: "",
          apartment: "",
          city: "",
          province: "Ontario",
          zipCode: "",
        },
        dateOfBirth: null,
        driversLicense: {
          number: "",
          provider: "",
        },
      },
      info: {
        trafficComplaint: {
          willEnterPersonInfo: "yes",
          streetAddress: {
            country: "Canada",
            streetNumber: "",
            streetName: "",
            streetType: "",
            postDirection: "",
            apartment: "",
            city: "",
            province: "Ontario",
            zipCode: "",
          },
          startTime: null,
          endTime: null,
          locationType: "",
          vehicle: {
            involvementType: "",
            maker: "",
            model: "",
            style: "",
            year: "",
            colours: "",
            licenseType: "",
            licensePlateNo: "",
            licenseLocation: "",
            vehicleDescription: "",
          },
          narrative: "",
        },
      },
      incident: {
        photographs: [],
      },
    }));
  }, []);

  return (
    <>
      {page === "Start:0" && (
        <FormBase
          validationSchema={validation.metaStart0}
          initialValues={{ meta: formData.meta }}
        >
          {() => <Start step="0" />}
        </FormBase>
      )}
      {page === "Start:1" && (
        <FormBase
          validationSchema={validation.metaStart1}
          initialValues={{ meta: formData.meta }}
        >
          {() => <Start step="1" />}
        </FormBase>
      )}
      {/* {page === "Start:2" && (
        <FormBase
          validationSchema={validation.metaStart2}
          initialValues={{ info: formData.info }}
          submitCallback={(values) => {
            const willEnterPersonInfo =
              values.info.trafficComplaint.willEnterPersonInfo;

            if (willEnterPersonInfo === "yes" && !pages.includes("Yourself")) {
              setPages((pages) => [
                ...pages.slice(0, 3),
                "Yourself",
                ...pages.slice(3),
              ]);
              return;
            }

            if (willEnterPersonInfo === "no" && pages.includes("Yourself")) {
              setPages((pages) => pages.filter((page) => page !== "Yourself"));
            }
          }}
        >
          {() => <Start step="2" />}
        </FormBase>
      )} */}
      {page === "Yourself" && (
        <>
          {formData.meta?.reporterType === "Individual" && (
            <FormBase
              validationSchema={validation.individualYourself}
              initialValues={{ reporter: formData.reporter }}
            >
              {() => <Yourself />}
            </FormBase>
          )}
          {formData.meta?.reporterType === "Business" && (
            <FormBase
              validationSchema={validation.businessYourself}
              initialValues={{ reporter: formData.reporter }}
            >
              {() => <Yourself />}
            </FormBase>
          )}
        </>
      )}
      {page === "Incident" && (
        <FormBase
          validationSchema={validation.incident}
          initialValues={formData}
        >
          {() => <Incident />}
        </FormBase>
      )}
      {page === "Vehicle" && (
        <FormBase
          validationSchema={validation.vehicle}
          initialValues={{ info: formData.info }}
        >
          {() => <Vehicle />}
        </FormBase>
      )}
      {page === "Narrative" && (
        <FormBase
          validationSchema={validation.narrative}
          initialValues={{
            info: formData.info,
          }}
        >
          {() => <Narrative />}
        </FormBase>
      )}
      {page === "Review" && (
        <FormBase initialValues={{}}>
          {() => <Review formValues={formData} />}
        </FormBase>
      )}
      {page === "Finish" && (
        <FormBase initialValues={{}}>{() => <FinishBase />}</FormBase>
      )}
    </>
  );
}
