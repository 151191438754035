import React from "react";
import InfoBox from "./InfoBox";
import MultiLineTextField from "../form-fields/MultiLineTextField";
export default function NarrativeBase({ formKey = "narrative" }) {
  return (
    <div className="page-root">
      <InfoBox
        title="Enter Narrative"
        text={
          <>
            Please enter a detailed description of the incident. <br /> <br />
          </>
        }
      />
      <MultiLineTextField formKey={formKey} characterCount={5000} />
    </div>
  );
}
