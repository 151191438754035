import React from "react";
import { IncidentBase } from "../../../../common/IncidentBase";

export default function Incident() {
  return (
    <IncidentBase
      formKey="info.bullying"
      color=""
      text={
        <>
          Please enter all the information that applies. If you do not know the
          address of the incident, use the nearest intersection such as Market
          St./ 10th St.
        </>
      }
    ></IncidentBase>
  );
}
