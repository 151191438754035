import React from "react";
import VehicleBase, {
  colors,
  licenseTypes,
} from "../../../../common/VehicleBase";
import SelectField from "../../../../form-fields/SelectField";
import TextInputField from "../../../../form-fields/TextInputField";
import styles from "./Vehicle.module.css";

export default function Vehicle({ formKey = "info.theftOfGas.vehicle" }) {
  return (
    <VehicleBase required={false} formKey={formKey}>
      <div className={styles.block}>
        <TextInputField
          name={formKey + ".vIN"}
          label={"VIN"}
          sx={{ width: 0.5 }}
        />
      </div>
      <div className={styles.block}>
        <SelectField
          name={formKey + ".colours"}
          options={colors.map((color) => ({
            value: color,
            label: color,
          }))}
          label={<>Colour</>}
          sx={{ width: 0.5 }}
        />
      </div>
      <div className={styles.block}>
        <SelectField
          name={formKey + ".licenseType"}
          options={licenseTypes.map((type) => ({
            value: type,
            label: type,
          }))}
          label="License Plate Type"
          sx={{ width: 0.5 }}
        />
      </div>
    </VehicleBase>
  );
}
