import React from "react";
import SelectDateTime from "../form-fields/TimePickerField"

export default function DateInput({
  formKey,
  required = true,
  hasTime = true,
  label,
}) {
  return (<>
    <p>{required && <span style={{ color: "red" }}>* </span>} {label}</p>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        maxWidth: "100%",
        gap: "0.5rem",
        marginTop: "0.5rem",
      }}
    >
      <SelectDateTime
        name={`${formKey}`}
        sx={{ width: 1 }}
        hasTime={hasTime}
      />
    </div>
  </>);
}
