import React from "react";
import { useContext, useMemo } from "react";
import { PageContext } from "../../contexts/Page";
import stepStyle from "./Stepper.module.css"
import style from "./StepperCard.module.css";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { green} from "@mui/material/colors";
/**
 * 
 * This is the stepper card seen at the side of the screen when the user is navigating through out the screen , it doesnt show if user is on the main page 
 * 
 */
export default function StepperCard({ isMobile }) {
    const { pages: rawPages, page: rawCurrentPage } = useContext(PageContext);
    const pages = useMemo(
        () =>
            rawPages
                .filter((page) => !page.includes(":") || page.split(":")[1] === "0")
                .map((page) => page.split(":")[0]),
        [rawPages]
    );
    const currentPage = useMemo(
        () => rawCurrentPage?.split(":")[0],
        [rawCurrentPage]
    );
    return (
        pages.length === 0 ? null : <div className={isMobile ? stepStyle.mobile : style.card}>
            <Stepper activeStep={pages.indexOf(currentPage)} alternativeLabel={isMobile ? true : false} orientation={isMobile ? "horizontal" : "vertical"} className={isMobile ? null : stepStyle.root} >
                {pages.map((label) => (
                    <Step key={label} sx={{
                        "&.MuiStep-root": {
                            padding: 0,

                        },

                    }}>
                        <StepLabel
                            sx={{
                                ".MuiSvgIcon-root.Mui-active": {

                                    color: "#222438",

                                },
                                ".MuiSvgIcon-root.Mui-completed": {
                                    color: "#222438",
                                },
                                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                {
                                    marginTop: 1,
                                },
                                '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                                    marginTop: 1,
                                },
                                "&.MuiStepLabel-alternativeLabel": {
                                    margin: 0,
                                },
                                "&.MuiStepIcon-root.Mui-active": {
                                    color: green,
                                },
                                ".MuiStepLabel-label": {
                                    // backgroundColor:"green",
                                    fontSize: ".8rem",
                                }




                            }}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div >

    );
}


