import React, { useContext, useEffect } from "react";
import { PageContext } from "../../../../../contexts/Page";
import { FormDataContext } from "../../../../../contexts/FormData";
import FormBase from "../../../../common/FormBase";
import Yourself from "./Yourself";
import Incident from "./Incident";
import Property from "./Property";
import Narrative from "./Narrative";
import Review from "./Review";
import * as validation from "./validation";
import FinishBase from "../../../../common/FinishBase";

export default function Index() {
  const { page, setPages } = useContext(PageContext);
  const { formData, setFormData } = useContext(FormDataContext);

  useEffect(() => {
    setPages([
      "Yourself",
      "Incident",
      "Property",
      "Narrative",
      "Review",
      "Finish",
    ]);
    setFormData((formData) => ({
      meta: {
        ...formData.meta,
        reportType: "Original",
        reporterType: "Individual",
        originalReportNumber: "",
      },
      reporter: {
        firstName: "",
        lastName: "",
        homeAddress: {
          country: "Canada",
          streetNumber: "",
          streetName: "",
          streetType: "",
          postDirection: "",
          apartment: "",
          city: "",
          province: "Ontario",
          zipCode: "",
        },
        contactPhoneNumber: "",
        email: "",
        confirmEmail: "",
        gender: "",
        dateOfBirth: null,
      },
      incident: {
        photographs: [],
      },
      info: {
        bicycleRegistration: {
          bicycles: [
            {
              brand: "",
              model: "",
              bicycleType: "",
              style: "",
              handleType: "",
              seatStyle: "",
              speed: "",
              wheelSize: "",
              colour: "",
              serialNumber: "",
              totalValue: "",
              additionalDescription: "",
            },
          ],
          narrative: "",
        },
      },
    }));
  }, []);

  return (
    <>
      {page === "Yourself" && (
        <FormBase
          validationSchema={validation.reporter}
          initialValues={{ reporter: formData.reporter }}
        >
          {() => <Yourself />}
        </FormBase>
      )}
      {page === "Incident" && (
        <FormBase initialValues={{ incident: formData.incident }}>
          {() => <Incident />}
        </FormBase>
      )}
      {page === "Property" && (
        <FormBase
          validationSchema={validation.bicyclesReportInfo}
          initialValues={{
            info: formData.info,
          }}
        >
          {() => <Property />}
        </FormBase>
      )}
      {page === "Narrative" && (
        <FormBase
          validationSchema={validation.bicyclesReportInfo}
          initialValues={{
            info: formData.info,
          }}
        >
          {() => <Narrative />}
        </FormBase>
      )}
      {page === "Review" && (
        <FormBase initialValues={{}}>
          {() => <Review formValues={formData} />}
        </FormBase>
      )}
      {page === "Finish" && (
        <FormBase initialValues={{}}>{() => <FinishBase />}</FormBase>
      )}
    </>
  );
}
