import React from "react";
import SelectField from "../../form-fields/SelectField";

export default function Gender({ formKey = "reporter", required = true }) {
  return (
    <SelectField
      name={`${formKey}.gender`}
      label={<>{required && <span style={{ color: "red" }}>* </span>} Gender</>}
      options={gender_options}
      sx={{ width: 1, maxWidth: 0.5 }}
    />
  );
}

const genderList = [
  "Female",
  "Male",
  "Transgender/Non Binary",
  "Other",
  "Prefer not to answer",
];

const gender_options = genderList.map((type) => ({
  value: type,
  label: type,
}));

export { genderList, gender_options };
