import React from "react";
import ConfirmQuestions from "./ConfirmQuestions";
import PersonTypeSelection from "./PersonTypeSelection";
import ReportTypeSelection from "./ReportTypeSelection";
import IndividualYourself from "./IndividualYourself";
import BusinessYourself from "./BusinessYourself";

export default function FormTemplate({ templateName, ...props }) {
  switch (templateName) {
    case "report-type-selection":
      return <ReportTypeSelection {...props} />;
    case "confirm-questions":
      return <ConfirmQuestions {...props} />;
    case "person-type-selection":
      return <PersonTypeSelection {...props} />;
    case "individual-yourself":
      return <IndividualYourself {...props} />;
    case "business-yourself":
      return <BusinessYourself {...props} />;
    default:
      return null;
  }
}
