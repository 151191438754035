import React, { Fragment } from "react";
import styles from "./ReviewBase.module.css";
import InfoBox from "./InfoBox";
import DisplayKeyValue from "../form-fields/DisplayKeyValue";
import { date } from "yup";

export default function ReviewBase({ formData }) {
  console.log("FORM DATA IS:", formData);

  const reportType = Object.keys(formData.info)[0];
  const incidentDisplayReports = [
    "bullying",
    "damageToProperty",
    "damageToVehicle",
    "lostProperty",
    "theftFromVehicle",
    "theftOfGas",
    "theftUnder5k",
    "trafficComplaint",
  ];
  console.log(reportType);

  // Bicycle total value registration
  if (formData.info.hasOwnProperty("bicycleRegistration")) {
    var sumValue = 0;
    for (const bicycle of formData.info.bicycleRegistration.bicycles) {
      sumValue += Number(bicycle.totalValue);
    }
  }

  // Damage/stolen total value registration
  //TODO now
  if (
    reportType === "damageToProperty" ||
    reportType === "lostProperty" ||
    reportType === "theftFromVehicle" ||
    reportType === "theftUnder5k"
  ) {
    var sumValueItems = 0;

    if (formData.info[reportType].property[0].hasOwnProperty("damagedValue")) {
      for (const item of formData.info[reportType].property) {
        sumValueItems += Number(item.damagedValue);
      }
    }

    if (formData.info[reportType].property[0].hasOwnProperty("totalValue")) {
      for (const item of formData.info[reportType].property) {
        sumValueItems += Number(item.totalValue);
      }
    }
  }

  return (
    <div className="page-root">
      <InfoBox
        title="Review Report"
        text={
          <>
            Please review the report. If all the information is correct, click
            the Submit button to submit the report. If you need to modify some
            information, click the desired modify link. This will be your last
            chance to change infomration for this report.{" "}
          </>
        }
      />
      <div className={styles.fields + " shadow"}>
        {/* GENERAL INFORMATION ###################### */}
        <div className={styles.block}>
          <h3>General Information</h3>
        </div>
        <DisplayKeyValue
          keyName={"Incident Type"}
          value={formData.meta.incidentType}
        />

        <DisplayKeyValue
          keyName={"Original or Supplemental"}
          value={formData.meta.reportType}
        />
        {formData.meta.reportType === "Supplemental" && (
          <DisplayKeyValue
            keyName={"Original Report Number"}
            value={formData.meta.originalReportNumber}
          />
        )}

        <DisplayKeyValue
          keyName={"Individual or Business"}
          value={formData.meta.reporterType}
        />

        {/* REPORTING PERSON INFORMATION ###################### */}
        <div className={styles.block + " " + styles.bwSectionPadding}>
          <h3>Reporting Person Information</h3>
        </div>

        {/* Bullying */}
        {formData.info.hasOwnProperty("bullying") && (
          <>
            {" "}
            <DisplayKeyValue
              keyName={"Wishes to remain anonymous"}
              value={formData.info.bullying.anonymous}
            />
            <DisplayKeyValue
              keyName={"Wishes to report to school"}
              value={formData.info.bullying.willReportToSchool}
            />
          </>
        )}

        {formData.meta.reporterType === "Business" && (
          <>
            <DisplayKeyValue
              keyName={"Employer Name"}
              value={formData.reporter.employerName}
            />
            <DisplayKeyValue
              keyName={"Work Address"}
              value={addressFormatter(formData.reporter.workAddress)}
            />
            <DisplayKeyValue
              keyName={"Work Phone"}
              value={formData.reporter.workPhone}
            />
          </>
        )}

        <DisplayKeyValue
          keyName={"First Name"}
          value={formData.reporter.firstName}
        />
        <DisplayKeyValue
          keyName={"Last Name"}
          value={formData.reporter.lastName}
        />
        <DisplayKeyValue
          keyName={"Home Address"}
          value={addressFormatter(formData.reporter.homeAddress)}
        />
        <DisplayKeyValue
          keyName={"Contact Phone Number"}
          value={formData.reporter.contactPhoneNumber}
        />
        <DisplayKeyValue keyName={"Email"} value={formData.reporter.email} />

        {formData.meta.reporterType === "Individual" && (
          <>
            {formData.reporter.hasOwnProperty("employerName") && (
              <DisplayKeyValue
                keyName={"Employer Name"}
                value={formData.reporter.employerName}
              />
            )}
            {formData.reporter.hasOwnProperty("workAddress") && (
              <DisplayKeyValue
                keyName={"Work Address"}
                value={addressFormatter(formData.reporter.workAddress)}
              />
            )}
            {formData.reporter.hasOwnProperty("workPhone") && (
              <DisplayKeyValue
                keyName={"Work Phone"}
                value={formData.reporter.workPhone}
              />
            )}
          </>
        )}

        <DisplayKeyValue keyName={"Gender"} value={formData.reporter.gender} />
        <DisplayKeyValue
          keyName={"DOB"}
          value={dateFormatter(formData.reporter.dateOfBirth)}
        />

        {formData.reporter.hasOwnProperty("driversLicense") && (
          <>
            <DisplayKeyValue
              keyName={"Driver License No"}
              value={formData.reporter.driversLicense.number}
            />
            <DisplayKeyValue
              keyName={"Licensing Prov/State"}
              value={formData.reporter.driversLicense.provider}
            />
          </>
        )}

        {/* Bullying Start*/}
        {formData.info.hasOwnProperty("bullying") && (
          <>
            {/* <DisplayKeyValue
              keyName={"Age"}
              value={formData.info.bullying.age}
            /> */}
            <DisplayKeyValue
              keyName={"What School?"}
              value={formData.info.bullying.school}
            />
            <DisplayKeyValue
              keyName={"What Grade?"}
              value={formData.info.bullying.grade}
            />
          </>
        )}
        {/* Bullying End*/}

        {/* INCIDENT INFORMATION ###################### */}
        <div className={styles.block + " " + styles.bwSectionPadding}>
          <h3>Incident Information</h3>
        </div>

        {/* BULLYING */}
        {incidentDisplayReports.includes(reportType) && (
          <>
            <DisplayKeyValue
              keyName={"Incident Location"}
              value={addressFormatter(formData.info[reportType].streetAddress)}
            />
            <DisplayKeyValue
              keyName={"Approximate Start Date/Time of Incident"}
              value={dateTimeFormatter(formData.info[reportType].startTime)}
            />
            <DisplayKeyValue
              keyName={"Approximate End Date/Time of Incident"}
              value={dateTimeFormatter(formData.info[reportType].endTime)}
            />
          </>
        )}

        {(reportType === "damageToProperty" ||
          reportType === "damageToVehicle") && (
          <DisplayKeyValue
            keyName={"Graffiti Involved"}
            value={formData.info[reportType].graffitiInvolved}
          />
        )}

        {(reportType === "damageToProperty" ||
          reportType === "damageToVehicle" ||
          reportType === "lostProperty" ||
          reportType === "theftFromVehicle" ||
          reportType === "theftOfGas" ||
          reportType === "theftUnder5k" ||
          reportType === "trafficComplaint") && (
          <DisplayKeyValue
            keyName={"Location Type"}
            value={formData.info[reportType].locationType}
          />
        )}

        {reportType === "theftUnder5k" && (
          <DisplayKeyValue
            keyName={"Type of Theft"}
            value={formData.info[reportType].theftType}
          />
        )}

        {formData.incident.photographs.length > 0 &&
          <DisplayKeyValue
            keyName={"Attachments"}
            value={formData.incident.photographs.map(
              (attachment) => {
                if (
                  attachment.type === "image/png" ||
                  attachment.type === "image/jpeg" ||
                  attachment.type === "image/jpg" ||
                  attachment.type === "image/gif"
                ) {
                  return <img
                    src={attachment.url}
                    height={50}
                    key={attachment.id}
                  />
                } else {
                  return <Fragment key={attachment.id}>{attachment.filename + ", "}</Fragment>
                }
              }
            )}
          />
        }

        {/* TODO Break up proprty/bicycle/vehicle to incident specific sections */}
        {/* PROPERTY/PERSON INFORMATION ###################### */}
        {(reportType === "bullying" || reportType === "theftOfGas") && (
          <>
            <div className={styles.block + " " + styles.bwSectionPadding}>
              <h3>Person Information</h3>
            </div>
            {formData.info[reportType].involvedPersons.map((person, index) => {
              return (
                <div
                  className={styles.bwSectionPadding}
                  key={`Person ${index + 1}`}
                >
                  <DisplayKeyValue keyName={`Person ${index + 1}`} value={""} />
                  <DisplayKeyValue
                    keyName={"Involvement"}
                    value={person.involvementType}
                  />
                  <DisplayKeyValue
                    keyName={"First Name"}
                    value={person.firstName}
                  />
                  <DisplayKeyValue
                    keyName={"Last Name"}
                    value={person.lastName}
                  />
                  <DisplayKeyValue
                    keyName={"Home Address"}
                    value={addressFormatter(person.homeAddress)}
                  />
                  <DisplayKeyValue
                    keyName={"Contact Phone Number"}
                    value={person.contactPhoneNumber}
                  />

                  {person.hasOwnProperty("email") && (
                    <DisplayKeyValue keyName={"Email"} value={person.email} />
                  )}

                  {person.hasOwnProperty("employerName") && (
                    <DisplayKeyValue
                      keyName={"Employer Name"}
                      value={person.employerName}
                    />
                  )}

                  {person.hasOwnProperty("workAddress") && (
                    <DisplayKeyValue
                      keyName={"Work Address"}
                      value={addressFormatter(person.workAddress)}
                    />
                  )}

                  {person.hasOwnProperty("workPhone") && (
                    <DisplayKeyValue
                      keyName={"Work Phone"}
                      value={person.workPhone}
                    />
                  )}

                  <DisplayKeyValue keyName={"Race"} value={person.race} />

                  {person.hasOwnProperty("residentStatus") && (
                    <DisplayKeyValue
                      keyName={"Resident Status"}
                      value={person.residentStatus}
                    />
                  )}

                  <DisplayKeyValue keyName={"Gender"} value={person.gender} />
                  <DisplayKeyValue
                    keyName={"DOB"}
                    value={dateFormatter(person.dateOfBirth)}
                  />

                  {person.hasOwnProperty("school") && (
                    <DisplayKeyValue
                      keyName={"What School?"}
                      value={person.school}
                    />
                  )}

                  {person.hasOwnProperty("grade") && (
                    <DisplayKeyValue
                      keyName={"What Grade?"}
                      value={person.grade}
                    />
                  )}

                  {person.hasOwnProperty("sSN") && (
                    <DisplayKeyValue keyName={"SIN/SSN"} value={person.sSN} />
                  )}

                  {person.hasOwnProperty("driversLicense") && (
                    <>
                      <DisplayKeyValue
                        keyName={"Driver License No"}
                        value={person.driversLicense.number}
                      />
                      <DisplayKeyValue
                        keyName={"Licensing Prov/State"}
                        value={person.driversLicense.provider}
                      />
                    </>
                  )}

                  {person.hasOwnProperty("age") && (
                    <DisplayKeyValue keyName={"Age"} value={person.age} />
                  )}

                  <DisplayKeyValue
                    keyName={"Height"}
                    value={imperialHeightFormatter(person.height)}
                  />

                  {person.hasOwnProperty("weight") && (
                    <DisplayKeyValue
                      keyName={"Weight (lbs)"}
                      value={person.weight}
                    />
                  )}
                  {person.hasOwnProperty("personSize") && (
                    <DisplayKeyValue
                      keyName={"How big is this person?"}
                      value={person.personSize}
                    />
                  )}

                  <DisplayKeyValue
                    keyName={"Eye Color"}
                    value={person.eyeColor}
                  />
                  <DisplayKeyValue
                    keyName={"Hair Color"}
                    value={person.hairColor}
                  />

                  {person.hasOwnProperty("hairStyle") && (
                    <DisplayKeyValue
                      keyName={"Hair Style"}
                      value={person.hairStyle}
                    />
                  )}
                  {person.hasOwnProperty("facialHair") && (
                    <DisplayKeyValue
                      keyName={"Facial Hair"}
                      value={person.facialHair}
                    />
                  )}
                  {person.hasOwnProperty("clothingOrPhysicalDescription") && (
                    <DisplayKeyValue
                      keyName={"Clothing or Physical Description"}
                      value={person.clothingOrPhysicalDescription}
                    />
                  )}
                  {person.hasOwnProperty("tattoosOrMarks") && (
                    <DisplayKeyValue
                      keyName={"Tattos or marks on body or face"}
                      value={person.tattoosOrMarks}
                    />
                  )}
                </div>
              );
            })}
          </>
        )}

        {formData.info.hasOwnProperty("bicycleRegistration") && (
          <>
            {" "}
            <div className={styles.block + " " + styles.bwSectionPadding}>
              <h3>Property Information</h3>
            </div>
            <DisplayKeyValue
              keyName={"Total Value of all Properties ($)"}
              value={sumValue}
            />
            {formData.info.bicycleRegistration.bicycles.map(
              (bicycle, index) => {
                return (
                  <div
                    className={styles.bwSectionPadding}
                    key={`Bicycle ${index + 1}`}
                  >
                    <DisplayKeyValue
                      keyName={`Bicycle ${index + 1}`}
                      value={""}
                    />
                    <DisplayKeyValue keyName={"Type"} value={"Bicycle"} />
                    <DisplayKeyValue keyName={"Brand"} value={bicycle.brand} />
                    <DisplayKeyValue keyName={"Model"} value={bicycle.model} />
                    <DisplayKeyValue
                      keyName={"Bicycle Type"}
                      value={bicycle.bicycleType}
                    />
                    <DisplayKeyValue
                      keyName={"Bicycle Style"}
                      value={bicycle.style}
                    />
                    <DisplayKeyValue
                      keyName={"Bicycle Handle Type"}
                      value={bicycle.handleType}
                    />
                    <DisplayKeyValue
                      keyName={"Bicycle Seat Style"}
                      value={bicycle.seatStyle}
                    />
                    <DisplayKeyValue
                      keyName={"Bicycle Speed"}
                      value={bicycle.speed}
                    />
                    <DisplayKeyValue
                      keyName={"Bicycle Wheel Size"}
                      value={bicycle.wheelSize}
                    />
                    <DisplayKeyValue
                      keyName={"Colour"}
                      value={bicycle.colour}
                    />
                    <DisplayKeyValue
                      keyName={"Serial No"}
                      value={bicycle.serialNumber}
                    />
                    <DisplayKeyValue
                      keyName={"Total Value ($)"}
                      value={bicycle.totalValue}
                    />
                    <DisplayKeyValue
                      keyName={"Additional Description"}
                      value={bicycle.additionalDescription}
                    />
                  </div>
                );
              }
            )}
          </>
        )}
        {(reportType === "damageToVehicle" ||
          reportType === "theftFromVehicle" ||
          reportType === "theftOfGas" ||
          reportType === "trafficComplaint") && (
          <>
            <div className={styles.block + " " + styles.bwSectionPadding}>
              <h3>Vechicle Information</h3>
            </div>
            <DisplayKeyValue
              keyName={"Type"}
              value={formData.info[reportType].vehicle.involvementType}
            />

            <DisplayKeyValue
              keyName={"Make"}
              value={formData.info[reportType].vehicle.maker}
            />

            <DisplayKeyValue
              keyName={"Model"}
              value={formData.info[reportType].vehicle.model}
            />

            {formData.info[reportType].vehicle.hasOwnProperty("vIN") && (
              <DisplayKeyValue
                keyName={"VIN"}
                value={formData.info[reportType].vehicle.vIN}
              />
            )}

            {formData.info[reportType].vehicle.hasOwnProperty("style") && (
              <DisplayKeyValue
                keyName={"Style"}
                value={formData.info[reportType].vehicle.style}
              />
            )}

            {formData.info[reportType].vehicle.hasOwnProperty("year") && (
              <DisplayKeyValue
                keyName={"Year"}
                value={formData.info[reportType].vehicle.year}
              />
            )}

            {formData.info[reportType].vehicle.hasOwnProperty("colours") && (
              <DisplayKeyValue
                keyName={"Colour"}
                value={formData.info[reportType].vehicle.colours}
              />
            )}

            {formData.info[reportType].vehicle.hasOwnProperty(
              "marketValue"
            ) && (
              <DisplayKeyValue
                keyName={"Market Value ($)"}
                value={formData.info[reportType].vehicle.marketValue}
              />
            )}

            {formData.info[reportType].vehicle.hasOwnProperty(
              "damagedValue"
            ) && (
              <DisplayKeyValue
                keyName={"Damaged Value ($)"}
                value={formData.info[reportType].vehicle.damagedValue}
              />
            )}

            <DisplayKeyValue
              keyName={"License Plate Type"}
              value={formData.info[reportType].vehicle.licenseType}
            />

            <DisplayKeyValue
              keyName={"License Plate No"}
              value={formData.info[reportType].vehicle.licensePlateNo}
            />

            <DisplayKeyValue
              keyName={"Licensing Province"}
              value={formData.info[reportType].vehicle.licenseLocation}
            />

            {formData.info[reportType].vehicle.hasOwnProperty(
              "vehicleSituation"
            ) && (
              <DisplayKeyValue
                keyName={"Vehicle Locked and Windows Closed"}
                value={formData.info[reportType].vehicle.vehicleSituation}
              />
            )}
            {formData.info[reportType].vehicle.hasOwnProperty(
              "vehicleDescription"
            ) && (
              <DisplayKeyValue
                keyName={"Vehicle Description"}
                value={formData.info[reportType].vehicle.vehicleDescription}
              />
            )}
          </>
        )}
        {(reportType === "damageToProperty" ||
          reportType === "lostProperty" ||
          reportType === "theftFromVehicle" ||
          reportType === "theftUnder5k") && (
          <>
            {" "}
            <div className={styles.block + " " + styles.bwSectionPadding}>
              <h3>Property Information</h3>
            </div>
            <DisplayKeyValue
              keyName={"Total Value of all Properties ($)"}
              value={sumValueItems}
            />
            {formData.info[reportType].property.map((item, index) => {
              return (
                <div
                  className={styles.bwSectionPadding}
                  key={`Property${index + 1}`}
                >
                  <DisplayKeyValue
                    keyName={`Property/Item ${index + 1}`}
                    value={""}
                  />
                  <DisplayKeyValue keyName={"Type"} value={item.propertyType} />
                  <DisplayKeyValue keyName={"Subtype"} value={item.subType} />
                  <DisplayKeyValue keyName={"Brand"} value={item.brand} />
                  <DisplayKeyValue keyName={"Model"} value={item.model} />
                  <DisplayKeyValue keyName={"Colour"} value={item.colour} />
                  <DisplayKeyValue
                    keyName={"Serial No"}
                    value={item.serialNo}
                  />
                  <DisplayKeyValue keyName={"How Many"} value={item.quantity} />

                  {reportType === "damageToProperty" ? (
                    <DisplayKeyValue
                      keyName={"Damaged Value ($)"}
                      value={item.damagedValue}
                    />
                  ) : (
                    <DisplayKeyValue
                      keyName={"Total Value ($)"}
                      value={item.damagedValue}
                    />
                  )}

                  <DisplayKeyValue
                    keyName={"Property Description"}
                    value={item.description}
                  />
                </div>
              );
            })}
          </>
        )}

        <div className={styles.block + " " + styles.bwSectionPadding}>
          <h3>Narrative</h3>
        </div>
        <DisplayKeyValue
          keyName={"Additional Details"}
          value={formData.info[reportType].narrative}
        />
      </div>
    </div>
  );
}

function addressFormatter(addressDict) {
  var address = "";
  if (addressDict.apartment !== "") address += addressDict.apartment + "-";

  if (addressDict.streetNumber !== "")
    address += addressDict.streetNumber + " ";

  if (addressDict.streetName !== "") address += addressDict.streetName + " ";

  if (addressDict.streetType !== "") address += addressDict.streetType;

  if (addressDict.postDirection !== "")
    address += " " + addressDict.postDirection;
  if (address !== "") address += ", ";
  if (addressDict.city !== "") address += addressDict.city + ", ";

  if (addressDict.province !== "") address += addressDict.province + " ";

  if (addressDict.zipCode !== "") address += addressDict.zipCode + ", ";
  address += addressDict.country;
  return address;
}

function dateFormatter(date) {
  return date ? date.toString().slice(4, 10) + "," + date.toString().slice(10, 15) : "";
}

function dateTimeFormatter(dateTime) {
  return dateTime ? dateTime.toString() : "";
}

function imperialHeightFormatter(heightDict) {
  var height = "";
  if (heightDict.foot !== "") height += heightDict.foot + " feet ";
  if (heightDict.inch !== "") height += heightDict.inch + " in";
  return height;
}
