import React from "react";
import TextInputField from "../../form-fields/TextInputField";
import styles from "./FirstAndLastName.module.css";

export default function FirstAndLastName({
  formKey = "reporter",
  index = null,
  required = true,
}) {
  if (index !== null) {
    formKey = formKey + "." + index;
  }

  return (
    <div className={styles.root}>
      <TextInputField
        name={`${formKey}.firstName`}
        label={
          <>{required && <span style={{ color: "red" }}>* </span>} First Name</>
        }
        sx={{ width: 0.5 }}
      />

      <TextInputField
        name={`${formKey}.lastName`}
        label={
          <>{required && <span style={{ color: "red" }}>* </span>} Last Name</>
        }
        sx={{ width: 0.5 }}
      />
    </div>
  );
}
