import { Alert, Button, IconButton, Snackbar, Typography } from "@mui/material";
import { useCallback, useContext } from "react";
import { ConnectionContext } from "../../contexts/Connection";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import styles from "./ErrorChip.module.css"

/* const ReloadTimer = function() {
  const [timer, setTimer] = useState(99999);
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer <= 0) {
        window.location.reload();
        return;
      }
      return setTimer(timer - 1);
    }, 1000);
    return () => clearInterval(interval);
  });
  return (<>
    Will reload automatically in {timer}...
  </>)
} */

export default function ErrorChip() {
  const { error, setError } = useContext(ConnectionContext);

  useEffect(() => {
    if (error.message) {
      alert(error.message);
    }
  }, [error]);

  const handleClose = useCallback(() => {
    setError({
      message: "",
      callback: null,
      args: null
    })
  }, []);

  return (
    <Snackbar
      open={!!error.errorMsg}
      autoHideDuration={6000}
      action={(<>
        <IconButton 
          color="primary"
          onClick={handleClose}
        > 
          <CloseIcon />
        </IconButton>
      </>)}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error">
        {error.errorMsg + " If the error persists please contact your police service"}
      </Alert>
    </Snackbar>
  )
}

/* {error.errorMsg && <div className={styles.alertContainer}>
  <Alert severity="error" action={
    <Button color="inherit" size="small" onClick={() => {
      error.callingFunction(error.args);
      setError({
        errorMsg: "",
        callingFunction: null,
        args: null,
      });
    }}>
      TRY AGAIN
    </Button>
  }>
    <AlertTitle>{error.errorMsg + " Please try again."}</AlertTitle>
    <ReloadTimer/>
  </Alert>
</div>} */
