import React from "react";
import SelectField from "../form-fields/SelectField";
import TextInputField from "../form-fields/TextInputField";
import InfoBox from "./InfoBox";
import styles from "./VehicleBase.module.css";

export default function VehicleBase({
  children,
  required,
  decription = false,
  formKey = "info.damageToVehicle.vehicle",
}) {
  return (
    <div className="page-root">
      <InfoBox
        title="Enter Vehicle Information"
        text="Please enter the vehicle information."
      />
      <div className={styles.fields + " shadow"}>
        <div className={styles.block}>
          <SelectField
            name={formKey + ".involvementType"}
            options={types.map((type) => ({
              value: type,
              label: type,
            }))}
            label={
              <>
                <span style={{ color: "red" }}>*</span> Type
              </>
            }
            sx={{ width: 0.5 }}
          />
          <SelectField
            name={formKey + ".maker"}
            options={makers.map((maker) => ({
              value: maker,
              label: maker,
            }))}
            label={
              <>
                <>{required && <span style={{ color: "red" }}>* </span>} Make</>
              </>
            }
            sx={{ width: 0.5 }}
          />
        </div>

        <div className={styles.block}>
          <TextInputField
            name={formKey + ".model"}
            label={
              <>{required && <span style={{ color: "red" }}>* </span>} Model</>
            }
            sx={{ width: 0.5 }}
          />
        </div>

        {children}

        <div className={styles.block}>
          <TextInputField
            helperText="(do not enter dashes or spaces)"
            name={formKey + ".licensePlateNo"}
            label={
              <>
                {required && <span style={{ color: "red" }}>* </span>} License
                Plate No
              </>
            }
            sx={{ width: 0.5 }}
          />
          <SelectField
            name={formKey + ".licenseLocation"}
            options={location.map((type) => ({
              value: type,
              label: type,
            }))}
            label={
              <>
                {required && <span style={{ color: "red" }}>* </span>} Licensing
                Prov
              </>
            }
            sx={{ width: 0.5 }}
          />
        </div>

        {decription && (
          <div className={styles.block}>
            <TextInputField
              name={formKey + ".vehicleDescription"}
              label={
                <>
                  {<span style={{ color: "red" }}>* </span>} Vehicle Description
                </>
              }
              sx={{ width: 1 }}
              multiline
            />
          </div>
        )}
      </div>
    </div>
  );
}

export const types = [
  "All terrain vehicle",
  "Automobile",
  "Bus, coach, streetcar, train",
  "Construction equipment",
  "Farm equipment",
  "Motor home, RV",
  "Motorcycle",
  "Other motorized",
  "Snowmobile",
  "Trailer",
  "Truck, van",
];

const makers = [
  "Acura",
  "Alfa Romeo",
  "American Motors",
  "Aston Martin",
  "Audi",
  "Avant",
  "Bentley",
  "Bmw",
  "Buick",
  "Cadillac",
  "Chevrolet",
  "Chrysler",
  "Daewoo",
  "Daihatsu",
  "Datsun",
  "Delorean",
  "Dodge",
  "Eagle",
  "Ferrari",
  "Fiat",
  "Ford",
  "Geo",
  "Gmc",
  "Honda",
  "Hummer",
  "Hyundai",
  "Infiniti",
  "Isuzu",
  "Jaguar",
  "Jeep",
  "Kia",
  "Lamborghini",
  "Lancia",
  "Land Rover",
  "Lexus",
  "Lincoln",
  "Lotus",
  "Maserati",
  "Maybach",
  "Mazda",
  "Mercedes-benz",
  "Mercury",
  "Merkur",
  "Mini",
  "Mitsubishi",
  "Nissan",
  "Oldsmobile",
  "Other",
  "Peugot",
  "Plymouth",
  "Pontiac",
  "Porsche",
  "Renault",
  "Rolls Royce",
  "Saab",
  "Saturn",
  "Scion",
  "Sterling",
  "Subaru",
  "Suzuki",
  "Toyota",
  "Triumph",
  "Volkswagen",
  "Volvo",
  "Yugo",
];

export const licenseTypes = [
  "Antique",
  "ATV",
  "Bus",
  "Commercial",
  "Construction equipt",
  "Dealer",
  "Diplomatic",
  "Farm",
  "Government",
  "Military",
  "Motorcycle",
  "Passenger",
  "Snowmobile",
  "Trailer",
  "Transit permit",
  "Truck, van",
];

const location = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
  "Other",
];

export const vehicleStyle = [
  "2-Door Convertible",
  "2-Door Hatchback",
  "2-Door Sedan",
  "4-Door Convertible",
  "4-Door Hatchback",
  "4-Door Sedan",
  "Bus",
  "Limousine",
  "Minibike",
  "Motorcycle",
  "Other Automobile",
  "Other Truck",
  "SUV",
  "Truck - Camper",
  "Truck - Pickup",
  "Van",
  "Wagon",
];

// TODO make year query current year so it changes automatically
export const years = [];
for (let i = 1900; i <= new Date().getFullYear(); i++) {
  years.unshift(i.toString());
}

export const colors = [
  "Beige",
  "Black",
  "Blue",
  "Blue, dark",
  "Blue, light",
  "Bronze",
  "Brown",
  "Copper",
  "Cream, ivory",
  "Gold",
  "Green",
  "Green, dark",
  "Green, light",
  "Grey",
  "Maroon",
  "Orange",
  "Pink",
  "Purple, mauve",
  "Red",
  "Silver, aluminum, steel",
  "Tan",
  "Turquoise",
  "White",
  "Yellow",
  "Other",
];
