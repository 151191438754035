import React, { createContext, useState } from "react";

export const PageContext = createContext();

export default function PageProvider({ children }) {
  const [pageIndex, setPageIndex] = useState(0);
  const [pages, setPages] = useState([]);

  const previousPage = () => {
    if (pageIndex === 0) return;
    setPageIndex((pageIndex) => pageIndex - 1);
  };

  const nextPage = () => {
    console.log('nextpage');
    if (pageIndex > pages.length - 1) return;
    setPageIndex((pageIndex) => pageIndex + 1);
  };
  const clickPage = (newIndex) => {
    if (pageIndex > pages.length - 1) return;
    setPageIndex((pageIndex) => newIndex);
  };

  return (
    <PageContext.Provider
      value={{
        page: pages[pageIndex],
        pages,
        setPages,
        previousPage,
        nextPage,
        clickPage,
      }}
    >
      {children}
    </PageContext.Provider>
  );
}
