import React from "react";
import { locations, IncidentBase } from "../../../../common/IncidentBase";
import RadioField from "../../../../form-fields/RadioField";
import SelectField from "../../../../form-fields/SelectField";
import styles from "./Incident.module.css";

export default function Incident({ formKey = "info.theftOfGas" }) {
  return (
    <IncidentBase
      formKey={formKey}
      text={
        <>
          When responding to the question "Have Additional Person Info to
          Enter?" below, only click 'Yes' if you have at least the first name,
          last name and DOB of the suspect. If you cannot identify the person
          specifically, click 'No' and provide what descriptors you have in the
          Narrative text section toward the end of the report. <br />
          NOTE: At least one photograph must be uploaded to submit this report.
        </>
      }
    >
      <div
        style={{
          paddingBottom: "1rem",
          borderBottom: "1px solid var(--border-color)",
        }}
      >
        <p style={{ paddingLeft: "1rem" }}>
          <span style={{ color: "red" }}>* </span>Location Type
        </p>
        <div className={styles.section}>
          <SelectField
            options={locations.map((location) => ({
              value: location,
              label: location,
            }))}
            name={formKey + ".locationType"}
            label={"Please Select"}
          />
        </div>
      </div>
      <div
        style={{
          paddingBottom: "1rem",
          borderBottom: "1px solid var(--border-color)",
        }}
      >
        <p style={{ paddingLeft: "1rem" }}>
          Have Additional Person Info to Enter?{" "}
          <span style={{ color: "red" }}>* </span>
        </p>
        <div className={styles.section}>
          <RadioField
            type="radio"
            name={formKey + ".haveAdditionalInfo"}
            value="yes"
            label="yes"
          />
          <RadioField
            type="radio"
            name={formKey + ".haveAdditionalInfo"}
            value="no"
            label="no"
          />
        </div>
      </div>
    </IncidentBase>
  );
}
