import React from "react";
import ContactPhoneNumber from "../../../../common/yourself-components/ContactPhoneNumber";
import DOB from "../../../../common/yourself-components/DOB";
import EmailAndConfirmEmail from "../../../../common/yourself-components/EmailAndConfirmEmail";
import FirstAndLastName from "../../../../common/yourself-components/FirstAndLastName";
import Gender from "../../../../common/yourself-components/Gender";
import Address from "../../../../common/yourself-components/Address";
import YourselfBase from "../../../../common/YourselfBase";
import SelectField from "../../../../form-fields/SelectField";
import TextInputField from "../../../../form-fields/TextInputField";
import styles from "./Yourself.module.css";

export default function Yourself({ formKey = "info.bullying" }) {
  return (
    <YourselfBase
      infoBoxProps={{
        text: (
          <>
            Please enter your information as completely as possible. This
            information is mandatory but will not be shared with anyone. You may
            be contacted regarding this incident. An email address is required
            in order for an officer to communicate with you if more information
            is required and you would like to be notified when this report is
            received and approved.
          </>
        ),
        color: "red",
      }}
    >
      <div className={styles.screeningQuestion}>
        <span>Do you wish to remain anonymous? </span>
        <SelectField
          name={formKey + ".anonymous"}
          label={<>{<span style={{ color: "red" }}>* </span>} Please Select</>}
          options={screeningQuestionOptions}
          sx={{ maxWidth: 1 }}
        />
      </div>
      <div className={styles.screeningQuestion}>
        <span>
          Do you wish this bullying incident be reported to your school?
        </span>
        <SelectField
          name={formKey + ".willReportToSchool"}
          label={<>{<span style={{ color: "red" }}>* </span>} Please Select</>}
          options={screeningQuestionOptions}
          sx={{ maxWidth: 1 }}
        />
      </div>
      <FirstAndLastName />
      <Address formKey="reporter" />
      <div className={styles.section}>
        <Gender />
        <ContactPhoneNumber required />
      </div>
      <EmailAndConfirmEmail required />
      <DOB />
      <div className={styles.section}>
        <TextInputField
          name={formKey + ".school"}
          label="What school?"
          sx={{ width: 0.5 }}
        />
        <TextInputField
          name={formKey + ".grade"}
          label="What grade?"
          sx={{ width: 0.5 }}
        />
      </div>
    </YourselfBase>
  );
}

const screeningQuestionOptions = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
  {
    value: "I don't know",
    label: "I don't know",
  },
];
