import React from "react";
import styles from "./Verification.module.css";

export default function VerificationBase({ children }) {
    return (
        <div className="page-root">
            <div className={styles.fields + " shadow"}>{children}</div>
        </div>
    );
}
