import React, { useState, useContext, useEffect } from 'react'
import { CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe } from '@stripe/react-stripe-js';
import style from "./PaymentForm.module.css";
import SucessCard from './SucessCard';
import { PaymentContext } from '../../contexts/PaymentContext';
export function PaymentForm({ secret }) {
    //Payment successful 
    const [success, setSuccess] = useState(false);
    const { value, setValue } = useContext(PaymentContext);
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState(null);
    const clientSecret = secret;
    const options = {
        iconStyle: "solid",
        showIcon: true,
        style: {
            base: {
                iconColor: '#c4f0ff',
                fontSize: "16px",
                color: "#424770",
                letterSpacing: "0.025em",
                fontFamily: "Source Code Pro, monospace",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#9e2146"
            }
        }
    };
    useEffect(() => {
        if (!stripe) {
            return;
        }
        if (!clientSecret) {
            console.log("cant find client secret")
            return;
        }

    }, [stripe]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);
        //Confirm and complete the payment on the client side 
        const result = await stripe.confirmCardPayment(clientSecret, {
            //Grabs all the information from our elements 
            payment_method: {
                card: elements.getElement(CardNumberElement, CardCvcElement, CardExpiryElement),
            }
        });
        if (result.error) {
            // Show error to your customer (e.g., insufficient funds,payment failed)
            setIsLoading(false);
            setValue(false);
            console.log('error');
            setMessage(result.error.message);
            
        } else {
            // The payment has been processed!
            if ((result.paymentIntent.status === 'succeeded')) {
                setSuccess(true);
                setValue(true);
                console.log('Paid 5 dollars !');
            }
            else {
                console.log(error);
                setError("Try again webhook failed");
                setIsLoading(false);
                console.log("webhook failed");
            }
        }
        setIsLoading(false);
    }; //ends
    return (
        <>
            {!success || !value?
                <div>
                    <form onSubmit={handleSubmit} >
                        <label className={style.label}>
                            Card number
                            <CardNumberElement
                                options={options}
                                className={style.container}

                            />
                        </label>
                        <label className={style.label}>
                            Expiration date
                            <CardExpiryElement
                                options={options}
                                className={style.container}

                            />
                        </label>
                        <label className={style.label}>
                            CVC
                            <CardCvcElement
                                options={options}
                                className={style.container}

                            />
                        </label>
                        <button disabled={isLoading || !stripe || !elements} className={style.btn}>
                            <span id="button-text">
                                {isLoading ? <div className={style.spinner} id="spinner"></div> : "Pay now"}
                            </span>
                        </button>
                        {message && <div className={style.paymentMessage}>{message}</div>}
                    </form>

                </div>
                : <SucessCard></SucessCard>
            }

        </>
    )
}
