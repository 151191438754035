import React, { useContext, useEffect } from "react";
import { PageContext } from "../../../../../contexts/Page";
import { FormDataContext } from "../../../../../contexts/FormData";
import FormBase from "../../../../common/FormBase";
import Start from "./Start";
import Yourself from "./Yourself";
import Incident from "./Incident";
import Person from "./Person";
import Narrative from "./Narrative";
import Review from "./Review";
import * as validation from "./validation";
import FinishBase from "../../../../common/FinishBase";

export default function Index() {
  const { page, pages, setPages } = useContext(PageContext);
  const { formData, setFormData } = useContext(FormDataContext);

  useEffect(() => {
    setPages([
      "Start",
      "Yourself",
      "Incident",
      "Person",
      "Narrative",
      "Review",
      "Finish",
    ]);

    setFormData((formData) => ({
      meta: {
        ...formData.meta,
        reportType: "Original",
        originalReportNumber: "",
        reporterType: "Individual",
      },
      reporter: {
        firstName: "",
        lastName: "",
        homeAddress: {
          country: "Canada",
          streetNumber: "",
          streetName: "",
          streetType: "",
          postDirection: "",
          apartment: "",
          city: "",
          province: "Ontario",
          zipCode: "",
        },
        contactPhoneNumber: "",
        email: "",
        confirmEmail: "",
        gender: "",
        dateOfBirth: null,
      },
      info: {
        bullying: {
          anonymous: "",
          willReportToSchool: "",
          school: "",
          grade: "",
          streetAddress: {
            country: "Canada",
            streetNumber: "",
            streetName: "",
            streetType: "",
            postDirection: "",
            apartment: "",
            city: "",
            province: "Ontario",
            zipCode: "",
          },
          startTime: null,
          endTime: null,
          haveAdditionalInfo: "",
          involvedPersons: [],
          narrative: "",
        },
      },

      incident: {
        photographs: [],
      },
    }));
  }, [setFormData, setPages]);

  //TODO Do not send peron array if no additional info

  return (
    <>
      {page === "Start" && (
        <FormBase
          validationSchema={validation.metaStart1}
          initialValues={{ meta: formData.meta }}
        >
          {() => <Start />}
        </FormBase>
      )}
      {page === "Yourself" && (
        <FormBase
          validationSchema={validation.reporter}
          initialValues={formData}
        >
          {() => <Yourself />}
        </FormBase>
      )}
      {page === "Incident" && (
        <FormBase
          validationSchema={validation.incident}
          initialValues={formData}
          /* submitCallback={(values) => {
            const willEnterPersonInfo = values.info.bullying.haveAdditionalInfo;

            if (willEnterPersonInfo === "yes" && !pages.includes("Person")) {
              setPages((pages) => [
                ...pages.slice(0, 3),
                "Person",
                ...pages.slice(3),
              ]);
              return;
            }

            if (willEnterPersonInfo === "no" && pages.includes("Person")) {
              setPages((pages) => pages.filter((page) => page !== "Person"));
            }
          }} */
        >
          {() => <Incident />}
        </FormBase>
      )}
      {page === "Person" && (
        <FormBase
          validationSchema={validation.personsDecriptions}
          initialValues={formData}
        >
          {() => <Person />}
        </FormBase>
      )}
      {page === "Narrative" && (
        <FormBase
          validationSchema={validation.narrative}
          initialValues={{
            info: formData.info,
          }}
        >
          {() => <Narrative />}
        </FormBase>
      )}
      {page === "Review" && (
        <FormBase initialValues={{}}>
          {() => <Review formValues={formData} />}
        </FormBase>
      )}
      {page === "Finish" && (
        <FormBase initialValues={{}}>{() => <FinishBase />}</FormBase>
      )}
    </>
  );
}
