import React from "react";
import YourselfBase from "../../../../../common/YourselfBase";
import DOB from "../../../../../common/yourself-components/DOB";
import ContactInformation from "./ContactInformation";
import AddressMobile from "../../../../../common/yourself-components/AddressMobile/AddressMobile";
import FirstMiddleLast from "../../../../../common/yourself-components/FirstMiddleLast";
export default function Yourself({ step }) {
  switch (step) {
    case "0":
      return (
        <YourselfBase showInfoBox={false}>
          <FirstMiddleLast />
          <DOB />
        </YourselfBase>);

    case "1":
      return (
        <YourselfBase showInfoBox={false}>
          <div>
            <ContactInformation />
          </div>
        </YourselfBase>);
    case "2":
      return (<AddressMobile step={step} />);
    case "3":
      return (<AddressMobile step={step} />);
    default:
      return null;
  }

}
