import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
// import * as Yup from "yup";
import AnimateHeight from "react-animate-height";
import { Alert, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./ReportSelection.module.css";
import RadioField from "../../../form-fields/RadioField";
import { TimerResetContext } from "../../../../contexts/Timeout";
import { FormDataContext } from "../../../../contexts/FormData";
import InfoBox from "../../../common/InfoBox";
import CopyRight from "../../../common/CopyRight";
import ReCAPTCHA from "react-google-recaptcha";
const siteKey = "6LfbD2kgAAAAAEmduzZXFKhUf0d6QqGsjDUlTFCG"; // old key from previous app

const DescriptiveRadioField = ({ label, definition, example }) => {
  const [height, setHeight] = useState(0);

  const handleExpand = (e) => {
    e.preventDefault();

    setHeight((height) => (height ? 0 : "auto"));
  };

  return (
    <div className={styles.field}>
      <RadioField
        name="meta.incidentType"
        type="radio"
        value={label}
        style={{
          width: "100%",
          padding: "0.3rem 1rem",
          boxShadow:
            height === "auto"
              ? "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
              : "",
          transition: ".4s",
        }}
        label={
          <div className={styles.labelRoot}>
            <p>{label}</p>
            <div className={styles.expandIconContainer} onClick={handleExpand}>
              <ExpandMoreIcon
                style={{
                  transform: `rotate(${height ? "180deg" : 0})`,
                  transition: "0.4s",
                }}
              />
            </div>
          </div>
        }
      />
      <AnimateHeight height={height} duration={400}>
        <div className={styles.incidentDetails + " inner-shadow"}>
          <div className={styles.detailsItem}>
            <div className={styles.detailsHeader}>Definition</div>
            <p>{definition}</p>
          </div>
          <div className={styles.detailsItem}>
            <div className={styles.detailsHeader}>Example</div>
            <p>{example}</p>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};

// const validationSchema = Yup.object({
//   meta: Yup.object({
//     incidentType: Yup.string().required(
//       "You must provide a value for Incident Type."
//     ),
//   }),
//   recaptcha: Yup.string().required(),
// });

const verifyResponse = async (token) => {
  const url = "https://0aoepwm2qk.execute-api.ca-central-1.amazonaws.com/Prod/verify";
  const params = {
    token: token,
  };
  const body = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify( params ),
  };

  fetch(url, body).then(response => {
    console.log("Response: ");
    console.log(response);
  }, (err) => {
    console.log("Failure: ");
    console.log(err);
  });
}

export default function ReportSelection() {
  const controlResetTimer = useContext(TimerResetContext);
  const { setFormData } = useContext(FormDataContext);
  const recaptchaRef = React.createRef();

  const handleSubmit = async (values) => {
    setFormData({
      meta: {
        schemaVersion: "2.0.0",
        region: "Cobourg",
        incidentType: values.meta.incidentType,
      },
      recaptcha: values.recaptcha
    });
    console.log(values.recaptcha);
    verifyResponse(values.recaptcha);
    controlResetTimer();
  };

  return (
    <div className="page-root">
      <InfoBox
        title="Select Incident Type"
        text={
          <>
            There are several different incident types that can be entered using
            the internet. Please select one from the list below and continue. If
            the incident you are reporting has a known suspect, you should
            instead call us.
          </>
        }
      />
      <Formik
        initialValues={{ meta: { incidentType: "" }, recaptcha: "" }}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => {

          /* const handleChange = () => {
            console.log("handleChange");
            if (!props.values.recaptcha) {
              recaptchaRef.current.execute();
            }
          }; */

          return (
            <Form className={styles.form}>
              {props.errors.incidentType && (
                <Alert severity="error">{props.errors.incidentType}</Alert>
              )}
              <div className={styles.fields + " shadow"} /* onChange={handleChange} */>
                <DescriptiveRadioField
                  label="Freedom of Information"
                  definition={
                    <>
                      The Cobourg Police Service has created a bicycle
                      registration program to help protect bicycles owned by
                      residents of Cobourg . This program will increase the
                      chances of having your bicycle returned to you if it is
                      stolen or lost. It is important to remember that you still
                      have to make a police report if your bike is stolen.
                    </>
                  }
                  example={
                    <>
                      You own a bicycle and wish to register it with the Cobourg
                      Police Service. In the event your bicycle(s) is stolen in
                      the future, having this information saved with the Cobourg
                      PS may help to return your bicycle to you should it be
                      recovered.
                    </>
                  }
                />
               
              </div>
              <div className={styles.submit}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={siteKey}
                  onChange={(values) => { props.setFieldValue("recaptcha", values); }}
                  size="invisible"
                />
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={async () => {
                    if (props.values.meta.incidentType && !props.values.recaptcha) {
                      await recaptchaRef.current.executeAsync();
                    }
                    props.submitForm();
                  }}
                  disabled={!props.values.meta.incidentType}
                >
                  Start Report
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <CopyRight />
    </div>
  );
}
