import * as Yup from "yup";
import { incidentBaseValidationSchema } from "../../../../common/incidentBaseValidation";
import {
  eyeColors,
  involvementTypes,
  hairColors,
  footList,
  inchList,
} from "../../../../common/personBaseValidation";
import { reportTypeSelectionValidationSchema } from "../../../../common/templates/validation";
import { narrativeBaseValidationSchema } from "../../../../common/narrativeBaseValidation";
import { raceList } from "../../../../common/Race";
import { genderList } from "../../../../common/yourself-components/Gender";

export const metaStart1 = Yup.object({
  meta: reportTypeSelectionValidationSchema,
});

const requiredErrorMsg = "You must fill all required fields.";

export const reporter = Yup.object({
  reporter: Yup.object({
    firstName: Yup.string().required(requiredErrorMsg).max(20),
    lastName: Yup.string().required(requiredErrorMsg).max(20),
    homeAddress: Yup.object({
      country: Yup.string().required(requiredErrorMsg),
      streetNumber: Yup.string().max(30),
      streetName: Yup.string().required(requiredErrorMsg).max(100),
      streetType: Yup.string(),
      postDirection: Yup.string().oneOf(["E", "W", "S", "N"]),
      apartment: Yup.string().max(30),
      city: Yup.string().required(requiredErrorMsg).max(30),
      province: Yup.string().required(requiredErrorMsg),
      zipCode: Yup.string().required(requiredErrorMsg).max(20),
    }),
    contactPhoneNumber: Yup.string()
      .required()
      .max(12)
      .matches(
        /[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
        "Home phone is invalid."
      ),
    email: Yup.string().required().email("Invalid Email.").max(50),
    confirmEmail: Yup.string()
      .required()
      .when("email", {
        is: (email) => !!email?.length,
        then: Yup.string().required("Confirm Email has to match Email."),
      })
      .oneOf([Yup.ref("email")], "Confirm Email has to match Email."),
    gender: Yup.string().required(requiredErrorMsg).oneOf(genderList),
    dateOfBirth: Yup.date()
      .required(requiredErrorMsg)
      .nullable()
      .typeError("Invalid Date"),
  }),
  info: Yup.object({
    bullying: Yup.object({
      anonymous: Yup.string()
        .required(requiredErrorMsg)
        .oneOf(["yes", "no", "I don't know"]),
      willReportToSchool: Yup.string()
        .required(requiredErrorMsg)
        .oneOf(["yes", "no", "I don't know"]),
      school: Yup.string().max(50),
      grade: Yup.string().max(50),
    }),
  }),
});

export const incident = Yup.object({
  info: Yup.object({
    bullying: incidentBaseValidationSchema,
  }),
});

export const narrative = Yup.object({
  info: Yup.object({
    bullying: narrativeBaseValidationSchema,
  }),
});

export const personsDecriptions = Yup.object().shape({
  info: Yup.object({
    bullying: Yup.object({
      involvedPersons: Yup.array().of(
        Yup.object({
          involvementType: Yup.string()
            .required(requiredErrorMsg)
            .oneOf(involvementTypes),

          firstName: Yup.string().max(20),
          lastName: Yup.string().max(20),
          homeAddress: Yup.object({
            country: Yup.string(),
            streetNumber: Yup.string().max(30),
            streetName: Yup.string().max(100),
            streetType: Yup.string(),
            postDirection: Yup.string().oneOf(["E", "W", "S", "N"]),
            apartment: Yup.string().max(30),
            city: Yup.string().max(30),
            province: Yup.string(),
            zipCode: Yup.string().max(20),
          }),
          contactPhoneNumber: Yup.string()
            .max(12)
            .matches(
              /[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
              "Phone number is invalid."
            ),
          race: Yup.string().oneOf(raceList),
          dateOfBirth: Yup.date().nullable().typeError("Invalid Date"),
          school: Yup.string(),
          grade: Yup.string(),
          age: Yup.number()
            .typeError("Age must be a positive number (e.g. 24)")
            .positive("Age must be a positive number (e.g. 24)"),
          height: Yup.object({
            foot: Yup.number().oneOf(footList, "Feet should be a number"),
            inch: Yup.number().oneOf(inchList, "Inch should be a number"),
          }),
          personSize: Yup.string().oneOf(["large", "medium", "small", ""]),
          eyeColor: Yup.string().oneOf(eyeColors),
          hairColor: Yup.string().oneOf(hairColors),
          hairStyle: Yup.string(),
          facialHair: Yup.string(),
          clothingOrPhysicalDescription: Yup.string(),
          tattoosOrMarks: Yup.string(),
        })
      ),
    }),
  }),
});
