import React, { useContext } from "react";
import { Chip } from "@mui/material";
import Address from "./yourself-components/Address";
import DateInput from "./DateInput";
import styles from "./IncidentBase.module.css";
import InfoBox from "./InfoBox";
import FileField from ".././form-fields/FileField";
import { ConnectionContext } from "../../contexts/Connection";

export function IncidentBase({ children, text, color, formKey }) {
  const { loadingMessage } = useContext(ConnectionContext);

  return (
    <div className="page-root">
      <InfoBox
        style={{ padding: "1rem", color: { color } }}
        title="Enter Incident Information"
        text={text}
      />
      <div className={styles.fields + " shadow"}>
        <div className={styles.root}>
          <Address formKey={formKey} type="street" shouldValidateZip />
        </div>

        <div
          style={{
            paddingBottom: "1rem",
            borderBottom: "1px solid var(--border-color)",
          }}
        >
          <div className={styles.section}>
            <DateInput
              formKey={formKey + ".startTime"}
              label="Approximate Start Date/Time of Incident"
            />
          </div>
        </div>
        <div
          style={{
            paddingBottom: "1rem",
            borderBottom: "1px solid var(--border-color)",
          }}
        >
          <div className={styles.section}>
            <DateInput
              formKey={formKey + ".endTime"}
              label="Approximate End Date/Time of Incident"
            />
          </div>
        </div>
        <div>{children}</div>
        <div style={{ padding: "1rem" }}>
          <p style={{ paddingBottom: "0.5rem" }}>
            Documents/Photographs (incident related)
          </p>
          <FileField
            name="incident.photographs"
            accept="image/*, video/*, audio/*, application/pdf"
            label="Document Upload"
            buttonSize="small"
            fullWidth={true}
            renderValue={(files, handleDelete) => [
              ...files.map((file) => (
                <Chip
                  key={file?.id}
                  label={file?.filename}
                  className={styles.chip + " shadow"}
                  onDelete={() => handleDelete(file?.id)}
                /* color={
                  file?.state === "PENDING" ? "warning"
                  : file?.state === "FULLFILLED" ? "success"
                  : file?.state === "REJECTED" ? "error"
                  : "default"
                } */
                />
              )),
              loadingMessage && (
                <Chip
                  key="dummy chip"
                  label="Loading file(s)"
                  className={styles.chip + " shadow"}
                />
              ),
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export const locations = [
  "Parking lots",
  "Intersection",
  "Residential Street",
  "City Street",
  "Attached garage/shed",
  "B &amp; B rooms/resort units/other",
  "Bank/financial institution",
  "Bar, restaurant",
  "Car dealership",
  "Church (historic value)",
  "Church/synagogue/temple/mosque/religious institution",
  "Commercial vehicle inspection station",
  "Community centre",
  "Construction site",
  "Construction site (historic value)",
  "Convenience store",
  "Courthouse",
  "Customs office",
  "Detached garage/shed",
  "Factory",
  "Federal corrections facility (historic value)",
  "Gas station",
  "Government building",
  "Halfway house/social service agency",
  "Homeless shelter/mission",
  "Hospital",
  "Hospital (historic value)",
  "Hotel units/motel rooms",
  "Hydro station/plant",
  "Jail/penitentiary/corrections institution",
  "Jewelry store",
  "Office",
  "Open areas",
  "Other commercial dwelling unit",
  "Other commercial places",
  "Other commercial/corporate places - other business",
  "Other dwelling unit",
  "Other non-commercial/corporate places",
  "Other public transportation and connected facilities",
  "Pharmacy",
  "Plane/airport",
  "Police station",
  "Private property structure",
  "Provincial corrections facility (historic value)",
  "Residence - apartment/condominium",
  "Residence - dorms/complexes",
  "Residence - farm/agricultural",
  "Residence - hotel/rooming house",
  "Residence - semi/duplex",
  "Residence - senior citizen home",
  "Residence - single home, house",
  "Residence - surrounding property",
  "Residence - temporary/seasonal",
  "Residence - townhouse",
  "Residence - trailer/mobile home",
  "Restaurant",
  "Schools during supervised hours",
  "Schools not during supervised hours",
  "Snowmobile/ATV trail",
  "Store",
  "Streets, roads, highways",
  "Subway or subway station",
  "Trains/piggy back",
  "Transit bus or bus shelter",
  "Universities/colleges",
  "Unknown",
];
