import React from "react";
import TextInputField from "../../../../form-fields/TextInputField";
import styles from "./Fields.module.css";
import SelectField from "../../../../form-fields/SelectField";
import * as validation from "./validation";

function BrandModel({ formKey = "info.bicycleRegistration.bicycles", index }) {
  return (
    <div className={styles.root}>
      <div>
        <TextInputField
          name={`${formKey}.${index}.brand`}
          label={
            <>
              <span style={{ color: "red" }}>* </span> Brand
            </>
          }
          inputProps={{
            maxLength: 20,
          }}
        />
      </div>
      <div>
        <TextInputField
          name={`${formKey}.${index}.model`}
          label={
            <>
              <span style={{ color: "red" }}>* </span> Model
            </>
          }
          inputProps={{
            maxLength: 20,
          }}
        />
      </div>
    </div>
  );
}

function TypeStyle({ formKey = "info.bicycleRegistration.bicycles", index }) {
  return (
    <div className={[styles.root, styles.column].join(" ")}>
      {/* <p>Type and Style</p> */}
      <div className={styles.fields}>
        <div>
          <SelectField
            name={`${formKey}.${index}.bicycleType`}
            label={
              <>
                <span style={{ color: "red" }}>* </span> Bicycle Type
              </>
            }
            options={validation.bicycleTypes.map((bicycleType) => ({
              value: bicycleType,
              label: bicycleType,
            }))}
            sx={{ minWidth: 150 }}
          />
        </div>
        <div>
          <SelectField
            name={`${formKey}.${index}.style`}
            label={<>Bicycle Style</>}
            options={validation.bicycleStyles.map((bicycleStyle) => ({
              value: bicycleStyle,
              label: bicycleStyle,
            }))}
            sx={{ minWidth: 150 }}
          />
        </div>
        <div>
          <SelectField
            name={`${formKey}.${index}.handleType`}
            label={<>Handle Style</>}
            options={validation.bicycleHandleTypes.map((bicycleHandleType) => ({
              value: bicycleHandleType,
              label: bicycleHandleType,
            }))}
            sx={{ minWidth: 150 }}
          />
        </div>
        <div>
          <SelectField
            name={`${formKey}.${index}.seatStyle`}
            label={<>Seat Style</>}
            options={validation.bicycleSeatStyles.map((bicycleSeatStyle) => ({
              value: bicycleSeatStyle,
              label: bicycleSeatStyle,
            }))}
            sx={{ minWidth: 150 }}
          />
        </div>
      </div>
    </div>
  );
}

function SpeedSize({ formKey = "info.bicycleRegistration.bicycles", index }) {
  return (
    <div className={styles.root + " " + styles.column}>
      {/* <p>Speed and Wheel Size</p> */}
      <div className={styles.fields}>
        <div>
          <TextInputField
            name={`${formKey}.${index}.speed`}
            label={<>Bicycle Speed</>}
            inputProps={{
              maxLength: 20,
            }}
          />
        </div>
        <div>
          <TextInputField
            name={`${formKey}.${index}.wheelSize`}
            label={<>Wheel Size (inches)</>}
            inputProps={{
              maxLength: 20,
            }}
          />
        </div>
      </div>
    </div>
  );
}

function ColourNumberValue({
  formKey = "info.bicycleRegistration.bicycles",
  index,
}) {
  return (
    <div className={styles.root + " " + styles.column}>
      {/* <p>Colour and Value ($)</p> */}
      <div className={styles.fields}>
        <div>
          <SelectField
            name={`${formKey}.${index}.colour`}
            label={
              <>
                <span style={{ color: "red" }}>* </span> Colour
              </>
            }
            options={validation.colours.map((colour) => ({
              value: colour,
              label: colour,
            }))}
            sx={{ minWidth: 150 }}
          />
        </div>
        <div>
          <TextInputField
            name={`${formKey}.${index}.serialNumber`}
            label={
              <>
                <span style={{ color: "red" }}>* </span> Serial No
              </>
            }
            inputProps={{
              maxLength: 20,
            }}
          />
        </div>
        <div>
          <TextInputField
            name={`${formKey}.${index}.totalValue`}
            label={
              <>
                <span style={{ color: "red" }}>* </span> Total Value ($)
              </>
            }
            inputProps={{
              maxLength: 20,
            }}
          />
        </div>
      </div>
    </div>
  );
}

function AdditionalDescrption({
  formKey = "info.bicycleRegistration.bicycles",
  index,
}) {
  return (
    <div className={styles.root}>
      <TextInputField
        name={`${formKey}.${index}.additionalDescription`}
        label={<>Additional Description</>}
        multiline
        fullWidth
      />
    </div>
  );
}

export {
  BrandModel,
  TypeStyle,
  SpeedSize,
  ColourNumberValue,
  AdditionalDescrption,
};
