import React from "react";
import styles from "./Review.module.css"
import ReviewBase from "./ReviewBase"
import DisplayKeyValue from "../../../../../form-fields/DisplayKeyValue";
import PaymentScreen from "../VerificationScreen/PaymentScreen";
export default function Review({ formData, step }) {
    console.log("REIVEW DATA IS:", formData);
    switch (step) {
        case "0":
            return (<ReviewBase>
                <div className={styles.column}>
                    <div className={styles.card}>
                        <h4 >
                            Reporter
                        </h4>
                        <DisplayKeyValue keyName={"First Name"} value={formData.reporter.firstName} />
                        <DisplayKeyValue keyName={"Middle Name"} value={formData.reporter.middleName} />
                        <DisplayKeyValue keyName={"Last Name"} value={formData.reporter.lastName} />
                        <DisplayKeyValue keyName={"Gender"} value={formData.reporter.gender} />
                        <DisplayKeyValue keyName={"Phone Number"} value={formData.reporter.phoneNumber} />
                        <DisplayKeyValue keyName={"Other Number"} value={formData.reporter.otherNumber} />
                        <DisplayKeyValue keyName={"Date of Birth"} value={dateFormatter(formData.reporter.dateOfBirth)} />
                        <DisplayKeyValue keyName={"Email"} value={formData.reporter.email} />

                    </div>
                    <div className={styles.card}>
                        <h4 >
                            Address
                        </h4>
                        <DisplayKeyValue keyName={"Unit Number"} value={formData.reporter.homeAddress.unitNumber} />
                        <DisplayKeyValue keyName={"Street Number"} value={formData.reporter.homeAddress.streetNumber} />
                        <DisplayKeyValue keyName={"Street Name"} value={formData.reporter.homeAddress.streetName} />
                        <DisplayKeyValue keyName={"Post Direction"} value={formData.reporter.homeAddress.postDirection} />
                        <DisplayKeyValue keyName={"Postal Code"} value={formData.reporter.homeAddress.zipCode} />
                        <DisplayKeyValue keyName={"Country"} value={formData.reporter.homeAddress.country} />
                        <DisplayKeyValue keyName={"Province"} value={formData.reporter.homeAddress.province} />
                        <DisplayKeyValue keyName={"City"} value={formData.reporter.homeAddress.city} />
                    </div>
                    <div className={styles.card}>
                        <h4 >
                            Narrative
                        </h4>
                        <DisplayKeyValue keyName={"Narrative"} value={formData.info.adminreport.foireport.narrative} />
                    </div>
                    <div className={styles.card}>
                        <h4 >
                            Questions
                        </h4>
                        <DisplayKeyValue keyName={"Do you wish us to contact these individual to try and obtain their consent to disclose their information? "} value={formData.info.adminreport.foireport.contactIndivisual} />
                        <DisplayKeyValue keyName={"If yes, do you consent to our releasing YOUR identity to the individual we contact? "} value={formData.info.adminreport.foireport.releaseIdentity} />
                        <DisplayKeyValue keyName={"Request For "} value={formData.info.adminreport.foireport.request} />
                        <DisplayKeyValue keyName={"Preferred method of access to records: "} value={formData.info.adminreport.foireport.methodAccess} />
                    </div>


                </div>



            </ReviewBase >);
        case "1":
            return (
                <PaymentScreen />
            );
        default:
            return null;
    }

}
function dateFormatter(date) {
    return date ? date.toString().slice(4, 10) + "," + date.toString().slice(10, 15) : "";
}