import React, { useState } from "react";
import styles from "./InfoBox.module.css";
import AnimateHeight from "react-animate-height";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function InfoBox({ title, text, color }) {
  const [height, setHeight] = useState(window.innerWidth <= 768 ? 0 : "auto");

  const handleExpand = (e) => {
    e.preventDefault();

    setHeight((height) => (height ? 0 : "auto"));
  };

  return (
    <div className={styles.root + " shadow"}>
      <div onClick={handleExpand}>
        <b>{title}</b>
        <div className={styles.expandIconContainer}>
          <ExpandMoreIcon
            style={{
              transform: `rotate(${height ? "180deg" : 0})`,
              transition: "0.4s",
            }}
          />
        </div>
      </div>
      <AnimateHeight height={height} duration={400}>
        <p style={{ color }}>{text}</p>
      </AnimateHeight>
    </div>
  );
}
