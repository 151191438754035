import React, { useContext, useEffect, useState } from "react";
import { FormDataContext } from "../../contexts/FormData";
import { PageContext } from "../../contexts/Page";
import styles from "./Header.module.css";
import Drawer from "./Drawer";

export default function Header({
  websiteURL,
  name,
  logo,
  logoText,
  showReportTitle,
}) {
  const mobileCutOff = 630;
  const { pages: rawPages, page: rawCurrentPage } = useContext(PageContext);
  const { formData } = useContext(FormDataContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileCutOff);
  const bannerMessage = showReportTitle
    ? formData.meta.incidentType
    : "For emergencies call 911";

  function handleResize() {
    setIsMobile(window.innerWidth < mobileCutOff);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [, setProgress] = useState(0);

  useEffect(() => {
    if (!rawPages.length) return;
    setProgress(
      (rawPages.indexOf(rawCurrentPage) / (rawPages.length - 1)) * 100
    );
  });

  return (
    <header>
      <div className={styles.box}>
        <a href={websiteURL} target="_blank" rel="noreferrer">
          <div className={isMobile ? styles.logoText : styles.logo}>
            <img src={isMobile ? logoText : logo} alt={`${name} Logo`} />
          </div>
        </a>
        {/* TODO Comment out drawer in prod */}
        {/* <Drawer /> */}
        {!isMobile && (
          <div className={styles.bannerDesktop}>
            <div>
              <h3 className={styles.callMessage}>Emergency Call 911</h3>
              <h4 className={styles.incidentTypeMessage}>{bannerMessage}</h4>
            </div>
          </div>
        )}
        {isMobile && (
          <div className={styles.bannerMobile}>
            <div>
              <h3 className={styles.callMessage}>Emergency Call 911</h3>
              <h4 className={styles.incidentTypeMessage}>{bannerMessage}</h4>
            </div>
          </div>
        )}

        <a href="https://www.tryrubicon.com/" target="_blank" rel="noreferrer">
          {!isMobile && (
            <div className={styles.desktopBranding}>
              <p>
                <b>POWERED BY </b>
              </p>
              <img
                src="Rubicon Logo and Text Grey Straight.png"
                alt="Rubicon Logo"
              />
            </div>
          )}
          {isMobile && (
            <div className={styles.mobileBranding}>
              <img src="android-chrome-192x192.png" alt="Rubicon Logo" />
            </div>
          )}
        </a>
      </div>
    </header>
  );
}
