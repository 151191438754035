import React from "react";
import { locations, IncidentBase } from "../../../../common/IncidentBase";
import SelectField from "../../../../form-fields/SelectField";
import styles from "./Incident.module.css";

export default function Incident({ formKey = "info.lostProperty" }) {
  return (
    <IncidentBase
      formKey={formKey}
      text={
        <>
          Please enter all the information that applies. If you do not know the
          address of the incident, use the nearest intersection such as Market
          St./ 10th St.
        </>
      }
      color=""
    >
      <div
        style={{
          paddingBottom: "1rem",
          borderBottom: "1px solid var(--border-color)",
        }}
      >
        <p style={{ paddingLeft: "1rem" }}>
          <span style={{ color: "red" }}>* </span>Location Type
        </p>
        <div className={styles.section}>
          <SelectField
            options={locations.map((location) => ({
              value: location,
              label: location,
            }))}
            name={formKey + ".locationType"}
            label={"Please Select"}
          />
        </div>
      </div>
    </IncidentBase>
  );
}
