import React from "react";
import RadioField from "../../form-fields/RadioField";
import styles from "./ConfirmQuestions.module.css";

export default function ConfirmQuestions({ questions }) {
  return (
    <div className={styles.root}>
      <div className={styles.header + " shadow"}>
        <b>Confirm Question(s)</b>
      </div>
      <div>
        {questions.map((question, index) => (
          <div key={index} className={styles.question + " shadow"}>
            {question.value}
            <div className={styles.buttonGroup}>
              <RadioField
                label="Yes"
                type="radio"
                name={question.name}
                value="yes"
              />
              <RadioField
                label="No"
                type="radio"
                name={question.name}
                value="no"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
