import React from "react";
import FormTemplate from "../../../../common/templates";

const questions = [
  {
    value: "Is this an Emergency?",
    name: "start0.isEmergency",
  },
  {
    value: "Do you have a valid email address?",
    name: "start0.haveValidEmailAddress",
  },
  {
    value: "Are there any known suspects?",
    name: "start0.haveKnownSuspects",
  },
  {
    value:
      "Does the amount of property stolen from the vehicle total over $5,000.00?",
    name: "start0.damageWasOver5000",
  },
  {
    value: "Did the theft occur within the City of Cobourg?",
    name: "start0.wasWithinCobourg",
  },
];

export default function Start({ step }) {
  switch (step) {
    case "0":
      return (
        <div>
          <FormTemplate
            templateName="confirm-questions"
            questions={questions}
          />
        </div>
      );
    case "1":
      return (
        <div>
          <FormTemplate templateName="report-type-selection" formKey="meta" />
        </div>
      );
    case "2":
      return (
        <div>
          <FormTemplate templateName="person-type-selection" formKey="meta" />
        </div>
      );
    default:
      return null;
  }
}
