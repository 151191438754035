import React from "react";
import VerificationBase from "./VerificationBase";
import styles from "./Verification.module.css";
import FileField from "../../../../../form-fields/FileField";
import faceIcon from "../Icons/face.png";
import id from "../Icons/id.png";
import facewithID from "../Icons/facewithId.png";
import { Tooltip, IconButton } from "@mui/material";
import HelpOutline from '@mui/icons-material/HelpOutline';

export default function PhotoScreen() {
    return (<VerificationBase>
        <div className={styles.body}>
            <div className={styles.column}>
                <h4 className={styles.h4}>Photos</h4>
                <div className={styles.row}>
                    <h4 className={styles.h4Normal}>We need to verify your identity</h4>
                    <Tooltip PopperProps={{
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, -20],
                                },
                            },
                        ],
                    }} title="Eg: Driver Licence , Passport , HealthCard , more , more " className={styles.helpIcon} placement="top" sx={{ padding: 0, marginTop: .5, maxWidth: 200, }}>
                        <IconButton>
                            <HelpOutline />
                        </IconButton>
                    </Tooltip>
                </div>


                <div className={styles.button}>
                    <FileField
                        retake={true}
                        icon={faceIcon}
                        id="0"
                        name="info.adminreport.foireport.photoFace"
                        accept="image/*, video/*, audio/*, application/pdf"
                        label="Take a clear photo of Your Face"
                        label2="Retake a photo of Your Face"
                        buttonSize="small"
                    />


                </div>
                <div className={styles.button}>
                    <FileField
                        retake={true}
                        icon={id}
                        id="1"
                        name="info.adminreport.foireport.photoID"
                        accept="image/*, video/*, audio/*, application/pdf"
                        label="Take a clear photo of your ID (eg: driver license)"
                        label2="Retake a photo of Your ID"
                        buttonSize="small"

                    />
                </div>
                <div className={styles.button}>
                    <FileField
                        retake={true}
                        icon={facewithID}
                        id="2"
                        name="info.adminreport.foireport.photoBoth"
                        accept="image/*, video/*, audio/*, application/pdf"
                        label="Take a clear photo of Your Face and ID in the same photo"
                        label2="Retake a photo of Your Face and ID in the same photo"
                        buttonSize="small"
                    />
                </div>
                <p className={styles.redText}> If officers have trouble validating photos, you may be asked to visit the police service in person</p>
            </div>
        </div>
    </VerificationBase>);



}
