import React from "react";
import ContactPhoneNumber from "../../../../common/yourself-components/ContactPhoneNumber";
import DOB from "../../../../common/yourself-components/DOB";
import EmailAndConfirmEmail from "../../../../common/yourself-components/EmailAndConfirmEmail";
import FirstAndLastName from "../../../../common/yourself-components/FirstAndLastName";
import Gender from "../../../../common/yourself-components/Gender";
import Address from "../../../../common/yourself-components/Address";
import YourselfBase from "../../../../common/YourselfBase";
import styles from "./Yourself.module.css";

export default function Yourself(formKey = "reporter") {
  return (
    <YourselfBase
      infoBoxProps={{
        text: (
          <>
            <li>
              Please enter the bicycle owners information as completely as
              possible. If bicycle is for a child, enter parent or guardian
              information.
            </li>
            <li> You may be contacted regarding this incident.</li>
            <li>
              An email address is required if you would like to be notified when
              this report is received and approved.
            </li>
          </>
        ),
      }}
    >
      <FirstAndLastName />
      <Address />
      <div className={styles.section}>
        <Gender />
        <ContactPhoneNumber required/>
      </div>
      <EmailAndConfirmEmail required />
      <DOB />
    </YourselfBase>
  );
}
