import React from "react";
import sucessIcon from "../adminforms/main/components/foi-report/Icons/sucess.png";
import styles from "./SucessCard.module.css";
export default function SucessCard() {
  return (
    <div className={styles.column}>
      <img src={sucessIcon} alt={""} className={styles.icon}></img>
      <p className={styles.text2}>Transaction Completed Successfully </p>
      <p className={styles.text}>Thank you!</p>
    </div>
  );
}
