import React from "react";
import styles from "./PropertyBase.module.css";
import InfoBox from "./InfoBox";
import SelectField from "../form-fields/SelectField";
import TextInputField from "../form-fields/TextInputField";
import { typesSubtypesObj, colors } from "./propertyBaseValidation";
import { FieldArray } from "formik";
import { useField } from "formik";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function PropertyBase({
  formKey = "property",
  damaged = false,
  children,
}) {
  const [field, meta, helpers] = useField(formKey);
  const { value } = meta;
  const [open, setOpen] = React.useState([true, true]);

  const handleClick = (index) => () => {
    setOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      newOpen[index] = !newOpen[index];
      return newOpen;
    });
  };

  return (
    <div className="page-root">
      <InfoBox
        title="Enter Property Information"
        text="Please enter information for the property items that were damaged or stolen."
      />
      <div className={styles.fields + " shadow"}>
        <FieldArray
          name={formKey}
          render={(arrayHelpers) => (
            <div>
              {value.length > 0 &&
                value.map((property, index) => (
                  <div key={index}>
                    <div className={styles.expandButton} value={index}>
                      <p
                        onClick={handleClick(index)}
                        className={styles.boxTitle}
                      >
                        Property {index + 1}
                      </p>
                      {open[index] ? (
                        <ExpandLess onClick={handleClick(index)} />
                      ) : (
                        <ExpandMore onClick={handleClick(index)} />
                      )}

                      <IconButton
                        onClick={() => {
                          if (value.length > 1) {
                            arrayHelpers.remove(index);
                            setOpen((prevOpen) => {
                              const newOpen = [...prevOpen];
                              newOpen.splice(index, 1);
                              return newOpen;
                            });
                          }
                        }}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    <Collapse in={open[index]} timeout="auto" unmountOnExit>
                      <div className={styles.root}>
                        <SelectField
                          name={`${formKey}.${index}.propertyType`}
                          options={Object.keys(typesSubtypesObj).map(
                            (type) => ({
                              value: type,
                              label: type,
                            })
                          )}
                          label={
                            <>
                              <span style={{ color: "red" }}>*</span> Type
                            </>
                          }
                          sx={{ width: 0.5 }}
                        />
                        <SelectField
                          name={`${formKey}.${index}.subType`}
                          options={typesSubtypesObj[
                            value[index].propertyType
                          ]?.map((subType) => ({
                            value: subType,
                            label: subType,
                          }))}
                          label="Subtype"
                          sx={{ width: 0.5 }}
                        />
                      </div>

                      <div className={styles.root}>
                        <TextInputField
                          name={`${formKey}.${index}.brand`}
                          label={"Brand"}
                          sx={{ width: 0.5 }}
                        />
                        <TextInputField
                          name={`${formKey}.${index}.model`}
                          label={"Model"}
                          sx={{ width: 0.5 }}
                        />
                      </div>

                      <div className={styles.root}>
                        <SelectField
                          name={`${formKey}.${index}.colour`}
                          options={colors.map((coulor) => ({
                            value: coulor,
                            label: coulor,
                          }))}
                          label="Colour"
                          sx={{ width: 0.5 }}
                        />
                        <TextInputField
                          name={`${formKey}.${index}.serialNo`}
                          label={"Serial No "}
                          sx={{ width: 0.5 }}
                        />
                      </div>

                      <div className={styles.root}>
                        <TextInputField
                          name={`${formKey}.${index}.quantity`}
                          label={
                            <>
                              <span style={{ color: "red" }}>*</span> How Many
                            </>
                          }
                          sx={{ width: 0.5 }}
                        />

                        {damaged && (
                          <TextInputField
                            name={`${formKey}.${index}.damagedValue`}
                            label={
                              <>
                                <span style={{ color: "red" }}>*</span> Damaged
                                Value ($)
                              </>
                            }
                            sx={{ width: 0.5 }}
                          />
                        )}

                        {!damaged && (
                          <TextInputField
                            name={`${formKey}.${index}.damagedValue`}
                            label={
                              <>
                                <span style={{ color: "red" }}>*</span> Total
                                Value ($)
                              </>
                            }
                            sx={{ width: 0.5 }}
                          />
                        )}
                      </div>

                      <div className={styles.block}>
                        <TextInputField
                          name={`${formKey}.${index}.description`}
                          label="Property Description"
                          sx={{ width: 1 }}
                          multiline
                        />
                      </div>
                    </Collapse>
                  </div>
                ))}
              <Button
                fullWidth
                variant="outlined"
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  arrayHelpers.push({
                    propertyType: "",
                    subType: "",
                    brand: "",
                    model: "",
                    colour: "",
                    serialNo: "",
                    quantity: "",
                    damagedValue: "",
                    description: "",
                  });

                  setOpen((prevOpen) => [...prevOpen, true]);
                }}
              >
                Add Property Item
              </Button>
            </div>
          )}
        />
      </div>
    </div>
  );
}
