import * as Yup from "yup";

const requiredErrorMsg = "You must fill all required fields.";
export const typesSubtypesObj = {
  Appliance: [
    "Air conditioner",
    "Barbecue",
    "Blender",
    "Bread maker",
    "Cleaning equipment",
    "ClOthes washer",
    "Coffee maker",
    "Curling iron",
    "Dish washer",
    "Dryer",
    "Electric knife",
    "Fan",
    "Food mixer",
    "Food processor",
    "Freezer",
    "Frying pan",
    "Furnace",
    "Hair dryer",
    "Heater",
    "Iron",
    "Kettle",
    "Kitchen appliance",
    "Microwave",
    "Other appliance",
    "Pasta maker",
    "Popcorn popper",
    "Refrigerator",
    "Sewing machine",
    "Stove",
    "Toaster",
    "Vacuum",
    "Waffle iron",
  ],
  "Computers, parts or accessories": [
    "CD-ROM drive",
    "Central processing unit",
    "Compact disc",
    "Complete system",
    "Computer games/software",
    "Computer tower",
    "Disk drive",
    "DVD drive",
    "External/removable drive",
    "Floppy disk",
    "Keyboard",
    "Laptop computer",
    "Mouse",
    "Other computer equipment",
    "Portable tablet",
    "Printer",
    "Printer - dot matrix",
    "Printer - ink/bubble jet",
    "Printer - laser",
    "Printer - Other",
  ],
  "Consumable goods": ["Food and beverages", "Other consumable goods"],
  "Household articles": [
    "Antiques",
    "Bedspreads/blankets",
    "Brass/bronze ware",
    "Chairs",
    "China",
    "Clocks",
    "Couch/sofa",
    "Drapes",
    "Figurines",
    "Floor coverings",
    "Furniture",
    "Household decorations",
    "Knives",
    "Lamps",
    "Lawn statues/lawn ornaments",
    "Linens",
    "Other household furnishings",
    "Paintings",
    "Plants",
    "Pool equipment",
    "Restaurant fixtures",
    "Silverware",
    "Tables",
    "Towels",
    "Toys",
  ],
  "Machinery or tools": [
    "Axe",
    "Barbecue",
    "Building materials",
    "Cement mixer",
    "Commercial power tool",
    "Construction equipment",
    "Electric cable",
    "Electric motor/generator",
    "Electric supplies",
    "Engine",
    "Farm equipment",
    "Garden power tool",
    "Generator",
    "Hand tool",
    "Heating/cooling equipment",
    "Household power tool",
    "Impact tool",
    "Jacks and hoist",
    "Jewelers instrument",
    "Lawn statues/lawn ornaments",
    "Lawnmower - (push)",
    "Lawnmower - mechanical (hand)",
    "Lawnmower - riding",
    "Machete",
    "Manual garden tool",
    "Other machinery/tool",
    "Planer",
    "Plants",
    "Plumbing supplies",
    "Pool equipment",
    "Pump - hand",
    "Pump - motor drive",
    "Rototiller",
    "Saw (all types)",
    "Snowblower",
    "Welding equipment",
    "Other",
  ],
  "Musical instrument": [
    "Accordion",
    "Bagpipes",
    "Banjo",
    "Brass instrument",
    "Cymbals",
    "Drums",
    "Electronic keyboard",
    "Guitar - acoustic",
    "Guitar - electric",
    "Harmonica",
    "Organ",
    "Other musical instrument",
    "Percussion instrument",
    "Piano",
    "Saxophone",
    "String instrument",
    "Tambourine",
    "Trumpet",
    "Woodwind instrument",
  ],
  "Natural Resources": ["Aggregate", "Forest resources", "Petroleum"],
  "Office equipment": [
    "Adding machines",
    "Answering machine",
    "Calculator",
    "Cash register",
    "Cellular/mobile phone",
    "Chair (office)",
    "Chairs (barber/dentist)",
    "Check writer",
    "Dictaphone",
    "Facsimile",
    "Furniture",
    "Other office equipment",
    "Pager",
    "Photocopier",
    "Postage meter",
    "Safe/cash box",
    "Telephone",
    "Typewriter",
    "Walkie-talkie",
    "Other",
  ],
  "Personal accessories": [
    "Barbering equipment",
    "Boots/shoes",
    "Briefcase/portfolio",
    "Clothing",
    "Coat",
    "Cosmetics",
    "Dentures",
    "Eye glasses",
    "Furs",
    "Hearing aid",
    "Luggage",
    "Necklace",
    "Other accessories",
    "Pacemaker",
    "Purse/handbag/knapsack",
    "Razor",
    "Ring",
    "Smoker sundries",
    "Tobacco",
    "Wallet/billfold",
    "Wigs/hairpieces",
  ],
  "Photographic equipment": [
    "Camera",
    "Camera bag",
    "Developer",
    "Editor",
    "Enlarger",
    "Film",
    "Film splicer",
    "Flash",
    "Lens",
    "Light meter",
    "Other photographic equipment",
    "Photographs",
    "Projector",
    "Screen",
    "Tripod",
    "Viewer",
  ],
  "Radio, T.V., etc": [
    "Amplifier",
    "Answering machine",
    "Antique - player/radio",
    "Car radio/cass/tape deck",
    "Car radio/CD player",
    "Cassette deck/8 track",
    "Cassette/8 track tape",
    "CB radio/ham radio",
    "Cellular/mobile phone",
    "Compact disc",
    "Compact disk player",
    "Digital video disc (DVD)",
    "Digital video disc player",
    "Earphones",
    "Headphones",
    "Microphones",
    "Monitor",
    "Other radio/TV/sound equip.",
    "Pager",
    "Portable media player",
    "Radio",
    "Record player",
    "Records",
    "Satellite dish",
    "Satellite receiver",
    "Sound producing equipment",
    "Speaker",
    "Stereo tuner/receiver",
    "Tape recorder/player",
    "Telephone",
    "Television",
    "Turntable",
    "Video camera",
    "Video cartridge/game",
    "Video cassette recorder",
    "Video game player",
    "Walkie-talkie",
    "Walkman-style radio",
  ],
  "Scientific or optical devices": [
    "Barometer",
    "Binoculars",
    "Compass",
    "Electric measuring equipment",
    "Fish finder",
    "Global positioning system",
    "Lens - Scientific",
    "Marine depth finder",
    "Medical bag",
    "Medical equipment",
    "Microscope",
    "Optical equipment",
    "Radar equipment",
    "Respirator",
    "Scales",
    "Slide rule",
    "Survey equipment",
    "Telescope",
    "Other",
  ],
  "Sporting goods": [
    "Baseball equipment",
    "Camping equipment",
    "Fishing equipment",
    "Football equipment",
    "Golf equipment",
    "Gym/exercise equipment",
    "Harness/riding",
    "Hiking equipment",
    "Hobby equip. (models, etc.)",
    "Hockey equipment",
    "Hunting equipment",
    "Ice skates",
    "Other sporting equipment",
    "Roller skates/blades",
    "Saddle",
    "Scuba equipment",
    "Skateboard",
    "Ski equipment",
    "Soccer equipment",
    "Sports cards",
    "Tennis equipment",
    "Trampoline",
    "Water sport equipment",
  ],
  "Vehicle accessory": [
    "Amplifier",
    "Axle",
    "Battery",
    "Bumper",
    "Carburetor",
    "Cassette deck/8 track",
    "Compact disk player",
    "Door",
    "Engine",
    "Fender",
    "Frame",
    "Gasoline",
    "Hood",
    "Other accessory",
    "Other parts",
    "Radar detector",
    "Radio",
    "Speaker",
    "Tires",
    "Transmission",
    "Wheel disc",
    "Wheels",
    "Other",
  ],
  "Other article": [
    "Ammunition, Other",
    "Animals",
    "Baby carriage/car seat",
    "Blasting caps",
    "Bones",
    "Books",
    "Boxes, mail, papers, etc.",
    "Bullion (gold, silver)",
    "Casino -Other property",
    "Casino -slot machine",
    "Casino token/chip",
    "Chemicals",
    "Drug accessory",
    "Dynamite",
    "Fire extinguisher equipment",
    "Flag",
    "Fuse",
    "Gauges",
    "Keys",
    "Lights",
    "Lumber",
    "Metal bar",
    "Money changer",
    "Nitroglycerin",
    "Office/school supplies",
    "Other explosive",
    "Other gambling property",
    "Other gaming device",
    "Other weapons - not guns",
    "Parking equipment",
    "Plastic",
    "Police equipment",
    "Prescription drugs",
    "Religious item",
    "Restaurant fixtures",
    "Scrap metal",
    "Signs",
    "Stamps",
    "Vending machine",
    "Wheel chair/pedal car",
    "Other",
  ],
  "Canadian currency": [
    "Cash",
    "Federal reserve bank note",
    "Federal reserve note",
    "Gold certificate",
    "National bank note",
    "Silver certificate",
    "Other",
  ],
  "Corporate bonds": [],
  "Credit card": [
    "American Express",
    "ATM card",
    "Canadian Tire",
    "Diners Club",
    "En Route",
    "Esso",
    "MasterCard",
    "Other oil/gas",
    "Other store",
    "Petro Canada",
    "Sears",
    "Shell",
    "Simpsons",
    "Sunoco",
    "Texaco",
    "Trust company",
    "VISA",
    "Other",
  ],
  "Debit card": [],
  "Foreign currency": [
    "British pound",
    "French franc",
    "German mark",
    "Italian lire",
    "Japanese yen",
    "Other",
  ],
  "Government bonds": [],
  Identification: [],
  "Money order/travellers cheque": [],
  "Other securities": [],
  Stocks: [],
  "U.S. currency": [
    "Cash",
    "Treasury bill",
    "Treasury bond",
    "Treasury certificate",
    "Treasury note",
    "U.S. individual retirement bond",
    "U.S. postal money order",
    "U.S. retirement plan bond",
    "U.S. savings bond",
    "U.S. savings note",
    "Other",
  ],
  "Vehicle documents": [],
  "Commercial/industrial Boat": [
    "Barge",
    "Cruise ship",
    "Ferry",
    "Fishing boat",
    "Icebreaker",
    "Launch",
    "Lifeboat",
    "Steamship",
    "Trawler",
    "Tugboat",
  ],
  Houseboat: ["Full hull", "Pontoon hull", "Other"],
  "Military vessel/boat": [],
  "Police boat": [],
  Powerboat: [
    "Air boat",
    "Bass boat",
    "Bow rider",
    "Cabin cruiser",
    "Car topper",
    "Dinghy (power)",
    "Inflatable",
    "Personal watercraft",
    "Racing boat",
    "Ski boat",
    "Tri hull",
    "Tunnel hull",
    "V hull",
    "Other",
  ],
  Sailboat: [
    "Cabin",
    "Catamaran",
    "Day sail",
    "Racing sailboat",
    "Recreational sailboat",
    "Other",
  ],
  "Other watercraft": [
    "Bathtub boat",
    "Canoe",
    "Dinghy",
    "Hovercraft",
    "Hydro foil",
    "Hydro plane",
    "Ice boat",
    "Inflatable (non-power)",
    "Kayak",
    "Paddle boat",
    "Rowboat",
    "Windsurfer",
    "Other",
  ],
  Alcohol: ["Beer", "Spirits", "Wine"],
};

export const colors = [
  "Beige",
  "Black",
  "Blue",
  "Blue, dark",
  "Blue, light",
  "Bronze",
  "Brown",
  "Copper",
  "Cream, ivory",
  "Gold",
  "Green",
  "Green, dark",
  "Green, light",
  "Grey",
  "Maroon",
  "Orange",
  "Pink",
  "Purple, mauve",
  "Red",
  "Silv., alum., st. steel",
  "Tan",
  "Turquoise",
  "White",
  "Yellow",
  "Other",
];

export const propertyBaseValidationSchema = Yup.object({
  propertyType: Yup.string()
    .required(requiredErrorMsg)
    .oneOf(Object.keys(typesSubtypesObj)),
  subType: Yup.string(),
  brand: Yup.string(),
  model: Yup.string(),
  colour: Yup.string().oneOf(colors),
  serialNo: Yup.string(),
  quantity: Yup.number()
    .typeError(
      "Put a number (e.g. 2) for how many items were damaged or stolen"
    )
    .required(requiredErrorMsg),
  description: Yup.string(),
  damagedValue: Yup.number()
    .typeError("Value should be a number")
    .required(requiredErrorMsg),
});
