import React, { createContext, useState, useCallback } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../config";

function readFileDataAsBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };

    reader.onerror = (err) => {
      reject(err);
    };

    reader.readAsDataURL(file);
  });
}
export const ConnectionContext = createContext();
export default function ConnectionProvider({ children }) {
  const [sessionToken, setSessionToken] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [error, setError] = useState({
    errorMsg: "",
    callback: null,
    args: null,
  });
  const [ref, setRef] = useState(null);

  //FOI
  const onRefChangeHandler = useCallback((node) => {
    setRef(node);
  }, []);

  const generateSessionToken = () => {
    const url = config.BACKEND_API_URL + "recaptcha/verify";
    setLoadingMessage("Verifying user...");
    return ref
      .executeAsync()
      .then((token) => {
        const params = {
          token: token,
        };
        const body = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        };
        return fetch(url, body);
      })
      .then((response) => {
        if (response.status !== 200)
          throw new Error(
            `Issue communicating with backend (Error ${response.status})`
          );
        return response.json();
      })
      .then((json) => {
        console.log(json.session_token);
        setSessionToken(json.session_token);
        setLoadingMessage("");
        return json.session_token;
      })
      .catch((err) => {
        setLoadingMessage("");
        setError({
          errorMsg: err.message,
          callback: generateSessionToken,
          args: null,
        });
        return null;
        // alert(`An error occured while generating session token. Please try again`);
      });
  };

  const getSessionToken = () => {
    if (sessionToken) return Promise.resolve(sessionToken);
    else return generateSessionToken();
  };

  //TODO Leave comments or break it up, as it is confusing
  const upMedia = (file, existingFiles) => {
    if (existingFiles.some((element) => element.filename === file.name)) {
      setError({
        errorMsg: `Cannot upload ${file.name} since you have already uploaded a file with the same name.`,
        callback: upMedia,
        args: file,
      });
      return Promise.resolve(null);
    }
    let attachment;
    return getSessionToken()
      .then((sessionToken) => {
        setLoadingMessage("Uploading File...");
        console.log(file);
        const url = config.BACKEND_API_URL + "reporting/request_presigned_url";
        const body = JSON.stringify({
          filename: file.name,
          mime: file.type,
          length: file.size,
        });
        const fetchBody = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + sessionToken,
          },
          body: body,
        };
        return fetch(url, fetchBody);
      })
      .then((response) => {
        if (response.status !== 200)
          throw new Error(
            `Issue communicating with backend (Error ${response.status})`
          );
        return response.json();
      })
      .then((json) => {
        const url = json.url;
        attachment = json.attachment;

        let body = new FormData();
        Object.keys(json.fields).forEach((key) => {
          body.append(key, json.fields[key]);
        });
        body.append("file", file);

        const fetchBody = {
          method: "POST",
          body: body,
          mode: "no-cors",
        };

        return fetch(url, fetchBody);
      })
      .then((response) => {
        // if (response.status !== 204) throw new Error(`Issue communicating with backend (Error ${response.status})`);
        setLoadingMessage("");
        return {
          url: URL.createObjectURL(file),
          type: file.type,
          ...attachment,
        };
      })
      .catch((error) => {
        setLoadingMessage("");
        setError({
          errorMsg: error.message,
          callback: upMedia,
          args: file,
        });
        return null;
        // alert(`An error occured while uploading ${file.name}. Please try again`);
      });
  };

  const submitCrimeForm = (formData) => {
    const url = config.BACKEND_API_URL + "reporting/submit";
    return getSessionToken()
      .then((sessionToken) => {
        setLoadingMessage("Uploading report...");
        let data = {
          report: formData,
          attachments: formData.incident.photographs,
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + sessionToken,
          },
          body: JSON.stringify(data),
        };
        return fetch(url, requestOptions);
      })
      .then((response) => {
        if (response.status !== 200)
          throw new Error(
            `Issue communicating with backend (Error ${response.status})`
          );
        return response.status;
      })
      .then((status) => {
        setLoadingMessage("");
        return status;
      })
      .catch((error) => {
        setLoadingMessage("");
        setError({
          errorMsg: error.message,
          callback: submitCrimeForm,
          args: formData,
        });
        return null;
        // alert(`An error occured while submitting your report. Please try again`);
      });
  };

  //All FOI Report Calls
  //Payment Calls
  //Makes a payment intent call to server
  const paymentIntent = async () => {
    const url = config.PAYMENT_EMAIL_API_URL + "payment";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    return data;
  };
  //Webhook from stripe to confirm payment
  const paymentWebHook = async () => {
    const url = config.PAYMENT_EMAIL_API_URL + "user";
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    return data;
  };

  //Form Calls
  const submitAdminForm = async (formData) => {
    // debugger;
    const url = config.PAYMENT_EMAIL_API_URL + "submit";
    let data = { report: formData, attachments: [], debug: "false" };
    let face = formData.info.adminreport.foireport.photoFace;
    let id = formData.info.adminreport.foireport.photoID;
    let both = formData.info.adminreport.foireport.photoBoth;
    let signature = formData.info.adminreport.foireport.signature;
    let promisesFiles = [];
    let requestOptions;
    promisesFiles.push(readFileDataAsBase64(face));
    promisesFiles.push(readFileDataAsBase64(id));
    promisesFiles.push(readFileDataAsBase64(both));
    promisesFiles.push(readFileDataAsBase64(signature));

    await Promise.all(promisesFiles)
      .then(async (values) => {
        values.map((fileString, index) => {
          data.attachments.push({
            data: fileString.split(";")[1].substr(7),
            name: face.name,
            mime: face.type,
            lastModified: face.lastModified,
            size: face.size,
          });
        });
        requestOptions = {
          mode: "cors",
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        await fetch(url, requestOptions);
      })
      .then((response) => {
        return response;
      });
  };

  return (
    <ConnectionContext.Provider
      value={{
        getSessionToken,
        upMedia,
        submitCrimeForm,
        loadingMessage,
        error,
        setError,
        paymentIntent,
        paymentWebHook,
        submitAdminForm,
      }}
    >
      <ReCAPTCHA
        ref={onRefChangeHandler}
        sitekey={config.RECAPTCHA_SITEKEY}
        size="invisible"
      />
      {children}
    </ConnectionContext.Provider>
  );
}

/*
  {
    report: {
      info:
      meta:
    }
    attachments: [
      {
        filename: "1123.png",
        id: "123lkj"
      }
    ]
  }

error = {
  errorMsg: string
  attemptedOperation: function
  args: obj
}

POST /request-signed-url
{
  filename: string,
  mime: string,
  length: number
}
sign with bearer token.

Response:
{
  url: string
  fields: {               // copy these one by one into formdata, then append file AT THE END
    filename: string
    content-type: string
    key: string
    algorithm: string
    credential: string
    date: string
    policy: string
    signature: string
  }                     // Copy into list of attachments
  attachment: {
    id: string
    filename: string
  }
}

POST /s3
{
  copy in fields
  append image
}
sign with bearer token.

response:
204 no content

POST /submit
{
  attachments: [
    {
      id: string
      filename: string
    },
  ]
  report: {
    ...
  }
}
response:
200 {}

*/
