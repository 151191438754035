import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import "./styles/material-ui/index.css";
import App from "./App";
import TimeoutProvider from "./contexts/Timeout";
import PageProvider from "./contexts/Page";
import FormDataProvider from "./contexts/FormData";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ConnectionProvider from "./contexts/Connection";
import PaymentProvider from "./contexts/PaymentContext";
import { color, palette } from "@mui/system";
const theme = createTheme({
  palette: {
    primary: {
      main: "#22438",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#222438 ",
          color: "white",
          "&:hover": {
            backgroundColor: "#222438",
          },
        }
      }
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <PageProvider>
        <TimeoutProvider>
          <FormDataProvider>
            <ConnectionProvider>
              <PaymentProvider>
                <App />
              </PaymentProvider>
            </ConnectionProvider>
          </FormDataProvider>
        </TimeoutProvider>
      </PageProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
