import React from "react";
import FormTemplate from "../../../../common/templates";

export default function Start() {
  return (
    <FormTemplate
      templateName="report-type-selection"
      infoBoxProps={{
        title: "Select Report Type",
        text: (
          <>
            Your Information is required in order for police to investigate and
            communicate with you. Your information will never be shared if you
            choose to remain anonymous. This report will be reported to police
            only. If you wish for your school to be notified, please advise us
            or your school directly.
          </>
        ),
        color: "red",
      }}
    />
  );
}
