import React from "react";
import InfoBox from "../../../../common/InfoBox";
import FormTemplate from "../../../../common/templates";
import RadioField from "../../../../form-fields/RadioField";
import styles from "./Start.module.css";

export default function Start({ step }) {
  switch (step) {
    case "0":
      return (
        <div>
          <FormTemplate templateName="report-type-selection" formKey="meta" />
        </div>
      );
    case "1":
      return (
        <div>
          <FormTemplate templateName="person-type-selection" formKey="meta" />
        </div>
      );
    case "2":
      return (
        <div>
          <ReportingPersonInformationSelection formKey="info.trafficComplaint" />
        </div>
      );
    default:
      return null;
  }
}

function ReportingPersonInformationSelection({
  formKey = "start",
  infoBoxProps = {
    title: "Enter Reporting Person Information",
    text: "Your personal information is not required, however it can be helpful if we need to contact you to clarify a question or to resolve what you are reporting.",
  },
}) {
  return (
    <div className="page-root">
      <InfoBox {...infoBoxProps} />
      <div className={styles.fields}>
        <div className={styles.field}>
          <RadioField
            name={`${formKey}.willEnterPersonInfo`}
            type="radio"
            value="yes"
            style={{
              width: "100%",
              padding: "0.3rem 1rem",
            }}
            label="I would like to ENTER reporting person information."
          />
        </div>
        <div className={styles.field}>
          <RadioField
            name={`${formKey}.willEnterPersonInfo`}
            type="radio"
            value="no"
            style={{
              width: "100%",
              padding: "0.3rem 1rem",
            }}
            label="I would like to SKIP reporting person information."
          />
        </div>
      </div>
    </div>
  );
}
