import React, { useContext, useEffect } from "react";
import { PageContext } from "../../../../../contexts/Page";
import { FormDataContext } from "../../../../../contexts/FormData";
import FormBase from "../../../../common/FormBase";
import Start from "./Start";
import Yourself from "./Yourself";
import Incident from "./Incident";
import Property from "./Property";
import Narrative from "./Narrative";
import Review from "./Review";
import * as validation from "./validation";
import FinishBase from "../../../../common/FinishBase";

export default function Index() {
  const { page, setPages } = useContext(PageContext);
  const { formData, setFormData } = useContext(FormDataContext);

  useEffect(() => {
    setPages([
      "Start:0",
      "Start:1",
      "Start:2",
      "Yourself",
      "Incident",
      "Property",
      "Narrative",
      "Review",
      "Finish",
    ]);

    setFormData((formData) => ({
      meta: {
        ...formData.meta,
        reportType: "Original",
        reporterType: "Individual",
        originalReportNumber: "",
      },
      start0: {
        isEmergency: "",
        haveValidEmailAddress: "",
        haveKnownSuspects: "",
        damageWasOver5000: "",
        wasWithinCobourg: "",
      },
      reporter: {
        firstName: "",
        lastName: "",
        homeAddress: {
          country: "Canada",
          streetNumber: "",
          streetName: "",
          streetType: "",
          postDirection: "",
          apartment: "",
          city: "",
          province: "Ontario",
          zipCode: "",
        },
        contactPhoneNumber: "",
        email: "",
        confirmEmail: "",
        gender: "",
        employerName: "",
        workPhone: "",
        workAddress: {
          country: "Canada",
          streetNumber: "",
          streetName: "",
          streetType: "",
          postDirection: "",
          apartment: "",
          city: "",
          province: "Ontario",
          zipCode: "",
        },
        dateOfBirth: null,
        driversLicense: {
          number: "",
          provider: "",
        },
      },
      info: {
        damageToProperty: {
          streetAddress: {
            country: "Canada",
            streetNumber: "",
            streetName: "",
            streetType: "",
            postDirection: "",
            apartment: "",
            city: "",
            province: "Ontario",
            zipCode: "",
          },
          startTime: null,
          endTime: null,
          havePropertyInfo: "yes",
          graffitiInvolved: "",
          locationType: "",
          property: [
            {
              propertyType: "",
              subType: "",
              brand: "",
              model: "",
              colour: "",
              serialNo: "",
              quantity: "",
              damagedValue: "",
              description: "",
            },
          ],
          narrative: "",
        },
      },

      incident: {
        photographs: [],
      },
    }));
  }, []);

  if (!Object.keys(formData).length) return null;

  return (
    <>
      {page === "Start:0" && (
        <FormBase
          validationSchema={validation.start0}
          initialValues={{ start0: formData.start0 }}
          validateOnChange
        >
          {() => <Start step="0" />}
        </FormBase>
      )}
      {page === "Start:1" && (
        <FormBase
          validationSchema={validation.metaStart1}
          initialValues={{ meta: formData.meta }}
        >
          {() => <Start step="1" />}
        </FormBase>
      )}
      {page === "Start:2" && (
        <FormBase
          validationSchema={validation.metaStart2}
          initialValues={{ meta: formData.meta }}
        >
          {() => <Start step="2" />}
        </FormBase>
      )}
      {page === "Yourself" && (
        <>
          {formData.meta?.reporterType === "Individual" && (
            <FormBase
              validationSchema={validation.individualYourself}
              initialValues={{ reporter: formData.reporter }}
            >
              {() => <Yourself />}
            </FormBase>
          )}
          {formData.meta?.reporterType === "Business" && (
            <FormBase
              validationSchema={validation.businessYourself}
              initialValues={{ reporter: formData.reporter }}
            >
              {() => <Yourself />}
            </FormBase>
          )}
        </>
      )}
      {page === "Incident" && (
        <FormBase
          validationSchema={validation.incident}
          initialValues={formData}
        >
          {() => <Incident />}
        </FormBase>
      )}
      {page === "Property" && (
        <FormBase
          validationSchema={validation.property}
          initialValues={formData}
        >
          {() => <Property />}
        </FormBase>
      )}
      {page === "Narrative" && (
        <FormBase
          validationSchema={validation.narrative}
          initialValues={{
            info: formData.info,
          }}
        >
          {() => <Narrative />}
        </FormBase>
      )}
      {page === "Review" && (
        <FormBase initialValues={{}}>
          {() => <Review formValues={formData} />}
        </FormBase>
      )}
      {page === "Finish" && (
        <FormBase initialValues={{}}>{() => <FinishBase />}</FormBase>
      )}
    </>
  );
}
