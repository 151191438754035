import * as Yup from "yup";
import { incidentBaseValidationSchema } from "../../../../common/incidentBaseValidation";
import { narrativeBaseValidationSchema } from "../../../../common/narrativeBaseValidation";
import {
  reportTypeSelectionValidationSchema,
  personTypeSelectionValidationSchema,
  individualYourselfValidationSchema,
  businessYourselfValidationSchema,
} from "../../../../common/templates/validation";

const requiredErrorMsg = "You must fill all required fields.";

// export const start0 = Yup.object({
//   start0: reportTypeSelectionValidationSchema,
// });

export const metaStart0 = Yup.object({
  meta: reportTypeSelectionValidationSchema,
});

export const metaStart1 = Yup.object({
  meta: personTypeSelectionValidationSchema,
});

export const metaStart2 = Yup.object({
  info: Yup.object({
    trafficComplaint: Yup.object({
      willEnterPersonInfo: Yup.string().required().oneOf(["yes", "no"]),
    }),
  }),
});

export const individualYourself = Yup.object({
  reporter: individualYourselfValidationSchema,
});

export const businessYourself = Yup.object({
  reporter: businessYourselfValidationSchema,
});

export const incident = Yup.object({
  info: Yup.object({
    trafficComplaint: Yup.object({
      locationType: Yup.string().required(requiredErrorMsg),
    }).concat(incidentBaseValidationSchema),
  }),
});

export const vehicle = Yup.object({
  info: Yup.object({
    trafficComplaint: Yup.object({
      vehicle: Yup.object({
        involvementType: Yup.string().required(requiredErrorMsg),
        maker: Yup.string(),
        model: Yup.string(),
        styles: Yup.string(),
        year: Yup.string(),
        colours: Yup.string(),
        licenseType: Yup.string(),
        licensePlateNo: Yup.string(),
        licenseLocation: Yup.string(),
        vehicleDescription: Yup.string().required(requiredErrorMsg),
      }),
    }),
  }),
});

export const narrative = Yup.object({
  info: Yup.object({
    trafficComplaint: narrativeBaseValidationSchema,
  }),
});
