import { Chip } from "@mui/material";
import React, { useContext } from "react";
import { ConnectionContext } from "../../../../../contexts/Connection";
import InfoBox from "../../../../common/InfoBox";
import FileField from "../../../../form-fields/FileField";
import styles from "./Incident.module.css";

export default function Incident() {
  const { loadingMessage } = useContext(ConnectionContext);
  return (
    <div className="page-root">
      <InfoBox
        title="Attach photographs of your bicycle (s)."
        text="If you have photographs of the bicycle(s) to be registered please attach here."
      />
      <div className={styles.form}>
        <FileField
          name="incident.photographs"
          accept="image/*, video/*, audio/*, application/pdf"
          label="Document Upload"
          renderValue={(files, handleDelete) => [
            ...files.map((file) => (
              <Chip
                key={file?.id}
                label={file?.filename}
                className={styles.chip + " shadow"}
                onDelete={() => handleDelete(file?.id)}
              />
            )),
            loadingMessage && (
              <Chip
                key="dummy chip"
                label="Loading file(s)"
                className={styles.chip + " shadow"}
              />
            ),
          ]}
        />
      </div>
    </div>
  );
}
