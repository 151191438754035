import React, { useContext, useEffect } from "react";
import { PageContext } from "../../../../../contexts/Page";
import { FormDataContext } from "../../../../../contexts/FormData";
import FormBase from "../../../../common/FormBase";
import Yourself from "./PersonalInfo/Yourself";
import * as validation from "./validation";
import Intro from "../foi-report/IntroScreen/Intro";
import Question from "./QuestionScreen/Question";
import Verification from "./VerificationScreen/Verification";
import Review from "./ReviewScreen/Review";
import FinishBase from "../../../../common/FinishBase";
import MultiLineTextField from "../../../../form-fields/MultiLineTextField";

export default function Index() {

  const { page, setPages } = useContext(PageContext);
  const { formData, setFormData } = useContext(FormDataContext);
  console.log(formData);
  useEffect(() => {
    setFormData({
      meta: {
        schemaVersion: "2.0.0",
        region: "Cobourg",
        incidentType: "Freedom of Information",
        emailOnly: true,
      },
      // recaptcha: values.recaptcha  
    });
  }, []);

  useEffect(() => {
    console.log(formData);
    setPages([
      "Intro:0",
      "Intro:1",
      "Yourself:0",
      "Yourself:1",
      "Yourself:2",
      "Yourself:3",
      "Narrative",
      "Verification:0",
      "Verification:1",
      "Review:0",
      "Review:1",
      "Finish",

    ]);
    setFormData((formData) => ({
      meta: {
        ...formData.meta,
        reportType: "Original",
        reporterType: "Individual",
        originalReportNumber: "",
      },
      reporter: {
        firstName: "",
        lastName: "",
        middleName: "",
        phoneNumber: "",
        otherNumber: "",
        email: "",
        confirmEmail: "",
        gender: "",
        dateOfBirth: null,
        homeAddress: {
          country: "Canada",
          unitNumber: "",
          streetNumber: "",
          streetName: "",
          streetType: "",
          postDirection: "",
          apartment: "",
          city: "",
          province: "Ontario",
          zipCode: "",
        },

      },
      info: {
        adminreport: {
          foireport:
          {
            request: "",
            contactIndivisual: "",
            releaseIdentity: "",
            narrative: "",
            methodAccess: "",
            signature: "",
            photoFace: "",
            photoID: "",
            photoBoth: "",

          },

        },
      },
    }));
  }, []);


  return (

    <>
      {console.log(formData)}
      {page === "Intro:0" && (
        <FormBase
          validationSchema={validation.intro0}
          initialValues={{ info: formData.info }}
        >
          {() => <Intro step="0" />}
        </FormBase>
      )
      }
      {page === "Intro:1" && (
        <FormBase
          validationSchema={validation.intro1}
          initialValues={{ info: formData.info }}
        >
          {() => <Intro step="1" />}
        </FormBase>
      )
      }
      {
        page === "Yourself:0" && (
          <FormBase
            validationSchema={validation.yourself0}
            initialValues={{ reporter: formData.reporter }}
          >
            {() => <Yourself step="0" />}
          </FormBase>
        )
      }
      {
        page === "Yourself:1" && (
          <FormBase
            validationSchema={validation.yourself1}
            initialValues={{ reporter: formData.reporter }}
          >
            {() => <Yourself step="1" />}
          </FormBase>
        )
      }
      {
        page === "Yourself:2" && (

          <FormBase
            validationSchema={validation.yourself3}
            initialValues={{
              reporter: formData.reporter
            }}>
            {() => <Yourself step="2" />}
          </FormBase>
        )
      }
      {
        page === "Yourself:3" && (

          <FormBase
            validationSchema={validation.yourself2}
            initialValues={{
              reporter: formData.reporter
            }}>
            {() => <Yourself step="3" />}
          </FormBase>
        )
      }
      {
        page === "Question" && (

          <FormBase
            // validationSchema={validation.reporter}
            initialValues={{
              info: formData.info
            }}>
            {() => <Question />}
          </FormBase>
        )
      }
      {
        //MIGHT HAVE TO CHANGE BELOW
        page === "Narrative" && (

          <FormBase
            // validationSchema={validation.reporter}
            initialValues={{
              info: formData.info,
            }}>
            {() => <MultiLineTextField title={"Carefully explain in detail what record(s) you are requesting access:"} tooltip={"names of individuals involved, dates, times, location, incident numbers,officers name and badge number"} characterCount={5000} formKey={"info.adminreport.foireport"}/>}
          </FormBase>
        )
      }
      {
        page === "Verification:0" && (

          <FormBase
            validationSchema={validation.verification0}
            initialValues={{ info: formData.info }}>
            {() => <Verification step={"0"} />}
          </FormBase>
        )
      }
      {
        page === "Verification:1" && (

          <FormBase
            validationSchema={validation.verification1}
            initialValues={{ info: formData.info }}>
            {() => <Verification step={"1"} />}
          </FormBase>
        )
      }
      {page === "Review:0" && (
        <FormBase initialValues={{}}>
          {() => <Review formData={formData} step={"0"} />}
        </FormBase>
      )}
      {page === "Review:1" && (
        <FormBase initialValues={{}}>
          {() => <Review formData={formData} step={"1"} />}
        </FormBase>
      )}
      {page === "Finish" && (
        <FormBase initialValues={{}}>
          {() => <FinishBase />}
        </FormBase>
      )}



    </>
  );
}
