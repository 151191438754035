import InfoBox from "./InfoBox";

export default function FinishBase() {
  return (
    <div className="page-root">
      <InfoBox
        title="Finish Report"
        text={
          <>
            Thank you for reporting. Reporting online helps Cobourg Police Service better respond to incidents and keep the community safe. <br/>
            Please CHECK YOUR INBOX for confirmation of submission from <b>no-reply@tryrubicon.com</b>. Please check spam and junk. If no email is received within 5 minutes please call non-emergency number: 905-372-6821. Once the report has been approved you will receive a confirmation email.{" "}
          </>
        }
      />
    </div>
  );
}
