import { FormControl } from "@mui/material";
import { Field, ErrorMessage } from "formik";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


export default function SelectDateTime({
  sx = {},
  hasTime = true,
  ...props
}) {
  const { name } = props;

  return (
    <FormControl sx={{ minWidth: 85, ...sx }} fullWidth>
      <Field name = {name}>
        {
          ({form, field}) => {
            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {!hasTime && <DatePicker
                  name={field.name}
                  value={field.value}
                  onChange={date => form.setFieldValue(field.name, date, false)}
                  renderInput={(params) => <TextField {...params} />}
                  label="mm/dd/yyyy"
                  maxDate={new Date()}
                />}
                {hasTime && <DateTimePicker
                  name={field.name}
                  value={field.value}
                  onChange={date => form.setFieldValue(field.name, date, false)}
                  renderInput={(params) => <TextField {...params} />}
                  label="mm/dd/yyyy hh:mm"
                  ampm={false}
                />}
              </LocalizationProvider>
            )
          }
        }
      </Field>
      {/* <ErrorMessage name={name} /> */}
    </FormControl>
  );
}
