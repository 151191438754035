import React from "react";
import TextInputField from "../../../../form-fields/TextInputField";
import styles from "./Fields.module.css";
import SelectField from "../../../../form-fields/SelectField";
import {
  eyeColors,
  involvementTypes,
  hairColors,
  footList,
  inchList,
} from "../../../../common/personBaseValidation";

export { InvolvementType, AgeHeight, EyeHairColor, FacialHairStyle };

function InvolvementType({ formKey, index }) {
  return (
    <div className={[styles.root, styles.column].join(" ")}>
      <div className={styles.fields}>
        <SelectField
          name={`${formKey}.${index}.involvementType`}
          label={
            <>
              <span style={{ color: "red" }}>* </span> Involvement Type
            </>
          }
          options={involvementTypes.map((invType) => ({
            value: invType,
            label: invType,
          }))}
          sx={{ width: 0.5 }}
        />
      </div>
    </div>
  );
}

function AgeHeight({ formKey, index }) {
  return (
    <div className={styles.root}>
      <TextInputField
        name={`${formKey}.${index}.age`}
        label={"Age"}
        sx={{ width: 1 / 4 }}
      />
      <div style={{ width: "25%" }}>
        <p style={{ padding: "0 0.5rem", textAlign: "end" }}>Height</p>
      </div>
      <SelectField
        name={`${formKey}.${index}.height.foot`}
        options={footList.map((type) => ({
          value: type,
          label: type,
        }))}
        label="Foot"
        sx={{ width: 1 / 4 }}
      />
      <SelectField
        name={`${formKey}.${index}.height.inch`}
        options={inchList.map((type) => ({
          value: type,
          label: type,
        }))}
        label="Inch"
        sx={{ width: 1 / 4 }}
      />
    </div>
  );
}

function EyeHairColor({ formKey, index }) {
  return (
    <div className={styles.root}>
      <SelectField
        name={`${formKey}.${index}.eyeColor`}
        options={eyeColors.map((type) => ({
          value: type,
          label: type,
        }))}
        label="Eye Color"
        sx={{ width: 1 / 2 }}
      />
      <SelectField
        name={`${formKey}.${index}.hairColor`}
        options={hairColors.map((type) => ({
          value: type,
          label: type,
        }))}
        label="Hair Color"
        sx={{ width: 1 / 2 }}
      />
    </div>
  );
}

function FacialHairStyle({ formKey, index }) {
  return (
    <div className={styles.root}>
      <TextInputField
        name={`${formKey}.${index}.hairStyle`}
        label={"Hair Style"}
        sx={{ width: 1 / 2 }}
      />
      <TextInputField
        name={`${formKey}.${index}.facialHair`}
        label={"Facial Hair"}
        sx={{ width: 1 / 2 }}
      />
    </div>
  );
}
