import React, { useContext } from "react";
import { FormDataContext } from "../../../contexts/FormData";
import IncidentTypeSelection from "./components/IncidentTypeSelection";
import BicycleRegistration from "./components/bicycle-registration";
import Bullying from "./components/bullying";
import DamageToProperty from "./components/damage-to-property";
import DamageToVehicle from "./components/damage-to-vehicle";
import LostProperty from "./components/lost-property";
import TheftFromVehicle from "./components/theft-from-vehicle";
import TheftOfGas from "./components/theft-of-gas";
import TheftUnder5000 from "./components/theft-under-5000";
import TrafficComplaint from "./components/traffic-complaint";

export default function Main() {
  const { formData } = useContext(FormDataContext);
  console.log(formData);
  switch (formData.meta.incidentType) {
    case "Bicycle Registration":
      return (
        <main>
          <BicycleRegistration />
        </main>
      );
    case "Bullying":
      return (
        <main>
          <Bullying />
        </main>
      );
    case "Damage/Mischief to Property":
      return (
        <main>
          <DamageToProperty />
        </main>
      );
    case "Damage/Mischief to Vehicle":
      return (
        <main>
          <DamageToVehicle />
        </main>
      );
    case "Lost Property":
      return (
        <main>
          <LostProperty />
        </main>
      );
    case "Theft from Vehicle":
      return (
        <main>
          <TheftFromVehicle />
        </main>
      );
    case "Theft of Gas":
      return (
        <main>
          <TheftOfGas />
        </main>
      );
    case "Theft Under $5,000":
      return (
        <main>
          <TheftUnder5000 />
        </main>
      );
    case "Traffic Complaint":
      return (
        <main>
          <TrafficComplaint />
        </main>
      );
    default:
      return (
        <main>
          <IncidentTypeSelection />
        </main>
      );
  }
}
