import React from "react";
import TextInputField from "../../../../../form-fields/TextInputField";
import styles from "../../../../../common/yourself-components/FirstMiddleLast.module.css"
import SelectField from "../../../../../form-fields/SelectField";
export default function ContactInformation({
    formKey = "reporter",
    index = null,
    required = true,
}) {
    if (index !== null) {
        formKey = formKey + "." + index;
    }

    return (
        <div>
            <div className={styles.column}>
                <label className={styles.name}>Gender</label>
                <SelectField
                    className={styles.textFieldStyle}
                    name={`${formKey}.gender`}
                    label={
                        <>
                            {required && (
                                <span style={{ color: "red" }}>* </span>
                            )}{" "}

                        </>
                    }
                    options={gender_options}
                    
                />
            </div>
            <div className={styles.column}>
                <label className={styles.name}>Phone Number</label>
                <TextInputField
                    className={styles.textFieldStyle}
                    name={`${formKey}.phoneNumber`}
             
                    label={
                        <>{required && <span style={{ color: "red" }}>* </span>} </>
                    }
                    format={(currentValue) => {
                        if (!currentValue) return currentValue;
                        let value = currentValue.split("");
                        if (value.length > 3 && value[3] !== "-") value.splice(3, 0, "-");
                        if (value.length > 7 && value[7] !== "-") value.splice(7, 0, "-");
                        value = value.join("");
                        return value;
                    }}

                />


            </div>
            <div className={styles.column}>
                <label className={styles.name}>Other Phone Number</label>
                <TextInputField
                    className={styles.textFieldStyle}
                    name={`${formKey}.otherNumber`}
                 
                    label={
                        <>{required && <span style={{ color: "red" }}>* </span>} </>
                    }
                    format={(currentValue) => {
                        if (!currentValue) return currentValue;
                        let value = currentValue.split("");
                        if (value.length > 3 && value[3] !== "-") value.splice(3, 0, "-");
                        if (value.length > 7 && value[7] !== "-") value.splice(7, 0, "-");
                        value = value.join("");
                        return value;
                    }}

                />

            </div>
            <div className={styles.column}>
                <label className={styles.name}>Email</label>
                <TextInputField
                    className={styles.textFieldStyle}
                    name={`${formKey}.email`}
                   
                    label={
                        <>{required && <span style={{ color: "red" }}>* </span>} </>
                    }
                    
                />
            </div>


        </div>
    );
}
const genderList = [
    "Female",
    "Male",
    "Transgender/Non Binary",
    "Other",
    "Prefer not to answer",
];

const gender_options = genderList.map((type) => ({
    value: type,
    label: type,
}));

export { genderList, gender_options };

