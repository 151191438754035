import React from "react";
import styles from "./IntroBase.module.css";

export default function IntroBase({ children }) {
    return (
        <div className="page-root">
            <div className={styles.fields + " shadow"}>{children}</div>
        </div>
    );
}
