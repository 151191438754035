import React from "react";
import CobourgFormFlow from "./components/cobourgPS-formflow";
import ErrorChip from "./components/common/ErrorChip";
import LoadingChip from "./components/common/LoadingChip";
import TimeoutTracker from "./components/common/TimeoutTracker";
import Main from "./components/adminforms/main/Main";
import StepperCard from "./components/common/StepperCard";
import Header from "./components/common/Header";

function subdomainSearchConfigs(map) {
  let main = map.find((item) => item.main);
  if (!main) {
    throw new Error("Must set main flag to true on at least one subdomain app");
  }

  return function getComponent() {
    const parts = window.location.hostname.split(".");

    let last_index = -2;
    const last = parts[parts.length - 1];
    const is_localhost = last === "localhost";
    if (is_localhost) {
      last_index = -1;
    }

    const subdomain = parts.slice(0, last_index).join(".");

    if (!subdomain) {
      return main;
    }

    const policeForm = map.find(({ subdomains }) =>
      subdomains.includes(subdomain)
    );
    console.log(policeForm);
    if (policeForm) {
      return policeForm;
    } else {
      return main;
    }
  };
}

const getPoliceConfigs = subdomainSearchConfigs([
  {
    subdomains: [
      "cobourgpolice.report",
      "test.cobourgpolice.report",
    ],
    application: function () {
      return <CobourgFormFlow />;
    },
    main: true,
    websiteURL: "https://cobourgpoliceservice.com/",
    name: "Cobourg Police Service",
    logo: "logo.png",
    logoText: "logoText.png",
  },
  {
    subdomains: ["cobourgpolice.kiosk", "test.cobourgpolice.kiosk"],
    application: function () {
      //point to foi
      return <Main />;
    },
    // main: true,
    websiteURL: "https://cobourgpoliceservice.com/",
    name: "Cobourg Police Service",
    logo: "logo.png",
    logoText: "logoText.png",
  },
]);

function App() {
  const PoliceConfig = getPoliceConfigs();
  console.log(PoliceConfig);

  return (
    <div id="app">
      <Header
        websiteURL={PoliceConfig.websiteURL}
        name={PoliceConfig.name}
        logo={PoliceConfig.logo}
        logoText={PoliceConfig.logoText}
        showReportTitle={true}
      />
      <div id="formWrapper">
        <div id={"form-container"}>
          <PoliceConfig.application />
          <TimeoutTracker />
          <LoadingChip />
          <ErrorChip />
        </div>
        <StepperCard></StepperCard>
      </div>
    </div>
  );
}

export default App;
