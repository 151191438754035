import * as Yup from "yup";
import { incidentBaseValidationSchema } from "../../../../common/incidentBaseValidation";
import { propertyBaseValidationSchema } from "../../../../common/propertyBaseValidation";
import { narrativeBaseValidationSchema } from "../../../../common/narrativeBaseValidation";
import {
  individualYourselfValidationSchema,
  businessYourselfValidationSchema,
  personTypeSelectionValidationSchema,
  reportTypeSelectionValidationSchema,
} from "../../../../common/templates/validation";

const requiredErrorMsg = "You must fill all required fields.";

export const start0 = Yup.object({
  start0: Yup.object({
    isEmergency: Yup.string()
      .required("All questions must be answered.")
      .matches(/no/, "If this is an Emergency call 911."),
    haveValidEmailAddress: Yup.string()
      .required("All questions must be answered.")
      .matches(
        /yes/,
        "You must have a valid email address to file a report online."
      ),
    haveKnownSuspects: Yup.string()
      .required("All questions must be answered.")
      .matches(
        /no/,
        "Please call the Cobourg Police Service by phone at 905-372-6821 to file your police report. Only reports without suspects can be filed online."
      ),
    damageWasOver5000: Yup.string()
      .required("All questions must be answered.")
      .matches(
        /no/,
        "Please call the Cobourg Police Service by phone at 905-372-6821 to file your police report. Only reports less than $5,000.00 in damage value can be filed online."
      ),
    wasWithinCobourg: Yup.string()
      .required("All questions must be answered.")
      .matches(
        /yes/,
        "If the damage or mischief occurred outside Cobourg, please contact the police service responsible for that area."
      ),
  }),
});

export const metaStart1 = Yup.object({
  meta: reportTypeSelectionValidationSchema,
});

export const metaStart2 = Yup.object({
  meta: personTypeSelectionValidationSchema,
});

export const individualYourself = Yup.object({
  reporter: individualYourselfValidationSchema,
});

export const businessYourself = Yup.object({
  reporter: businessYourselfValidationSchema,
});

export const incident = Yup.object({
  info: Yup.object({
    damageToProperty: Yup.object({
      havePropertyInfo: Yup.string().required(requiredErrorMsg),
      locationType: Yup.string().required(requiredErrorMsg),
      graffitiInvolved: Yup.string().required(requiredErrorMsg),
    }).concat(incidentBaseValidationSchema),
  }),
});

export const property = Yup.object().shape({
  info: Yup.object({
    damageToProperty: Yup.object({
      property: Yup.array().of(propertyBaseValidationSchema),
    }),
  }),
});

export const narrative = Yup.object({
  info: Yup.object({
    damageToProperty: narrativeBaseValidationSchema,
  }),
});
