import React from "react";
import InfoBox from "../../../../common/InfoBox";
import styles from "./Property.module.css";
import * as Fields from "./Fields";
import { FieldArray } from "formik";
import { useField } from "formik";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function Property({ formKey = "info" }) {
  const [field, meta, helpers] = useField(formKey);
  const { value } = meta;
  const [open, setOpen] = React.useState([true, true]);

  const handleClick = (index) => () => {
    setOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      newOpen[index] = !newOpen[index];
      return newOpen;
    });
  };

  return (
    <div className="page-root">
      <InfoBox
        title="Enter Bicycle(s) Information"
        text="Please enter bicycle description"
      />
      <div className={styles.fields + " shadow"}>
        <FieldArray
          name="info.bicycleRegistration.bicycles"
          render={(arrayHelpers) => (
            <div>
              {value.bicycleRegistration.bicycles.length > 0 &&
                value.bicycleRegistration.bicycles.map((property, index) => (
                  <div key={index}>
                    <div className={styles.expandButton} value={index}>
                      <p
                        onClick={handleClick(index)}
                        className={styles.boxTitle}
                      >
                        Bicycle {index + 1}
                      </p>
                      {open[index] ? (
                        <ExpandLess onClick={handleClick(index)} />
                      ) : (
                        <ExpandMore onClick={handleClick(index)} />
                      )}

                      <IconButton
                        onClick={() => {
                          if (value.bicycleRegistration.bicycles.length > 1) {
                            arrayHelpers.remove(index);
                            setOpen((prevOpen) => {
                              const newOpen = [...prevOpen];
                              newOpen.splice(index, 1);
                              return newOpen;
                            });
                          }
                        }}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    <Collapse in={open[index]} timeout="auto" unmountOnExit>
                      <Fields.BrandModel index={index} />
                      <Fields.TypeStyle index={index} />
                      <Fields.SpeedSize index={index} />
                      <Fields.ColourNumberValue index={index} />
                      <Fields.AdditionalDescrption index={index} />
                    </Collapse>
                  </div>
                ))}
              <Button
                fullWidth
                variant="outlined"
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  arrayHelpers.push({
                    brand: "",
                    model: "",
                    bicycleType: "",
                    style: "",
                    handleType: "",
                    seatStyle: "",
                    speed: "",
                    wheelSize: "",
                    colour: "",
                    serialNumber: "",
                    totalValue: "",
                    additionalDescription: "",
                  });
                  setOpen((prevOpen) => [...prevOpen, true]);
                }}
              >
                Add Bicycle
              </Button>
            </div>
          )}
        />
      </div>
    </div>
  );
}
