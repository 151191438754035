import React, { useContext } from "react";
import FormTemplate from "../../../../common/templates";
import { FormDataContext } from "../../../../../contexts/FormData";

export default function Yourself() {
  const { formData } = useContext(FormDataContext);

  switch (formData.meta.reporterType) {
    case "Individual":
      return <FormTemplate templateName="individual-yourself" />;
    case "Business":
      return <FormTemplate templateName="business-yourself" />;
    default:
      return null;
  }
}
