import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { PaymentForm } from './PaymentForm';
import { ConnectionContext } from '../../contexts/Connection';
import { PaymentContext } from '../../contexts/PaymentContext';
import config from '../../config';
console.log(config.STRIPE_PUBLIC_KEY);
const stripeTestPromise = loadStripe(config.STRIPE_PUBLIC_KEY);
export default function StripeContainer() {
    const [clientSecret, setClientSecret] = useState("");
    const { paymentIntent } = useContext(ConnectionContext);
    const { value } = useContext(PaymentContext);
    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        if (value) {

        }
        else {
            paymentIntent().then(data => setClientSecret(data['client_secret']));
        }

    }, []);
    return (
        <>
            {clientSecret && (
                <Elements stripe={stripeTestPromise}>
                    <PaymentForm secret={clientSecret} />
                </Elements>
            )}
        </>

    );
}