import React from "react";
import QuestionBase from "./QuestionBase";
import styles from "./Question.module.css";
import OptionRadioField from "../common/OptionRadioField";
export default function Question() {
    const values = ["Yes", "No"];
    return (
        <QuestionBase>
                {feeParagraph()}
                <OptionRadioField text={"Do you wish us to contact these individual to try and obtain their consent to disclose their information?"} formKey={"info.adminreport.foireport.contactIndivisual"} values={values}></OptionRadioField>
                <OptionRadioField text={"If yes, do you consent to our releasing YOUR identity to the individual we contact?"} formKey={"info.adminreport.foireport.releaseIdentity"} values={values}></OptionRadioField>
        </QuestionBase >

    );
}

function feeParagraph() {
    return <div className={styles.column}>
        <label className={styles.readTitle}>Please Read:</label>
        <p className={styles.readParagraph}>The record's you have requested may contain the personal information of individuals other than yourself (e.g. victim, accused,
            witness) Further to section 21 of MFIPPA, it may be necessary to notify affected individuals before making a decision on access.</p>
    </div>;
}