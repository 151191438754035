import React from "react";
import TextInputField from "../../form-fields/TextInputField";
import styles from "./FirstMiddleLast.module.css";
export default function FirstMiddleLast({
  formKey = "reporter",
  index = null,
  required = true,
}) {
  if (index !== null) {
    formKey = formKey + "." + index;
  }
  return (
    <div className={styles.body}>
      <div className={styles.column}>
        <div className={styles.row}>
          <label className={styles.name}>First Name </label>
        </div>
        <TextInputField
          className={styles.textFieldStyle}
          name={`${formKey}.firstName`}
          label={
            <>{required && <span style={{ color: "red" }}>* </span>} </>
          }
        />
      </div>
      <div className={styles.column}>
        <label className={styles.name}>Last Name</label>
        <TextInputField
          className={styles.textFieldStyle}
          name={`${formKey}.lastName`}
          label={
            <>{required && <span style={{ color: "red" }}>* </span>} </>
          }

        />

      </div>
      <div className={styles.column}>
        <label className={styles.name}>Middle Name</label>
        <TextInputField
          className={styles.textFieldStyle}
          name={`${formKey}.middleName`}
          label={
            <> {required = false} </>
          }
        />
      </div>
    </div >



  );
}
