import React, { useContext, useEffect } from "react";
import { PageContext } from "../../../../../contexts/Page";
import { FormDataContext } from "../../../../../contexts/FormData";
import FormBase from "../../../../common/FormBase";
import Start from "./Start";
import Yourself from "./Yourself";
import Incident from "./Incident";
import Person from "./Person";
import Vehicle from "./Vehicle";
import Narrative from "./Narrative";
import Review from "./Review";
import * as validation from "./validation";
import FinishBase from "../../../../common/FinishBase";

export default function Index() {
  const { page, pages, setPages } = useContext(PageContext);
  const { formData, setFormData } = useContext(FormDataContext);

  useEffect(() => {
    setPages([
      "Start:0",
      "Start:1",
      "Yourself",
      "Incident",
      "Person",
      "Vehicle",
      "Narrative",
      "Review",
      "Finish",
    ]);

    setFormData((formData) => ({
      meta: {
        ...formData.meta,
        reportType: "Original",
        reporterType: "Business",
        originalReportNumber: "",
      },
      start0: {
        wasWithinCobourg: "",
        isOwnerOrEmpolyee: "",
        haveAtLeastOnePhotograph: "",
      },
      reporter: {
        firstName: "",
        lastName: "",
        homeAddress: {
          country: "Canada",
          streetNumber: "",
          streetName: "",
          streetType: "",
          postDirection: "",
          apartment: "",
          city: "",
          province: "Ontario",
          zipCode: "",
        },
        contactPhoneNumber: "",
        email: "",
        confirmEmail: "",
        gender: "",
        employerName: "",
        workPhone: "",
        workAddress: {
          country: "Canada",
          streetNumber: "",
          streetName: "",
          streetType: "",
          postDirection: "",
          apartment: "",
          city: "",
          province: "Ontario",
          zipCode: "",
        },
        dateOfBirth: null,
        driversLicense: {
          number: "",
          provider: "",
        },
      },
      info: {
        theftOfGas: {
          streetAddress: {
            country: "Canada",
            streetNumber: "",
            streetName: "",
            streetType: "",
            postDirection: "",
            apartment: "",
            city: "",
            province: "Ontario",
            zipCode: "",
          },
          startTime: null,
          endTime: null,
          locationType: "",
          haveAdditionalInfo: "",
          involvedPersons: [],
          vehicle: {
            involvementType: "",
            maker: "",
            model: "",
            vIN: "",
            year: "",
            colours: "",
            licenseType: "",
            licensePlateNo: "",
            licenseLocation: "",
          },
          narrative: "",
        },
      },
      incident: {
        photographs: [],
      },
    }));
  }, [setFormData, setPages]);

  return (
    <>
      {page === "Start:0" && (
        <FormBase
          validationSchema={validation.start0}
          initialValues={{ start0: formData.start0 }}
          validateOnChange
        >
          {() => <Start step="0" />}
        </FormBase>
      )}
      {page === "Start:1" && (
        <FormBase
          validationSchema={validation.metaStart1}
          initialValues={{ meta: formData.meta }}
        >
          {() => <Start step="1" />}
        </FormBase>
      )}
      {page === "Yourself" && (
        <FormBase
          validationSchema={validation.businessYourself}
          initialValues={{ reporter: formData.reporter }}
        >
          {() => <Yourself />}
        </FormBase>
      )}
      {page === "Incident" && (
        <FormBase
          validationSchema={validation.incident}
          initialValues={formData}
          submitCallback={(values) => {
            let willEnterPersonInfo = values.info.theftOfGas.haveAdditionalInfo;

            if (willEnterPersonInfo === "yes" && !pages.includes("Person")) {
              setPages((pages) => [
                ...pages.slice(0, 4),
                "Person",
                ...pages.slice(4),
              ]);
              return;
            }
            if (willEnterPersonInfo === "no" && pages.includes("Person")) {
              setPages((pages) => pages.filter((page) => page !== "Person"));
            }
          }}
        >
          {() => <Incident />}
        </FormBase>
      )}

      {page === "Person" && (
        <FormBase
          validationSchema={validation.personsDecriptions}
          initialValues={formData}
        >
          {() => <Person />}
        </FormBase>
      )}
      {page === "Vehicle" && (
        <FormBase
          validationSchema={validation.vehicle}
          initialValues={{ info: formData.info }}
        >
          {() => <Vehicle />}
        </FormBase>
      )}
      {page === "Narrative" && (
        <FormBase
          validationSchema={validation.narrative}
          initialValues={{
            info: formData.info,
          }}
        >
          {() => <Narrative />}
        </FormBase>
      )}
      {page === "Review" && (
        <FormBase initialValues={{}}>
          {() => <Review formValues={formData} />}
        </FormBase>
      )}
      {page === "Finish" && (
        <FormBase initialValues={{}}>{() => <FinishBase />}</FormBase>
      )}
    </>
  );
}
