import * as Yup from "yup";
import { genderList } from "../../../../common/yourself-components/Gender";
import { narrativeBaseValidationSchema } from "../../../../common/narrativeBaseValidation";

const requiredErrorMsg = "Please fill all required fields.";
const bicycleTypes = [
  "Air Pump",
  "Antique",
  "Bmx",
  "Cross Country",
  "Downhill",
  "Dual Slalom",
  "Folding",
  "Freestyle",
  "Mountain",
  "Racing",
  "Recumbent",
  "Sidewalk",
  "Tandem",
  "Touring",
  "Tricycle - Adult",
  "Tricycle - Child",
  "Unicycle",
  "Other",
];
const bicycleStyles = ["Boys", "Childs", "Girls", "Mens", "Womens"];
const bicycleHandleTypes = [
  "Bullhorn",
  "Downhill",
  "Highrise",
  "Monkey",
  "Mountain",
  "Racing",
  "Regular",
  "Other",
];
const bicycleSeatStyles = [
  "Banana",
  "Female custom",
  "Gel",
  "Male custom",
  "Racing",
  "Regular",
  "Other",
];
const colours = [
  "Beige",
  "Black",
  "Blue",
  "Blue-dark",
  "Blue-ligh",
  "Bronze",
  "Brown",
  "Copper",
  "Cream-ivory",
  "Gold",
  "Green",
  "Green-dark",
  "Green-light",
  "Grey",
  "Maroon",
  "Orange",
  "Pink",
  "Purple-mauve",
  "Red",
  "Silv., alum., st. steel",
  "Tan",
  "Turquoise",
  "White",
  "Yellow",
  "Other",
];

const reporter = Yup.object({
  reporter: Yup.object({
    firstName: Yup.string().required(requiredErrorMsg).max(20),
    lastName: Yup.string().required(requiredErrorMsg).max(20),
    homeAddress: Yup.object({
      country: Yup.string().required(requiredErrorMsg),
      streetNumber: Yup.string().max(30),
      streetName: Yup.string().required(requiredErrorMsg).max(100),
      streetType: Yup.string(),
      postDirection: Yup.string().oneOf(["E", "W", "S", "N"]),
      apartment: Yup.string().max(30),
      city: Yup.string().required(requiredErrorMsg).max(30),
      province: Yup.string().required(requiredErrorMsg),
      zipCode: Yup.string().required(requiredErrorMsg).max(20),
    }),
    contactPhoneNumber: Yup.string()
      .required(requiredErrorMsg)
      .max(12)
      .matches(
        /[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
        "Home phone is invalid."
      ),
    email: Yup.string()
      .email("Invalid Email.")
      .required(requiredErrorMsg)
      .max(50),
    confirmEmail: Yup.string()
      .required(requiredErrorMsg)
      .oneOf([Yup.ref("email")], "Confirm Email has to match Email."),
    gender: Yup.string().required(requiredErrorMsg).oneOf(genderList),
    dateOfBirth: Yup.date().required(requiredErrorMsg).nullable().typeError("Invalid Date"),
  }),
});

const bicyclesReportInfo = Yup.object().shape({
  info: Yup.object({
    bicycleRegistration: Yup.object({
      bicycles: Yup.array().of(
        Yup.object({
          brand: Yup.string().required(requiredErrorMsg).max(20),
          model: Yup.string().required(requiredErrorMsg).max(20),
          bicycleType: Yup.string()
            .oneOf(bicycleTypes)
            .required(requiredErrorMsg),
          style: Yup.string().oneOf(bicycleStyles),
          handleType: Yup.string().oneOf(bicycleHandleTypes),
          seatStyle: Yup.string().oneOf(bicycleSeatStyles),
          speed: Yup.number()
            .typeError("Bicycle speed must be a positive number")
            .positive("Bicycle speed must be a positive number"),
          wheelSize: Yup.number()
            .typeError("Bicycle wheel size must be a positive number")
            .positive("Bicycle wheel size must be a positive number"),
          colour: Yup.string().oneOf(colours).required(requiredErrorMsg),
          serialNumber: Yup.string().required(requiredErrorMsg).max(30),
          totalValue: Yup.number()
            .typeError("This Should be a number (e.g. 142)")
            .required(requiredErrorMsg)
            .positive("Bicycle total must be a positive number"),
          additionalDescription: Yup.string().max(400),
        })
      ),
      narrativeBaseValidationSchema,
    }),
  }),
});

export const narrative = narrativeBaseValidationSchema;

export {
  reporter,
  bicyclesReportInfo,
  bicycleTypes,
  bicycleStyles,
  bicycleHandleTypes,
  bicycleSeatStyles,
  colours,
};
