import * as Yup from "yup";

const requiredErrorMsg = "You must fill all required fields.";

export const personBaseValidationSchema = Yup.object({
  involvementType: Yup.string().required(requiredErrorMsg),
  firstName: Yup.string().max(30),
  lastName: Yup.string().max(30),
  homeAddress: Yup.object({
    country: Yup.string(),
    streetNumber: Yup.string().max(30),
    streetName: Yup.string().max(100),
    streetType: Yup.string(),
    postDirection: Yup.string().oneOf(["E", "W", "S", "N"]),
    apartment: Yup.string().max(30),
    city: Yup.string().max(30),
    province: Yup.string(),
    zipCode: Yup.string().max(20),
  }),
  contactPhoneNumber: Yup.string().matches(
    /[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
    "Work phone is invalid."
  ),
});

export const involvementTypes = [
  "Suspect",
  "Bully",
  "Complainant",
  "Reporting Party",
  "Victim",
  "Witness",
];

export const footList = [0, 1, 2, 3, 4, 5, 6, 7, 8];

export const inchList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const eyeColors = [
  "Black",
  "Blue",
  "Brown",
  "Gray",
  "Green",
  "Hazel",
  "Red-albino",
  "Other",
];

export const hairColors = [
  "Auburn",
  "Black",
  "Blonde",
  "Brown",
  "Gray",
  "Light brown",
  "Red",
  "White",
  "Other/can't tell",
];
