import * as Yup from "yup";

const requiredErrorMsg = "You must fill all required fields.";

export const incidentBaseValidationSchema = Yup.object({
  streetAddress: Yup.object({
    country: Yup.string().required(requiredErrorMsg),
    streetNumber: Yup.string().max(30),
    streetName: Yup.string().required(requiredErrorMsg).max(100),
    streetType: Yup.string(),
    postDirection: Yup.string().oneOf(["E", "W", "S", "N"]),
    apartment: Yup.string().max(30),
    city: Yup.string().required(requiredErrorMsg).max(30),
    province: Yup.string().required(requiredErrorMsg),
    zipCode: Yup.string().required(requiredErrorMsg).max(20).matches(
      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
      "Postal Code is invalid"
    ),
  }),
  startTime: Yup.date().required(requiredErrorMsg).nullable().typeError("Invalid Date"),
  endTime: Yup.date().min(Yup.ref('startTime'), 'Incident End Time must be after Incident Start Time.').required(requiredErrorMsg).nullable().typeError("Invalid Date"),
});
