import React from "react";
import VerificationBase from "./VerificationBase";
import styles from "./Verification.module.css";
import StripeContainer from "../../../../../common/StripeContainer";

export default function PaymentScreen() {
    return (
        <VerificationBase>
            <div className={styles.body}>
                <div className={styles.column}>
                    <h4 className={styles.h4}>Payment</h4>
                    <StripeContainer />
                </div>
            </div>
        </VerificationBase>);
}
