import React from "react";
import IntroBase from "./IntroBase";
import styles from "./IntroBase.module.css";
import RadioField from "../../../../../form-fields/RadioField";
import Question from "../QuestionScreen/Question";
export default function Intro({ step }) {
    switch (step) {
        case "0":
            return (<IntroBase>
                <div className={styles.body}>

                    {feeParagraph()}
                    {requestRadioFields()}
                    {bottomParagraph()}
                </div>
            </IntroBase>);
        case "1":
            return (
                <Question />
            );
        default:
            return null;
    }

}

function feeParagraph() {
    return <div className={styles.column}>
        <label className={styles.coubourgTitle}>Cobourg Police Service</label>
        <p className={styles.coubourgParagraph}>A $5.00 Application Fee must accompany each request.
            Additional fees may apply. You will be notified prior to any
            additional charges being incurred.</p>
    </div>;
}

function bottomParagraph() {
    return <div className={styles.borderCard}>
        <label className={styles.noticeTitle}>NOTICE:</label>
        <label className={styles.fineText}>PERSONAL INFORMATION CONTAINED ON THIS FORM IS COLLECTED PURSUANT TO THE MUNICIPAL FREEDOM
            OF INFORMATION AND PROTECTION OF PRIVACY LEGISLATION AND WILL BE USED FOR THE PURPOSE OF
            RESPONDING TO YOUR REQUEST.
            QUESTIONS ABOUT THIS COLLECTION SHOULD BE DIRECTED TO THE FOI COORDINATOR </label>
    </div>;
}

function requestRadioFields() {
    return <div className={styles.column}>
        <label className={styles.requestText}>Request For:</label>
        <label className={styles.name}>
            <RadioField
                sx={{
                    "& .MuiSvgIcon-root": {
                        height: 20,
                        width: 20,
                    },
                    mr: 3,
                    '.MuiFormControlLabel-label': {
                        fontSize: 14,
                        wordSpacing: .5,
                    },

                }}
                className={styles.label}
                formKey="info.adminreport.foireport.request"
                type="radio"
                name="info.adminreport.foireport.request"
                value="Access to General Record"
                label="Access to General Record" />
            <RadioField
                sx={{
                    "& .MuiSvgIcon-root": {
                        height: 20,
                        width: 20,
                    },
                    mr: 3,
                    '.MuiFormControlLabel-label': {
                        fontSize: 14,
                        wordSpacing: .5,
                    },

                }}
                className={styles.label}
                formKey="info.adminreport.foireport.request"
                type="radio"
                name="info.adminreport.foireport.request"
                value="Access to Own Personnel Information"
                label="Access to Own Personnel Information" />
            <RadioField
                sx={{
                    "& .MuiSvgIcon-root": {
                        height: 20,
                        width: 20,
                    },
                    mr: 3,
                    '.MuiFormControlLabel-label': {
                        fontSize: 14,
                        wordSpacing: .5,
                    },

                }}
                formKey="info.adminreport.foireport.request"
                type="radio"
                name="info.adminreport.foireport.request"
                value="Correction of Own Personal Information"
                label="Correction of Own Personal Information" />
        </label>
    </div>;
}

