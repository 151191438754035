import React from "react";
import { useContext } from "react";
import FOIReport from "./components/foi-report";
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { FormDataContext } from "../../../contexts/FormData";
import ReportSelection from "./components/ReportSelection";
export default function Main() {

  const { formData } = useContext(FormDataContext);
  console.log(formData);


  switch (formData.meta.incidentType) {
    case "Freedom of Information":
      return (
        <main>
          <FOIReport/>
        </main>
      );
    default:
      return (
        <main>
          <ReportSelection />
        </main>
      );
  }
  // <main>
  //   <Router>
  //     <Switch>
  //       <Route exact path="/">
  //         <FOIReport />
  //       </Route>
  //       <Route path="/foi">
  //         <FOIReport />
  //       </Route>
  //     </Switch>
  //   </Router>


  // </main>

}
