import React from "react";
import styles from "./OptionRadioField.module.css";
import RadioField from "../../../../../form-fields/RadioField";
export default function OptionRadioField({ text, values, formKey, required }) {
    const items = []
    for (const [index, value] of values.entries()) {
        items.push(< RadioField key={index}
            sx={{
                "& .MuiSvgIcon-root": {
                    height: 18,
                    width: 18,
                },
                mr: 3,
                '.MuiFormControlLabel-label': {
                    fontSize: 14,
                    wordSpacing: .5,
                },

            }}
            formKey={formKey}
            type="radio"
            name={formKey}
            value={value}
            label={value} />)

    }
    return (
        <div className={styles.columnMargin}>
            <label className={styles.requestText}>{required && <span style={{ color: "red" }}>*</span>}{text}</label>
            <label className={styles.name}>
                {items}
            </label>
        </div>


    );
}

