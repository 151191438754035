import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useField } from "formik";

export default function SelectField({
  label,
  size = "small",
  options = [],
  sx = {},
  className,
  ...props
}) {
  const [field, meta] = useField(props);
  //  console.log('field',field)

  return (
    <FormControl sx={{ minWidth: 85, ...sx }} fullWidth error={!!meta.error}>
      <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
      <Select
        className={className}
        labelId={`${label}-select-label`}
        label={label}
        size={size}
        {...field}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
