import styles from "./CopyRight.module.css";

export default function CopyRight() {
  return (
    <div className={styles.copyRight}>
      {" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://docs.google.com/document/d/e/2PACX-1vQ2h6k543O_CDC97TUwi1UbW_GSxFnx74hE5aC3aLyK9OB-1SREaEPb3f8kwushLFBxupyjaZOPrbLU/pub"
      >
        {" "}
        Copyright &copy; {new Date().getFullYear()} Rubicon
      </a>{" "}
      &nbsp; | &nbsp;
      <a
        target="_blank"
        rel="noreferrer"
        href="https://docs.google.com/document/d/e/2PACX-1vRcNZrXdQuQHGhKXLBlUI9fF8aRS22FdT-qvsTjgyScH63Skj6jvMBXM-aT1bNyWA/pub"
      >
        Terms & Conditions{" "}
      </a>{" "}
      &nbsp;|&nbsp;
      <a
        target="_blank"
        rel="noreferrer"
        href="https://docs.google.com/document/d/e/2PACX-1vRdQdGkmAh5Ra3XI81TG6_5mJGefY-EiukwWUHabGA_-5-oPrfsXQmejhKWafdtYQ/pub"
      >
        {" "}
        Privacy Policy
      </a>
    </div>
  );
}
