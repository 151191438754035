import styles from "./DisplayKeyValue.module.css";

export default function DisplayKeyValue({ keyName, value }) {
  return (
    <div className={styles.container}>
      <div className={styles.itemFirst}>
        <p>
          <b>{keyName}:</b>
        </p>
      </div>
      <div className={styles.value}>
        <p>{value}</p>
      </div>
    </div>
  );
}
