import React from "react";
import TextInputField from "../../form-fields/TextInputField";
import SelectField from "../../form-fields/SelectField";
import styles from "./DriversLicense.module.css";

export default function DriversLicense({ formKey = "reporter" }) {
  return (
    <div className={styles.root}>
      <TextInputField
        name={`${formKey}.driversLicense.number`}
        label="Driver License No"
        sx={{ width: 0.5 }}
      />
      <SelectField
        name={`${formKey}.driversLicense.provider`}
        label="Licensing Prov/State"
        options={licensingProviderOptions}
        sx={{ width: 0.5 }}
      />
    </div>
  );
}

const licensingProviderOptions = [
  { value: "", label: "Licensing Prov/State" },
  { value: "Alberta", label: "Alberta" },
  { value: "British Columbia", label: "British Columbia" },
  { value: "Manitoba", label: "Manitoba" },
  { value: "New Brunswick", label: "New Brunswick" },
  { value: "Newfoundland", label: "Newfoundland" },
  { value: "Northwest Territories", label: "Northwest Territories" },
  { value: "Nova Scotia", label: "Nova Scotia" },
  { value: "Nunavut", label: "Nunavut" },
  { value: "Ontario", label: "Ontario" },
  { value: "Prince Edward Island", label: "Prince Edward Island" },
  { value: "Quebec", label: "Quebec" },
  { value: "Saskatchewan", label: "Saskatchewan" },
  { value: "Yukon", label: "Yukon" },
  { value: "Other", label: "Other" },
];
