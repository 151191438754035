import React from "react";
import { locations, IncidentBase } from "../../../../common/IncidentBase";
import SelectField from "../../../../form-fields/SelectField";
import styles from "./Incident.module.css";

export default function Incident({ formKey = "info.trafficComplaint" }) {
  return (
    <IncidentBase
      formKey={formKey}
      text={
        <>
          Enter details about location and time of the incident.{" "}
          <br></br>
          <b>
            Enter both Street Names if this happened at an intersection (E.g.
            Market St./ 10th St.)
          </b>
        </>
      }
      color=""
    >
      <div
        style={{
          paddingBottom: "1rem",
          borderBottom: "1px solid var(--border-color)",
        }}
      >
        <p style={{ paddingLeft: "1rem" }}>
          <span style={{ color: "red" }}>* </span>Location Type
        </p>
        <div className={styles.section}>
          <SelectField
            options={locations.map((location) => ({
              value: location,
              label: location,
            }))}
            name={formKey + ".locationType"}
            label={"Please Select"}
          />
        </div>
      </div>
    </IncidentBase>
  );
}
