import React from "react";
import SelectField from "../form-fields/SelectField";

export default function Race({ formKey = "yourself", required = true }) {
  return (
    <SelectField
      name={`${formKey}.race`}
      label={<>{required && <span style={{ color: "red" }}>* </span>} Race</>}
      options={race_options}
      sx={{ width: 1 }}
    />
  );
}

const raceList = [
  "Aboriginal",
  "Black",
  "Hispanic",
  "Metis",
  "Mid East",
  "Oriental/Asian",
  "Other",
  "S. Asian/E. Indian",
  "White",
];

const race_options = raceList.map((type) => ({
  value: type,
  label: type,
}));

export { raceList, race_options };
