import React, { useState } from "react";
import styles from "./MultiLineTextField.module.css";
import TextInputField from "./TextInputField";
import { Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import HelpOutline from "@mui/icons-material/HelpOutline";

export default function MultiLineTextField({ formKey, title, tooltip, characterCount }) {
    const [charactersLeft, setCharactersLeft] = useState(characterCount);
    const handleChange = (e) => {
        setCharactersLeft(characterCount - e.target.value.length);
    };
    return (
        <div className={styles.fields + " shadow"}>
            <div className={styles.block}>
                <div>
                    {tooltip ? <h4 className={styles.h4}>Narrative</h4> : null}
                    {/* <h4 className={styles.h4}>Narrative</h4> */}
                    <p className={styles.questionParagraph}>
                        {title}
                        <span>
                            {tooltip ? <Tooltip
                                title={tooltip}
                                className={styles.helpIcon}
                                placement="top"
                                sx={{ padding: 0, paddingBottom: 0.5, paddingLeft: 1 }}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            margin: 0,
                                            fontSize: "10px"
                                        }
                                    }
                                }}>
                                <IconButton>
                                    <HelpOutline />
                                </IconButton>
                            </Tooltip> : null}

                        </span>
                    </p>
                </div>
                <TextInputField
                    inputProps={{ maxLength: characterCount }}
                    name={formKey + ".narrative"}
                    label={""}
                    sx={{ width: 1, }}
                    multiline
                    rows={8}
                    onChange={handleChange}
                />
                <div className={styles.charactersLeft}>{charactersLeft} characters left
                </div>
            </div>
        </div>
    );
}
